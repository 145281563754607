import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Select, Space, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { PencilSimpleLine, Trash } from 'phosphor-react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import videoPlaceholder from '../../../assets/images/imagePlaceholder.png';
import {
  ASSET_CATEGORY,
  MODULES,
  ROUTES,
  TAGS_SORT_FIELD,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { getImageUrl } from '../../../common/utils';
import { modalContext } from '../../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import Image from '../../../components/Image';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import CustomCard from '../../component/card/Cscard';
import { DELETE_SOURCE } from './graphql/Mutations';
import { GET_SOURCES } from './graphql/Queries';

function SourcesCard({
  name,
  createdAt,
  image,
  description,
  articlesCount,
  podcastsCount,
  videosCount,
  showPreview,
  isAssetAllowed,
  isLabelAllowed,
  isViewOnly,
  onEdit,
  id,
  isDeleteAllowed,
  handleDelete
}) {
  const handleShowPreview = () => {
    showPreview({
      type: ASSET_CATEGORY.IMAGE,
      url: getImageUrl(image?.url) ?? videoPlaceholder
    });
  };

  return (
    <CustomCard
      onClick={handleShowPreview}
      alt={name}
      src={getImageUrl(image?.url) ?? videoPlaceholder}
      blurHash={image?.blurhash}
      title={moment(createdAt).format('ll')}
      heading={name}
      description={description}
      primaryOnClick={() => onEdit(id)}
      secondaryOnClick={() => {
        handleDelete(id);
      }}
      primary={
        isAssetAllowed &&
        isLabelAllowed &&
        (isViewOnly ? (
          <>
            <EyeFilled size={16} />
            View
          </>
        ) : (
          <>
            <PencilSimpleLine size={16} />
            Edit
          </>
        ))
      }
      secondary={
        isDeleteAllowed && (
          <>
            <Trash size={16} />
            Delete
          </>
        )
      }
    >
      <Space wrap className="mb-12">
        <Tag>{videosCount} Videos</Tag>
        <Tag>{podcastsCount} Podcasts</Tag>
        <Tag>{articlesCount} Articles</Tag>
      </Space>
    </CustomCard>
  );
}

const SOURCES_OPTIONS = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false }
];

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  active
}) => ({
  filter: {
    skip: offset,
    search,
    limit
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  },
  where: {
    isActive: active
  }
});

const dataSelector = ({ sourcesAdmin }) => ({
  data: sourcesAdmin?.sources ?? [],
  count: sourcesAdmin?.count ?? 0
});

const columns = ({
  isAddEditAllowed,
  isDeleteAllowed,
  handleEdit,
  handleDelete,
  isViewOnly,
  showPreview
}) => [
  {
    title: 'Image',
    dataIndex: 'image',
    width: 150,
    render: (value, { name }) => (
      <Image
        onClick={() => {
          showPreview({
            type: ASSET_CATEGORY.IMAGE,
            url:
              getImageUrl(value?.url, { height: 50, width: 80 }) ??
              videoPlaceholder
          });
        }}
        className="group-img-table pointer"
        blurHash={value?.blurhash}
        src={
          getImageUrl(value?.url, { height: 50, width: 80 }) ?? videoPlaceholder
        }
        alt={name}
      />
    )
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 170,
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description'
  },
  {
    title: 'Videos',
    dataIndex: 'videosCount',
    width: 100
  },
  {
    title: 'Podcasts',
    dataIndex: 'podcastsCount',
    width: 120
  },
  {
    title: 'Articles',
    dataIndex: 'articlesCount',
    width: 120
  },
  ...(isAddEditAllowed || isDeleteAllowed
    ? [
        {
          title: 'Action',
          dataIndex: 'operation',
          width: '12%',
          render: (text, record) => (
            <div>
              <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Source`}>
                {isViewOnly ? (
                  <EyeFilled
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(record?.id);
                    }}
                  />
                ) : (
                  <EditOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(record?.id);
                    }}
                  />
                )}
              </Tooltip>
              {isDeleteAllowed && (
                <span onClick={(e) => e.stopPropagation()}>
                  <Tooltip title="Delete Source">
                    <DeleteOutlined onClick={() => handleDelete(record?.id)} />
                  </Tooltip>
                </span>
              )}
            </div>
          )
        }
      ]
    : [])
];

function Sources({ history }) {
  const ref = useRef();
  const [active, setActive] = useState(true);
  const filterProps = useFilterBar({
    defaultSortOptions: { options: TAGS_SORT_FIELD }
  });

  const [deleteSource] = useMutation(DELETE_SOURCE, { onError() {} });

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isLabelAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isAssetAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleActiveChange = (value) => {
    setActive(value);
  };

  const filters = useMemo(
    () => ({
      active,
      ...filterProps?.filters
    }),
    [filterProps?.filters, active]
  );
  const sourcesBreadcrumbs = [
    { label: MODULES?.LABELS },
    { label: MODULES?.SOURCES }
  ];

  const handleShowModal = () => {
    history.push(`${ROUTES?.LABELS_SOURCES}/add`);
  };

  const handleEdit = useCallback((id) => {
    history.push(`${ROUTES?.LABELS_SOURCES}/${id}/edit`);
  }, []);

  const handleDelete = async (id) => {
    modalContext?.confirm({
      title: 'Are you sure, you want to delete this source?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      async onOk() {
        deleteSource({
          variables: { where: { id } }
        })
          .then(({ errors }) => {
            if (ref?.current?.removeItem && !errors) {
              ref?.current?.removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  return (
    <>
      <PageHeader
        menu={sourcesBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Sources' }}
        extraFilters={
          <Select
            value={active}
            options={SOURCES_OPTIONS}
            onChange={handleActiveChange}
          />
        }
      />
      <PageList
        ref={ref}
        filters={filters}
        listMode={filterProps.listMode}
        query={GET_SOURCES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: (props, { showPreview }) => (
            <SourcesCard
              {...props}
              onEdit={handleEdit}
              showPreview={showPreview}
              isAddEditAllowed={isAddEditAllowed}
              isAssetAllowed={isAssetAllowed}
              isLabelAllowed={isLabelAllowed}
              isViewOnly={isViewOnly}
              isDeleteAllowed={isDeleteAllowed}
              handleDelete={handleDelete}
            />
          )
        }}
        TableProps={{
          columns: (props) =>
            columns({
              ...props,
              isAddEditAllowed,
              isDeleteAllowed,
              handleEdit,
              handleDelete,
              isViewOnly
            })?.filter((item) => item !== false)
        }}
      />
    </>
  );
}

export default Sources;
