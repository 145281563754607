import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { isFunction } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { getImageUrl } from '../../../common/utils';
import Image from '../../../components/Image';

function CategoryCard({
  href,
  grid,
  lending,
  topic,
  backgroundColor,
  textColor = 'text-n-1000',
  responsive,
  onDelete,
  isDeleteAllowed = true,
  image,
  ...rest
}) {
  return (
    <Link
      className={`category-card ${textColor} ${grid ? 'category-grid' : ''} ${
        lending ? 'category-lending' : ''
      } ${responsive ? 'responsive-view' : ''}`}
      to={href}
      {...rest}
    >
      {image && (
        <Image src={getImageUrl(image?.url)} blurHash={image?.blurHash} />
      )}
      <div
        className="category-title"
        {...(!image && {
          // Used inline style as its dynamic value
          style: { backgroundColor }
        })}
      >
        {topic ? <div className="topic topic-title">{topic}</div> : null}
      </div>
      {isDeleteAllowed && isFunction(onDelete) && (
        <Tooltip title="Delete Topic">
          <Button
            type="text"
            className="btn-text btn-delete"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete();
            }}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </Link>
  );
}
export default CategoryCard;
