import { Avatar, Badge, Button, Tooltip, Typography } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { BookmarkSimple, CaretRight, ShareNetwork } from 'phosphor-react';
import React from 'react';
import TagGrid from '../tagModules/TagGrid';

function FeatureArticleCard({
  topicName,
  title,
  description,
  tagData,
  src,
  className,
  showCTA,
  publishedAt,
  author,
  html
}) {
  const { Paragraph } = Typography;
  return (
    <>
      <section className="featured-carousel responsive-view">
        <div
          className={`featured-video-card article-card center-false ${className}`}
        >
          <div className="fvc-image">
            <img src={src} alt="Featured Article" />
          </div>
          <div className="fvc-details">
            <div className="fvc-description article-description">
              {topicName && (
                <a prefetch={false} href="/">
                  <Badge className="badge-md bg-red-2 text-white-90 bg-red-2 text-n-900">
                    {topicName}
                  </Badge>
                </a>
              )}

              <div className="heading">{title}</div>
              <Paragraph className="description text-white-90">
                {description}
              </Paragraph>
              {publishedAt && (
                <div className="text-red mb-12">
                  {moment(publishedAt).fromNow()}
                </div>
              )}
              {!isEmpty(author) &&
                author?.map((item) => (
                  <div key={item?.id} className="video-avatar-group mb-16">
                    <a
                      href="/"
                      className="item d-flex align-center gap-8 flex-shrink-0"
                    >
                      <Avatar
                        className="overflow-hidden"
                        size="small"
                        {...(item?.image?.url && {
                          src: item?.image?.url
                        })}
                        alt={item?.name?.charAt(0)}
                      />
                      <div>
                        <div className="text-lg text-white-90">
                          {item?.name}
                        </div>
                        <div className="text-md text-white-70">
                          {item?.subTitle && (
                            <div className="text-md text-white-70">
                              {item?.subTitle}
                            </div>
                          )}
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              {tagData?.length > 0 && (
                <TagGrid
                  className="text-md tag-xs bg-tags b-0 text-n-0 mr-4"
                  data={tagData}
                  icon={<CaretRight className="ml-2" size={16} />}
                />
              )}
            </div>
            <div className="slider-button">
              {showCTA?.share && (
                <Tooltip placement="top" title="Share">
                  <Button
                    className=" text-black-900 b-0 cs-button bg-ivory gap-4 hbg-n-200 hc-black-70"
                    size="small"
                    block
                  >
                    <ShareNetwork size={24} />
                    Share
                  </Button>
                </Tooltip>
              )}
              {showCTA?.save && (
                <Tooltip placement="top" title="Save">
                  <Button
                    className="text-black-900 b-0 cs-button bg-ivory gap-4 hbg-n-200 hc-black-70"
                    size="small"
                    block
                  >
                    <BookmarkSimple weight="bold" size={24} />
                    Save
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </section>
      {html && (
        <div
          className="cms-content mt-8"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: html
          }}
        />
      )}
    </>
  );
}
export default FeatureArticleCard;
