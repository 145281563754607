import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, DatePicker, Form, Input, Space, Table } from 'antd';
import dayjs from 'dayjs';
import { isEmpty, isObject, map, omit, trim } from 'lodash';
import { CalendarBlank } from 'phosphor-react';
import React, { useCallback, useEffect } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { GET_WORKSPACES_APPS } from '../../app/components/sidebar/graphql/Queries';
import {
  MAX_LENGTHS,
  MODULES,
  PLAN_TYPE_KEYS,
  ROLE_KEYS,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION,
  defaultDateFormat
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import {
  Permissions,
  Switch
} from '../pages/component/pageModules/moduleForms/FormInputs';
import { GET_SUBSCRIPTION_PLANS } from '../subscriptions-plans/graphql/Queries';
import { Select } from '../videos/components/FormInputs';
import { CREATE_USER, UPDATE_USER } from './graphql/Mutations';
import { GET_USER } from './graphql/Queries';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  contactInfo: {
    number: '',
    code: ''
  },
  username: '',
  permissions: [],
  oneTimePlans: []
};

const variablesSelector = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

const PhoneInput = ({ value, onChange, placeholder, id }) => {
  const { number, code } = value;

  const handleChange = (val, { dialCode }) => {
    onChange({
      number: val?.replace(dialCode, ''),
      code: `+${dialCode}`
    });
  };

  return (
    <ReactPhoneInput
      inputProps={{ id }}
      placeholder={placeholder}
      value={`${code?.replace('+', '')}${number}`}
      onChange={handleChange}
    />
  );
};

const excludePermissions = ['LOGGED_IN', 'NOT_LOGGED_IN'];

const AddEditUser = ({ history, match: { params } }) => {
  const [form] = Form?.useForm();
  const { userId } = params;
  const isEdit = !!userId;
  const assignedPlan = Form.useWatch('assignedPlan', form);
  const oneTimePlan = Form.useWatch('oneTimePlans', form);

  const usersBreadcrumbs = [
    { label: MODULES?.USERS, route: ROUTES?.USERS },
    userId && { label: userId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const [getUser, { loading: fetchingDetails }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only'
  });

  const [addUpdateUser, { loading }] = useMutation(
    isEdit ? UPDATE_USER : CREATE_USER
  );

  useEffect(() => {
    if (isEdit && !!userId) {
      getUser({
        variables: {
          where: {
            id: userId
          }
        }
      }).then(({ data, error }) => {
        if (!error && !!data) {
          form?.setFieldsValue({
            firstName: data?.user?.firstName ?? '',
            lastName: data?.user?.lastName ?? '',
            email: data?.user?.email ?? '',
            contactInfo: {
              number: data?.user?.contactNumber ?? '',
              code: data?.user?.countryCode ?? ''
            },
            permissions:
              data?.user?.permissions?.map((value) => ({
                label: value,
                value
              })) ?? [],
            username: data?.user?.username,
            zipCode: data?.user?.zipCode,
            assignedPlan: data?.user?.assignedPlan,
            assignedPlanExpiry: data?.user?.assignedPlanExpiry
              ? dayjs(data?.user?.assignedPlanExpiry)
              : null,
            oneTimePlans: map(data?.user?.oneTimePlans, (item) => ({
              ...item,
              key: { label: item?.key, value: item?.key },
              createdAt: item?.createdAt ? dayjs(item?.createdAt) : null,
              expiredAt: item?.expiredAt ? dayjs(item?.expiredAt) : null
            })),
            currentPlan: data?.user?.currentPlan
          });
        }
      });
    }
  }, [isEdit, userId, form]);

  const handleSubmit = ({
    contactInfo,
    permissions,
    email,
    username,
    appId,
    oneTimePlans,
    ...restValues
  }) => {
    const payload = {
      ...restValues,
      ...(!isEdit && { email }),
      contactNumber: contactInfo.number,
      countryCode: contactInfo.code,
      ...(isEdit && {
        permissions: permissions?.map(({ value }) => value) ?? []
      }),
      roles: [ROLE_KEYS?.USER],
      username: trim(username),
      appId: appId?.value,
      assignedPlan: isObject(restValues?.assignedPlan)
        ? restValues?.assignedPlan?.value
        : restValues?.assignedPlan,
      oneTimePlans: map(oneTimePlans, (item) =>
        omit(
          {
            ...item,
            isActive: item?.isActive ?? false,
            key: isObject(item?.key) ? item?.key?.value : item?.key
          },
          '__typename'
        )
      )
    };

    delete payload?.currentPlan;

    addUpdateUser({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: userId
          }
        })
      }
    }).then(() => {
      history?.push(ROUTES?.USERS);
    });
  };

  const handleCancel = () => {
    history?.push(ROUTES?.USERS);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const planColumns = useCallback(
    (fields) => [
      {
        title: 'Active',
        key: 'isActive',
        width: 30,
        render: (value, row) => {
          return (
            <Form.Item
              {...row}
              name={[row?.name, 'isActive']}
              valuePropName="checked"
              key={[row?.key, 'isActive']}
            >
              <Switch />
            </Form.Item>
          );
        }
      },
      {
        title: 'Key',
        key: 'key',
        width: 50,
        render: (_, row) => {
          return (
            <Form.Item
              {...row}
              name={[row?.name, 'key']}
              rules={[
                {
                  validator() {
                    const keyValue = form.getFieldValue([
                      'oneTimePlans',
                      row?.name,
                      'key'
                    ]);
                    if (!keyValue) {
                      return Promise.reject(new Error('Please select plan'));
                    }
                    const allValues = form.getFieldValue(['oneTimePlans']);
                    const hasDuplicate =
                      allValues?.filter(
                        ({ key }) => key?.value === keyValue?.value
                      )?.length >= 2;
                    if (hasDuplicate) {
                      return Promise.reject(
                        new Error('Please select a unique plan')
                      );
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Select
                popupMatchSelectWidth={false}
                placeholder="Select plan"
                query={GET_SUBSCRIPTION_PLANS}
                variablesSelector={(filter) => ({
                  filter: {
                    isActive: true,
                    type: PLAN_TYPE_KEYS.ONE_TIME,
                    ...filter
                  }
                })}
                allowClear
                dataSelector={(res) =>
                  res?.subscriptionPlans?.subscriptionPlans?.map(({ key }) => ({
                    label: key,
                    value: key
                  })) ?? 0
                }
                keys={{
                  data: 'subscriptionPlans',
                  records: 'subscriptionPlans',
                  count: 'count'
                }}
                onChange={() => {
                  fields.forEach(({ name }) => {
                    if (name !== row?.name) {
                      form.validateFields([['oneTimePlans', name, 'key']]);
                    }
                  });
                }}
              />
            </Form.Item>
          );
        }
      },
      {
        title: 'Created',
        key: 'createdAt',
        render: (_, row) => {
          return (
            <Form.Item
              {...row}
              name={[row?.name, 'createdAt']}
              key={[row?.key, 'createdAt']}
            >
              <DatePicker
                suffixIcon={<CalendarBlank size={20} />}
                disabledDate={(day) => dayjs().isAfter(day)}
                format={defaultDateFormat}
              />
            </Form.Item>
          );
        }
      },
      {
        title: 'Expiry',
        key: 'expiredAt',
        render: (_, row) => {
          return (
            <Form.Item
              {...row}
              name={[row?.name, 'expiredAt']}
              key={[row?.key, 'expiredAt']}
            >
              <DatePicker
                suffixIcon={<CalendarBlank size={20} />}
                disabledDate={(day) => dayjs().isAfter(day)}
                format={defaultDateFormat}
              />
            </Form.Item>
          );
        }
      }
    ],
    [form]
  );
  return (
    <>
      <PageHeader menu={usersBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              required
              rules={[
                formValidatorRules?.required('Please enter first name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.NAME)]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
            <Form.Item
              label="Username"
              name="username"
              required
              rules={[formValidatorRules?.required('Please enter username!')]}
            >
              <Input placeholder="Enter username" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter email!'
                },
                formValidatorRules?.email,
                formValidatorRules?.maxLength(MAX_LENGTHS.FORM_INPUT)
              ]}
            >
              <Input
                disabled={isEdit}
                readOnly={isEdit}
                placeholder="Enter email"
              />
            </Form.Item>
            {!isEdit && (
              <Form.Item
                label="App"
                name="appId"
                rules={[{ required: true, message: 'Please select app!' }]}
              >
                <Select
                  placeholder="Select app"
                  query={GET_WORKSPACES_APPS}
                  variablesSelector={variablesSelector}
                  dataSelector={(data) => {
                    return (
                      data?.workspaceApps?.workspaceApps?.map(
                        ({ id, name }) => ({
                          label: name,
                          value: id
                        })
                      ) ?? []
                    );
                  }}
                  keys={{
                    data: 'workspaceApps',
                    records: 'workspaceApps',
                    count: 'count'
                  }}
                />
              </Form.Item>
            )}
            <Form.Item
              label="Phone Number"
              name="contactInfo"
              rules={[
                formValidatorRules?.maxLength(
                  MAX_LENGTHS.PHONE_NUMBER,
                  undefined,
                  (value) => value?.number?.length > MAX_LENGTHS.PHONE_NUMBER
                )
              ]}
            >
              <PhoneInput placeholder="Enter phone number" />
            </Form.Item>
            <Form.Item
              label="Zipcode"
              name="zipCode"
              required
              rules={[formValidatorRules?.required('Please enter zipcode!')]}
            >
              <Input placeholder="Enter zipcode" />
            </Form.Item>
            <Form.Item name="assignedPlan" label="Assign Plan">
              <Select
                placeholder="Select plan"
                query={GET_SUBSCRIPTION_PLANS}
                variablesSelector={(filter) => ({
                  filter: { isActive: true, ...filter }
                })}
                allowClear
                dataSelector={(res) =>
                  res?.subscriptionPlans?.subscriptionPlans?.map(
                    ({ key: value, name, frequency }) => ({
                      label: `${name} ${frequency ? `(${frequency})` : ''}`,
                      value
                    })
                  ) ?? 0
                }
                keys={{
                  data: 'subscriptionPlans',
                  records: 'subscriptionPlans',
                  count: 'count'
                }}
              />
            </Form.Item>
            <Form.Item
              name="assignedPlanExpiry"
              label="Assigned Plan Expiry"
              dependencies={['assignedPlan']}
            >
              <DatePicker
                suffixIcon={<CalendarBlank size={20} />}
                disabledDate={(day) => dayjs().isAfter(day)}
                format={defaultDateFormat}
                disabled={!assignedPlan}
              />
            </Form.Item>
            {isEdit && (
              <Form.Item label="Current Plan" name="currentPlan">
                <Input disabled />
              </Form.Item>
            )}
            <fieldset className="mb-12">
              <legend className="role-legend">One-Time Plans</legend>
              <Form.List name={['oneTimePlans']}>
                {(fields, { add, remove }) => (
                  <div className="listview form-table">
                    <Table
                      dataSource={fields?.map((field) => ({
                        ...field
                      }))}
                      locale={{ emptyText: <div /> }}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                      bordered={false}
                      footer={() => {
                        return (
                          <Button
                            onClick={() =>
                              add({
                                isActive: false,
                                key: null,
                                expiredAt: null,
                                createdAt: null
                              })
                            }
                            icon={<PlusOutlined />}
                          >
                            Add Plan
                          </Button>
                        );
                      }}
                      columns={
                        !isEmpty(oneTimePlan)
                          ? [
                              ...planColumns(fields),
                              {
                                title: 'Action',
                                render: (value, row) => {
                                  return (
                                    <Button
                                      className="text-btn"
                                      type="text"
                                      onClick={() => {
                                        remove(row?.name);
                                        fields.forEach(({ name }) => {
                                          if (name !== row?.name) {
                                            form.validateFields([
                                              ['oneTimePlans', name, 'key']
                                            ]);
                                          }
                                        });
                                      }}
                                      icon={<DeleteOutlined />}
                                    />
                                  );
                                }
                              }
                            ]
                          : []
                      }
                    />
                  </div>
                )}
              </Form.List>
            </fieldset>
            {isEdit && (
              <Permissions
                label="Attributes"
                placeholder="Select attributes"
                excludeOptions={excludePermissions}
              />
            )}
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditUser;
