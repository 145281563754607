import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space
} from 'antd';
import { filter, map, some } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  REGEX,
  ROUTES,
  UI_LABEL_TYPE,
  UI_LABEL_TYPES,
  UI_LABEL_TYPES_OPTIONS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import LoadEditor from '../../components/LoadEditor';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import SelectAsset from '../assets/components/SelectAsset';
import { CREATE_UI_LABEL, UPDATE_UI_LABEL } from './graphql/Mutations';
import { GET_LANGUAGES, GET_UI_LABEL } from './graphql/Queries';

const initialValues = {
  type: UI_LABEL_TYPES.STRING,
  key: '',
  description: '',
  referenceImage: {
    id: '',
    url: ''
  },
  asset: null,
  languageArray: []
};

const AddEditUILabel = ({ history, match: { params } }) => {
  const { uiLabelId } = params;
  const isEdit = !!uiLabelId;
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [form] = Form.useForm();
  const type = Form?.useWatch('type', form);

  const uiLabelBreadcrumbs = [
    { label: MODULES?.UI_CONFIGURATIONS },
    { label: MODULES?.UI_LABELS, route: ROUTES?.UI_CONFIG_LABELS },
    uiLabelId && { label: uiLabelId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const languageArrayValue = Form?.useWatch('languageArray', form);
  const languageCodeValue = Form?.useWatch('languageCode', form);

  const { data: languagesData } = useQuery(GET_LANGUAGES, {
    fetchPolicy: 'network-only',
    onCompleted: ({ languages }) => {
      if (languages?.languages?.length > 0) {
        form?.setFieldsValue({
          languageArray: map(languages?.languages, (item) => {
            return {
              languageCode: { label: item?.name, value: item?.code },
              languageValue: ''
            };
          })
        });
      }
    }
  });

  const isAssetEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isAssetViewAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const [fetchUILabel, { loading: fetchingDetails }] = useLazyQuery(
    GET_UI_LABEL,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateUILabel, { loading }] = useMutation(
    isEdit ? UPDATE_UI_LABEL : CREATE_UI_LABEL
  );

  useEffect(() => {
    if (isEdit) {
      fetchUILabel({
        variables: {
          id: uiLabelId
        }
      })
        .then(({ data }) => {
          if (!data) return;
          const { uiLabelAdmin } = data;
          form.setFieldsValue({
            key: uiLabelAdmin?.key,
            type: uiLabelAdmin?.type,
            languageValue: uiLabelAdmin?.value ?? '',
            languageCode: {
              label: uiLabelAdmin?.language?.name,
              value: uiLabelAdmin?.language?.code
            },
            description: uiLabelAdmin?.description ?? '',
            referenceImage: {
              id: uiLabelAdmin?.referenceImage?.id ?? '',
              url: uiLabelAdmin?.referenceImage?.url ?? ''
            }
          });
        })
        .catch();
    }
  }, [form]);

  const hasAddedOneValue = some(
    languageArrayValue,
    (item) => item?.languageValue?.length
  );

  const assetInfo = {
    [UI_LABEL_TYPES.IMAGE]: {
      modalTitle: 'Select Image',
      categoryKey: ASSET_CATEGORY.IMAGE,
      btnText: 'Image',
      dataSelector: ({ id, url }) => ({
        id,
        url
      })
    },
    [UI_LABEL_TYPES.VIDEO]: {
      modalTitle: 'Select Video',
      categoryKey: ASSET_CATEGORY.VIDEO,
      btnText: 'Video',
      dataSelector: ({ id, serviceImageThumbnail, serviceVideoThumbnail }) => ({
        id,
        url: serviceVideoThumbnail || serviceImageThumbnail
      })
    }
  }[type];

  const handleSubmit = ({
    referenceImage,
    asset: _asset,
    type: uiLabelType,
    key,
    languageArray = [],
    languageValue,
    languageCode,
    ...rest
  }) => {
    if (!hasAddedOneValue && !isEdit) {
      setValidationTriggered(true);
      return;
    }
    addUpdateUILabel({
      variables: {
        ...(isEdit && { id: uiLabelId }),
        data: {
          ...(!isEdit
            ? {
                type: uiLabelType,
                key,
                value: map(
                  filter(languageArray, (item) => item?.languageValue?.length),
                  (item) => {
                    return {
                      languageCode: item?.languageCode?.value,
                      value: item?.languageValue
                    };
                  }
                )
              }
            : { value: languageValue }),
          referenceImageId: referenceImage?.id || null,
          ...rest
        }
      }
    }).then(() => {
      history.push(ROUTES?.UI_CONFIG_LABELS);
    });
  };

  const handleCancel = () => {
    history.push(ROUTES?.UI_CONFIG_LABELS);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <>
      <PageHeader menu={uiLabelBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinishFailed={() => setValidationTriggered(true)}
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails}
          >
            <Form.Item
              label="Key"
              name="key"
              required
              rules={[
                {
                  validator(rule, value) {
                    if (!value) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject('Please enter a key!');
                    }
                    if (!REGEX?.KEY_VALUE?.test(value)) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject('Should be a valid key');
                    }
                    return Promise?.resolve();
                  }
                },
                // need to set 100 as per requirement
                formValidatorRules?.maxLength(100)
              ]}
            >
              <Input disabled={isEdit} placeholder="Enter key" />
            </Form.Item>
            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please select type!'
                }
              ]}
            >
              <AntdSelect
                disabled={isEdit}
                options={UI_LABEL_TYPES_OPTIONS}
                placeholder="Select type"
                onChange={() => {
                  form?.setFieldsValue({
                    languageArray: map(languageArrayValue, (item) => {
                      return {
                        ...item,
                        languageValue: ''
                      };
                    }),
                    asset: null
                  });
                }}
              />
            </Form.Item>
            {!isEdit && (
              <Form.Item
                label="Label Type"
                name="labelType"
                rules={[
                  { required: true, message: 'Please select label type!' }
                ]}
              >
                <Select
                  placeholder="Select label type"
                  options={UI_LABEL_TYPE}
                />
              </Form.Item>
            )}
            {type !== UI_LABEL_TYPES.STRING && assetInfo && (
              <Form.Item
                name="asset"
                rules={
                  !isEdit
                    ? [
                        {
                          validator(rule, value) {
                            if (!value) {
                              // eslint-disable-next-line prefer-promise-reject-errors
                              return Promise?.reject('Please select asset!');
                            }
                            return Promise?.resolve();
                          }
                        }
                      ]
                    : []
                }
              >
                <SelectAsset
                  allowClear={false}
                  onChange={({ id }) => {
                    if (id) {
                      if (!isEdit) {
                        form?.setFieldsValue({
                          languageArray: map(languageArrayValue, (item) => {
                            return {
                              ...item,
                              languageValue: id
                            };
                          })
                        });
                      } else {
                        form?.setFieldsValue({
                          languageValue: id
                        });
                      }
                    }
                  }}
                  isAssetEditAllowed={isAssetEditAllowed}
                  isAssetViewAllowed={isAssetViewAllowed}
                  {...assetInfo}
                />
              </Form.Item>
            )}

            <Form.Item
              disabled={isViewOnly}
              label="Description"
              name="description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter description" />
            </Form.Item>
            <Form.Item label="Reference Image" name="referenceImage">
              <SelectAsset
                disabled={isViewOnly}
                modalTitle="Select Reference Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
                isAssetEditAllowed={isAssetEditAllowed}
                isAssetViewAllowed={isAssetViewAllowed}
              />
            </Form.Item>
            {!isEdit ? (
              <Form.List name="languageArray">
                {(fields) => (
                  <Row gutter={[16, 0]}>
                    {fields?.map((field) => (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={8}
                        key={field?.key}
                      >
                        <Form.Item
                          label="Language Code"
                          hidden
                          name={[field?.name, 'languageCode']}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          required={!hasAddedOneValue}
                          label={`Value (${
                            languagesData?.languages?.languages?.[field?.key]
                              ?.name
                          })`}
                          name={[field?.name, 'languageValue']}
                          rules={
                            type === UI_LABEL_TYPES.HTML
                              ? []
                              : [
                                  formValidatorRules?.maxLength(
                                    MAX_LENGTHS.DESCRIPTION
                                  ),
                                  {
                                    validator(rule, value) {
                                      if (value?.length > 0 && !value.trim()) {
                                        return Promise?.reject(
                                          new Error('Please enter value!')
                                        );
                                      }
                                      return Promise?.resolve();
                                    }
                                  }
                                ]
                          }
                        >
                          {type === UI_LABEL_TYPES.HTML ? (
                            <LoadEditor
                              disabled={isViewOnly}
                              className="full-html"
                            />
                          ) : (
                            <Input
                              placeholder="Enter value"
                              disabled={
                                isViewOnly || type !== UI_LABEL_TYPES.STRING
                              }
                              readOnly={type !== UI_LABEL_TYPES.STRING}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                )}
              </Form.List>
            ) : (
              <>
                <Form.Item label="Language Code" name="languageCode" hidden>
                  <Input />
                </Form.Item>
                <Form.Item
                  label={`Value (${languageCodeValue?.label || ''})`}
                  name="languageValue"
                  rules={
                    type === UI_LABEL_TYPES.HTML
                      ? []
                      : [
                          formValidatorRules?.maxLength(
                            MAX_LENGTHS.DESCRIPTION
                          ),
                          {
                            validator(rule, value) {
                              if (!value.trim()) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise?.reject('Please enter value!');
                              }
                              return Promise?.resolve();
                            }
                          }
                        ]
                  }
                >
                  {type === UI_LABEL_TYPES.HTML ? (
                    <LoadEditor disabled={isViewOnly} className="full-html" />
                  ) : (
                    <Input
                      placeholder="Enter value"
                      disabled={isViewOnly || type !== UI_LABEL_TYPES.STRING}
                      readOnly={type !== UI_LABEL_TYPES.STRING}
                    />
                  )}
                </Form.Item>
              </>
            )}
            {type !== UI_LABEL_TYPES.STRING && assetInfo
              ? null
              : !hasAddedOneValue &&
                validationTriggered &&
                !isEdit && (
                  <span className="site-result-demo-error-icon">
                    Please enter at least one language value!
                  </span>
                )}
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}
                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditUILabel;
