import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect } from 'react';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULE_TYPES,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  STATIC_PAGES,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import SelectAsset from '../../../../assets/components/SelectAsset';
import { GET_SLUGS } from '../../../../menus/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields } from './FormInputs';

export const initialAssetValues = {
  id: '',
  url: ''
};

export const ACTION_TYPES = { INTERNAL: 'INTERNAL', EXTERNAL: 'EXTERNAL' };

export const getActionData = (data, withAsset = true) => {
  const values = {
    ...data,
    internalPageType: data?.internalPageType ?? null,
    ...(data?.type === ACTION_TYPES.INTERNAL && {
      ...(data?.internalPageType === PAGE_TYPES.STATIC
        ? { slug: data?.url }
        : {
            slug: data?.url
              ? {
                  label: `/${data.url}`,
                  value: data?.url
                }
              : null
          })
    }),
    ...(withAsset && {
      asset: data?.asset ?? {
        ...initialAssetValues
      }
    })
  };
  return values;
};

export const initialActionValues = {
  title: '',
  type: ACTION_TYPES.INTERNAL,
  internalPageType: null,
  slug: null,
  asset: {
    ...initialAssetValues
  },
  url: ''
};

const initialCTAValue = {
  asset: {
    ...initialAssetValues
  },
  time: '',
  date: '',
  location: '',
  primaryAction: {
    ...initialActionValues
  },
  secondaryAction: {
    ...initialActionValues
  }
};

const initialValues = {
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    title: true,
    image: true,
    description: true,
    primaryAction: true,
    secondaryAction: true,
    time: true,
    date: true,
    location: true
  },
  config: {
    items: [initialCTAValue]
  }
};

const ACTION_TYPE_OPTIONS = [
  { label: 'Internal', value: ACTION_TYPES.INTERNAL },
  { label: 'External', value: ACTION_TYPES.EXTERNAL }
];

export const ASSET_TYPES_OPTIONS = [
  { label: 'Image', value: ASSET_CATEGORY.IMAGE },
  { label: 'Video', value: ASSET_CATEGORY.VIDEO }
];

const SETTINGS = [
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.CTA_LIST, MODULE_TYPES.CTA_CAROUSEL]
  },
  {
    name: 'primaryAction',
    label: 'Primary Action',
    allowedTypes: [
      MODULE_TYPES.CTA_GRID,
      MODULE_TYPES.CTA_LIST,
      MODULE_TYPES.CTA_CAROUSEL
    ]
  },
  {
    name: 'secondaryAction',
    label: 'Secondary Action',
    allowedTypes: [
      MODULE_TYPES.CTA_GRID,
      MODULE_TYPES.CTA_LIST,
      MODULE_TYPES.CTA_CAROUSEL
    ]
  },
  {
    name: 'date',
    label: 'Date',
    allowedTypes: [
      MODULE_TYPES.CTA_GRID,
      MODULE_TYPES.CTA_LIST,
      MODULE_TYPES.CTA_CAROUSEL
    ]
  },
  {
    name: 'time',
    label: 'Time',
    allowedTypes: [
      MODULE_TYPES.CTA_GRID,
      MODULE_TYPES.CTA_LIST,
      MODULE_TYPES.CTA_CAROUSEL
    ]
  },
  {
    name: 'location',
    label: 'Location',
    allowedTypes: [
      MODULE_TYPES.CTA_GRID,
      MODULE_TYPES.CTA_LIST,
      MODULE_TYPES.CTA_CAROUSEL
    ]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.CTA_GRID]: 'ctaGridModule',
  [MODULE_TYPES.CTA_CAROUSEL]: 'ctaCarouselModule',
  [MODULE_TYPES.CTA_LIST]: 'ctaListModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.CTA_GRID]: 'CTA Grid Configs',
  [MODULE_TYPES.CTA_CAROUSEL]: 'CTA Carousel Configs',
  [MODULE_TYPES.CTA_LIST]: 'CTA List Configs'
};

const ActionButton = ({ name, label, showAsset, form, itemName }) => {
  const namePath = [itemName, name];
  const { internalPageType, type } =
    Form.useWatch(['config', 'items', ...namePath], form) ?? {};
  return (
    <Space className="w-full" direction="vertical">
      <Typography.Text>{label}</Typography.Text>
      <div>
        <Form.Item
          label="Title"
          name={[...namePath, 'title']}
          rules={[formValidatorRules?.maxLength(MAX_LENGTHS.CTA)]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>
        <Form.Item label="Navigation" name={[...namePath, 'type']}>
          <AntdSelect
            options={ACTION_TYPE_OPTIONS}
            onChange={() => {
              form.resetFields([
                ['config', 'items', ...namePath, 'internalPageType'],
                ['config', 'items', ...namePath, 'slug'],
                ['config', 'items', ...namePath, 'url']
              ]);
            }}
          />
        </Form.Item>

        {type === ACTION_TYPES.INTERNAL && (
          <>
            <Form.Item
              label="Page Type"
              name={[...namePath, 'internalPageType']}
            >
              <AntdSelect
                options={PAGE_TYPES_OPTIONS}
                placeholder="Select page type"
                onChange={() => {
                  form.resetFields([
                    ['config', 'items', ...namePath, 'slug'],
                    ['config', 'items', ...namePath, 'url']
                  ]);
                }}
              />
            </Form.Item>
            {internalPageType && (
              <>
                {internalPageType === PAGE_TYPES.STATIC ? (
                  <Form.Item label="Page" name={[...namePath, 'slug']}>
                    <AntdSelect
                      options={STATIC_PAGES}
                      onChange={(value) => {
                        form.setFieldValue(
                          ['config', 'items', ...namePath, 'url'],
                          `${value}`
                        );
                      }}
                      placeholder="Select page"
                    />
                  </Form.Item>
                ) : (
                  <Form.Item label="Page" name={[...namePath, 'slug']}>
                    <Select
                      placeholder="Select"
                      query={GET_SLUGS}
                      variablesSelector={(filter) => ({
                        filter: {
                          ...filter,
                          pageType: internalPageType
                        }
                      })}
                      dataSelector={(data) => {
                        return (
                          data?.slugs?.slugs?.map(({ slug }) => ({
                            label: `/${slug}`,
                            value: slug
                          })) ?? []
                        );
                      }}
                      keys={{
                        data: 'slugs',
                        records: 'slugs',
                        count: 'count'
                      }}
                      onChange={({ value }) => {
                        form.setFieldValue(
                          ['config', 'items', ...namePath, 'url'],
                          `${value}`
                        );
                      }}
                    />
                  </Form.Item>
                )}
              </>
            )}
          </>
        )}

        <Form.Item label="URL" name={[...namePath, 'url']}>
          <Input
            readOnly={type === ACTION_TYPES.INTERNAL}
            disabled={type === ACTION_TYPES.INTERNAL}
            placeholder="Enter url"
          />
        </Form.Item>

        {showAsset && (
          <Form.Item label="Icon" name={[...namePath, 'asset']}>
            <SelectAsset
              modalTitle="Select Icon"
              categoryKey={ASSET_CATEGORY.ICON}
              btnText="Icon"
              dataSelector={({ id, url }) => ({
                id,
                url
              })}
            />
          </Form.Item>
        )}
      </div>
    </Space>
  );
};

export const AssetInput = ({ type = ASSET_CATEGORY.IMAGE, name }) => {
  const label = {
    [ASSET_CATEGORY.IMAGE]: 'Image',
    [ASSET_CATEGORY.VIDEO]: 'Video'
  }[type];

  const dataSelector = {
    [ASSET_CATEGORY.IMAGE]: ({ id, url }) => ({
      id,
      url
    }),
    [ASSET_CATEGORY.VIDEO]: ({
      id,
      serviceImageThumbnail,
      serviceVideoThumbnail
    }) => ({
      id,
      url: serviceVideoThumbnail || serviceImageThumbnail
    })
  }[type];
  return (
    <Form.Item
      label={label}
      name={[name, 'asset']}
      extra={
        type === ASSET_CATEGORY.IMAGE
          ? 'please upload high resolution image for better UI'
          : ''
      }
    >
      <SelectAsset
        modalTitle={`Select ${label}`}
        categoryKey={type}
        btnText={label}
        dataSelector={dataSelector}
      />
    </Form.Item>
  );
};

const CTAMultipleInput = ({
  type,
  form,
  hasButtonIcon,
  assetType,
  name,
  remove
}) => {
  return (
    <>
      {[
        MODULE_TYPES.CTA_GRID,
        MODULE_TYPES.CTA_LIST,
        MODULE_TYPES.CTA_CAROUSEL
      ].includes(type) && (
        <>
          <Form.Item
            label="Title"
            name={[name, 'title']}
            rules={[formValidatorRules?.maxLength(MAX_LENGTHS?.TITLE)]}
          >
            <Input placeholder="Enter title" />
          </Form.Item>
          {type !== MODULE_TYPES.CTA_GRID && (
            <Form.Item
              label="Description"
              name={[name, 'description']}
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS?.DESCRIPTION)]}
            >
              <Input.TextArea rows={4} placeholder="Enter description" />
            </Form.Item>
          )}
          <Form.Item
            label="Event Date"
            name={[name, 'date']}
            rules={[formValidatorRules?.maxLength(MAX_LENGTHS.FORM_INPUT)]}
          >
            <Input placeholder="Enter event date" />
          </Form.Item>
          <Form.Item
            label="Event Time"
            name={[name, 'time']}
            rules={[formValidatorRules?.maxLength(MAX_LENGTHS.FORM_INPUT)]}
          >
            <Input placeholder="Enter event time" />
          </Form.Item>
          <Form.Item
            label="Event Location"
            name={[name, 'location']}
            rules={[formValidatorRules?.maxLength(MAX_LENGTHS.FORM_INPUT)]}
          >
            <Input placeholder="Enter event location" />
          </Form.Item>
        </>
      )}
      <AssetInput type={assetType} name={name} />
      {type !== MODULE_TYPES.CTA_BANNER && (
        <>
          <ActionButton
            name="primaryAction"
            label="Primary Action"
            showAsset={hasButtonIcon}
            form={form}
            itemName={name}
          />
          {type !== MODULE_TYPES.CTA_BANNER_WITH_TEXT && (
            <ActionButton
              name="secondaryAction"
              label="Secondary Action"
              showAsset={hasButtonIcon}
              form={form}
              itemName={name}
            />
          )}
        </>
      )}
      <Button
        className="text-btn mb-12"
        type="text"
        size="small"
        onClick={() => remove(name)}
      >
        Remove Data
      </Button>
    </>
  );
};

const CTAMultipleForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const { assetType = ASSET_CATEGORY.IMAGE } =
    Form.useWatch(['config'], form) ?? {};

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const hasButtonIcon = [
    MODULE_TYPES.CTA_GRID,
    MODULE_TYPES?.CTA_CAROUSEL,
    MODULE_TYPES?.CTA_LIST
  ].includes(type);

  const onChange = () => {
    form.setFieldValue(['config', 'asset'], initialAssetValues);
  };

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          items: defaultValues?.moduleData?.config?.items?.map((item) => ({
            ...item,
            primaryAction: getActionData(item?.primaryAction, hasButtonIcon),
            secondaryAction: getActionData(item?.secondaryAction, hasButtonIcon)
          }))
        }
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    hasButtonIcon
  ]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;
    const { items } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          items: items?.map((item) => ({
            assetId: item?.asset?.id ?? null,
            date: item?.date ?? '',
            location: item?.location ?? '',
            time: item?.time ?? '',
            title: item?.title ?? '',
            ...(type !== MODULE_TYPES.CTA_GRID && {
              description: item?.description ?? ''
            }),
            primaryAction: {
              title: item?.primaryAction?.title ?? '',
              type: item?.primaryAction?.type ?? ACTION_TYPES.EXTERNAL,
              internalPageType: item?.primaryAction?.internalPageType,
              url: item?.primaryAction?.url ?? '',
              ...(hasButtonIcon && {
                assetId: item?.primaryAction?.asset?.id ?? null
              })
            },
            secondaryAction: {
              title: item?.secondaryAction?.title ?? '',
              type: item?.secondaryAction?.type ?? ACTION_TYPES.EXTERNAL,
              internalPageType: item?.secondaryAction?.internalPageType,
              url: item?.secondaryAction?.url ?? '',
              ...(hasButtonIcon && {
                assetId: item?.secondaryAction?.asset?.id ?? null
              })
            }
          }))
        }
      }
    };
    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields
        title={{
          show: false
        }}
        description={{
          show: false
        }}
      />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          <div>
            <Form.List name={['config', 'items']}>
              {(fields, { add, remove }) => (
                <>
                  {fields?.map((field) => (
                    <CTAMultipleInput
                      type={type}
                      form={form}
                      hasButtonIcon={hasButtonIcon}
                      assetType={assetType}
                      onChange={onChange}
                      remove={remove}
                      key={field?.key}
                      {...field}
                    />
                  ))}
                  <Form.Item>
                    <Button
                      className="text-btn"
                      type="text"
                      onClick={() => add(initialCTAValue)}
                      icon={<PlusOutlined />}
                    >
                      Add Data
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </Space>

        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default CTAMultipleForm;
