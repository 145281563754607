import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Divider, Form, Input, Row, Space } from 'antd';
import { isEmpty, sum, values } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import * as urlSlug from 'url-slug';
import { AppContext } from '../../AppContext';
import {
  CREATE_WORKSPACE,
  UPDATE_WORKSPACE
} from '../../app/components/sidebar/graphql/Mutation';
import {
  GET_WORKSPACE,
  GET_WORKSPACES,
  GET_WORKSPACE_SIGNED_URL
} from '../../app/components/sidebar/graphql/Queries';
import {
  DATE_FORMATS,
  DEFAULT_WEB_COLORS,
  FIXED_WEB_COLORS,
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  TIME_FORMATS
} from '../../common/constants';
import {
  formValidatorRules,
  generateCombinedValues,
  getBase64
} from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import PreviewModal from '../../components/PreviewModal';
import ProgressBar from '../../components/ProgressBar';
import useRedirectUser from '../../hooks/useRedirectUser';
import { SlugInput } from '../labels/topics/components/FormInputs';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import {
  DEFAULT_CONFIGS,
  DEFAULT_CONFIG_KEYS,
  Logos,
  MobileView,
  getConfigData,
  parseConfigData
} from './components';

const { TextArea } = Input;

function Configuration({ form, setIsCustomSelected, isCustomSelected }) {
  return (
    <div className="workspace-configs mt-24">
      {Object.entries(DEFAULT_CONFIGS)
        .filter(
          ([key]) =>
            ![
              DEFAULT_CONFIG_KEYS.FONTS,
              DEFAULT_CONFIG_KEYS.EMAIL_VERIFICATION_BASE_URL,
              DEFAULT_CONFIG_KEYS.LOGO_POSITION,
              DEFAULT_CONFIG_KEYS.LOGO_SIZE,
              DEFAULT_CONFIG_KEYS.DEFAULT_IMAGE,
              DEFAULT_CONFIG_KEYS.SHOW_MENU,
              DEFAULT_CONFIG_KEYS.LOGOS,
              DEFAULT_CONFIG_KEYS.COMMUNITY_COLORS,
              DEFAULT_CONFIG_KEYS.COMMUNITY_IMAGE_URLS
            ].includes(key)
        )
        ?.map(([key, { component: Component, name }]) => (
          <Component
            key={key}
            form={form}
            namePath={['config', name]}
            setIsCustomSelected={setIsCustomSelected}
            isCustomSelected={isCustomSelected}
          />
        ))}
    </div>
  );
}

const initialValues = {
  description: '',
  isActive: true,
  name: '',
  slug: '/',
  owner: {
    firstName: '',
    lastName: '',
    email: ''
  },
  config: {
    emailVerificationBaseURL: '',
    communityImages: [],
    communityColors: [],
    colors: DEFAULT_WEB_COLORS,
    dateTime: {
      date: DATE_FORMATS?.DATE_FORMAT_LONG,
      time: TIME_FORMATS?.TIME_FORMAT_12H_LOWER
    }
  }
};

export default function AddEditWorkspace({ history, match: { params } }) {
  const { id } = params;
  const isEdit = !!id;

  const workspaceBreadcrumbs = [
    { label: MODULES?.WORKSPACES },
    id && { label: id },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const {
    state: { workspace },
    dispatch,
    updateWorkspaceConfig
  } = useContext(AppContext);

  const [btnLoading, setBtnLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [logosProgress, setLogosProgress] = useState({});
  const [previewImage, setPreviewImage] = useState('');
  const [form] = Form?.useForm();
  const [isCustomSelected, setIsCustomSelected] = useState({
    date: false,
    time: false
  });
  const { redirectUser } = useRedirectUser();
  const totalProgress =
    sum(values(logosProgress)) / Object.keys(logosProgress)?.length;

  const [getSignedUrl, { loading }] = useLazyQuery(GET_WORKSPACE_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onError: () => {
      setBtnLoading(false);
    }
  });

  useEffect(() => {
    form?.resetFields();
  }, [id, form]);

  const [
    getWorkspace,
    { data: workspaceData, loading: fetchingDetails }
  ] = useLazyQuery(GET_WORKSPACE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const slug = res?.workspace?.slug.startsWith('/')
        ? res?.workspace?.slug
        : `/${res?.workspace?.slug}`;

      form?.setFieldsValue({
        ...res?.workspace,
        ...(res?.workspace?.image && {
          image: [{ url: res?.workspace?.image }]
        }),
        ...(res?.workspace?.favicon && {
          icon: [{ url: res?.workspace?.favicon }]
        }),
        slug,
        config:
          parseConfigData(
            res?.workspace?.config,
            form,
            setIsCustomSelected,
            isCustomSelected
          ) || initialValues?.config
      });
    },
    onError: () => {}
  });

  useEffect(() => {
    const recordId = id;
    if (recordId) {
      getWorkspace({
        variables: {
          where: { id: recordId }
        }
      });
    }
  }, [id]);

  const [addUpdateWorkspace] = useMutation(
    isEdit ? UPDATE_WORKSPACE : CREATE_WORKSPACE,
    {
      onError: () => {
        setBtnLoading(false);
      }
    }
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewImage('');
    setPreviewOpen(false);
  };

  const handleSubmit = async (data) => {
    try {
      setBtnLoading(true);
      const combinedValues = generateCombinedValues(data, FIXED_WEB_COLORS);
      const payload = {
        ...data,
        slug: data?.slug?.startsWith('/')
          ? data?.slug?.substring(1)
          : data?.slug,
        config: await getConfigData({
          config: data?.config,
          combinedValues,
          signedUrl: getSignedUrl,
          uploadProgress: setLogosProgress,
          allowedKeys: [
            DEFAULT_CONFIG_KEYS.COLORS,
            DEFAULT_CONFIG_KEYS.LOGOS,
            DEFAULT_CONFIG_KEYS.DATE_TIME_FORMATS
          ]
        })
      };
      const recordId = id;
      const response = await addUpdateWorkspace({
        variables: {
          data: payload,
          ...(recordId && {
            where: { id: recordId }
          })
        },
        refetchQueries: [GET_WORKSPACES]
      });

      setLogosProgress({});
      setBtnLoading(false);

      if (response?.data) {
        if (!recordId && response?.data?.createWorkspace?.workspace) {
          const {
            workspace: createdWorkspace
          } = response?.data?.createWorkspace;
          // eslint-disable-next-line no-undef
          localStorage?.removeItem(APP);
          dispatch({
            type: 'SET_WORKSPACE',
            data: {
              id: createdWorkspace?.id,
              label: createdWorkspace?.name,
              value: createdWorkspace?.uuid,
              config: createdWorkspace?.config
            }
          });
          dispatch({
            type: 'SET_USER_PERMISSIONS',
            data: createdWorkspace?.permissions || []
          });
          updateWorkspaceConfig(createdWorkspace?.config);
          history.push(ROUTES?.CONTENTS_VIDEOS);
        } else {
          if (workspace?.id === id) {
            dispatch({
              type: 'SET_WORKSPACE',
              data: {
                id,
                value: workspaceData?.workspace?.uuid,
                label: data?.name
              }
            });
          }
          const getRoute = redirectUser();

          history?.push(getRoute);
        }
      }
    } catch (error) {
      setLogosProgress({});
      setBtnLoading(false);
      return error;
    }
  };

  const handleCancel = () => {
    const getRoute = redirectUser();
    history?.push(getRoute);
  };

  const handleNameChange = (e) => {
    form?.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  return (
    <>
      <PreviewModal
        show={previewOpen}
        url={previewImage}
        onClose={handlePreviewClose}
      />
      <PageHeader menu={workspaceBreadcrumbs} />
      <div className="page-wrapper workspace">
        <div className="page-wrapper-body">
          <Row>
            <Col sm={12}>
              <Form
                className="add-edit-form"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={initialValues}
                disabled={loading || btnLoading}
              >
                <Form.Item
                  label="Workspace Name"
                  name="name"
                  required
                  rules={[
                    formValidatorRules?.required(
                      'Please enter workspace name!'
                    ),
                    formValidatorRules?.maxLength(MAX_LENGTHS?.NAME)
                  ]}
                >
                  <Input
                    placeholder="Enter workspace name"
                    onChange={handleNameChange}
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <TextArea
                    rows={2}
                    placeholder="Enter description"
                    disabled={btnLoading}
                  />
                </Form.Item>
                <Form.Item
                  label="Slug"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter slug!'
                    },
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE),
                    formValidatorRules?.slug()
                  ]}
                >
                  <SlugInput />
                </Form.Item>
                <Form.Item
                  className="mt-24"
                  name="isActive"
                  valuePropName="checked"
                >
                  <Switch label="Active" />
                </Form.Item>
                {!isEdit && (
                  <>
                    <Divider orientation="left">Owner Details</Divider>
                    <Form.Item name={['owner', 'firstName']} label="First Name">
                      <Input placeholder="Enter first name" />
                    </Form.Item>
                    <Form.Item name={['owner', 'lastName']} label="Last Name">
                      <Input placeholder="Enter last name" />
                    </Form.Item>
                    <Form.Item
                      name={['owner', 'email']}
                      label="Email"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter email!'
                        },
                        formValidatorRules?.email,
                        formValidatorRules?.maxLength(MAX_LENGTHS?.FORM_INPUT)
                      ]}
                    >
                      <Input type="email" placeholder="username@gmail.com" />
                    </Form.Item>
                  </>
                )}
                <Divider orientation="left">Configurations</Divider>
                <Logos
                  form={form}
                  btnLoading={btnLoading}
                  namePath={['config', 'logos']}
                  handlePreview={handlePreview}
                />
                {!isEmpty(logosProgress) && totalProgress >= 0 && (
                  <Form.Item>
                    <ProgressBar progress={totalProgress} />
                  </Form.Item>
                )}

                <Configuration
                  form={form}
                  setIsCustomSelected={setIsCustomSelected}
                  isCustomSelected={isCustomSelected}
                />

                <div className="d-flex button-section mb-8">
                  <Space>
                    <Button
                      disabled={loading || fetchingDetails}
                      loading={loading || btnLoading}
                      type="text"
                      htmlType="submit"
                      className="text-btn mr-8"
                      size="middle"
                    >
                      Save
                    </Button>

                    <Button
                      disabled={loading || btnLoading}
                      type="text"
                      className="text-btn2"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              </Form>
            </Col>
            <Col sm={12}>
              <MobileView form={form} namePath={['config', 'colors']} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
