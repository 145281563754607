import { gql } from '@apollo/client';

export const GET_PAGES = gql`
  query pagesAdmin($filter: PagesAdminFilter, $sort: PagesSort) {
    pagesAdmin(filter: $filter, sort: $sort) {
      count
      pages {
        id
        slug
        title
        description
        status
        type
        isDefault
        allowDelete
        createdBy {
          id
          email
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PAGE = gql`
  query pageAdmin($where: PageUniqueInput!) {
    pageAdmin(where: $where) {
      id
      slug
      title
      description
      status
      type
      isDefault
      metaHeader
      metaFooter
      createdBy {
        id
        email
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_PAGE_MODULE_TYPES = gql`
  query pageModuleTypes {
    pageModuleTypes {
      category
      key
      name
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query permissions {
    permissions {
      count
      permissions {
        key
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_TOPIC = gql`
  query topicsAdmin($filter: TopicsFilter) {
    topicsAdmin(filter: $filter) {
      count
      topics {
        id
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_TAGS = gql`
  query tagsAdmin($filter: TagsFilter) {
    tagsAdmin(filter: $filter) {
      count
      tags {
        id
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_CONTRIBUTORS = gql`
  query contributorsAdmin(
    $filter: ContributorsFilter
    $where: ContributorsWhereInput
  ) {
    contributorsAdmin(filter: $filter, where: $where) {
      count
      contributors {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_COLLECTIONS = gql`
  query collectionsAdmin($filter: CollectionsFilter) {
    collectionsAdmin(filter: $filter) {
      count
      collections {
        id
        title
        description
      }
    }
  }
`;

export const GET_AUTO_GENERATED_BY_COMMUNITIES = gql`
  query communitiesAdmin($filter: CommunitiesFilter) {
    communitiesAdmin(filter: $filter) {
      count
      communities {
        id
        title
        description
      }
    }
  }
`;

export const GET_AUTO_GENERATED_BY_SOURCE = gql`
  query sources($where: SourcesWhereInput, $filter: SourcesFilter) {
    sourcesAdmin(where: $where, filter: $filter) {
      sources {
        id
        name
      }
      count
    }
  }
`;

const video = `
    video {
      id
      title
      isLive
      imageThumbnail {
        url
      }
    }
`;
const videos = `
  videos {
    order
    id
    title
    isLive
    imageThumbnail {
      url
    }
  }
`;

const getVideoConfig = (configKey, key, isMultiVideo = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiVideo ? videos : video}
    autoGenerate
    autoGenerateByType
    videoAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateByData {
      ... on Contributor {
        contributorId: id
        contributorFirstName: firstName,
        contributorLastName: lastName
      }
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
      ... on Source {
        sourceId: id,
        sourceName: name
      }
    }
  }
`;

const VIDEO_CONTINUE_WATCHING_CAROUSEL_FRAGMENT = gql`
  fragment videoContinueWatchingCarouselData on VideoContinueWatchingCarouselData {
    settings {
      title
      description
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
    }
  }
`;

const VIDEO_CAROUSEL_CONFIG_FRAGMENT = gql(
  getVideoConfig('carouselConfig', 'VideoCarouselModuleConfigData')
);
const VIDEO_CAROUSEL_FRAGMENT = gql`
  ${VIDEO_CAROUSEL_CONFIG_FRAGMENT}
  fragment videoCarouselData on VideoCarouselData {
    settings {
      title
      description
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoOrder
      viewAll
      videoProgressBar
      videoKebabMenu
    }
    config {
      ...carouselConfig
      autoGenerateLimit
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const VIDEO_LIST_CONFIG_FRAGMENT = gql(
  getVideoConfig('listConfig', 'VideoListModuleConfigData')
);
const VIDEO_LIST_FRAGMENT = gql`
  ${VIDEO_LIST_CONFIG_FRAGMENT}
  fragment videoListFragment on VideoListData {
    settings {
      title
      description
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoOrder
      videoProgressBar
      videoDescription
      videoKebabMenu
      viewAll
      viewSearch
      viewFilter
    }
    config {
      ...listConfig
      autoGenerateLimit
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const VIDEO_GRID_CONFIG_FRAGMENT = gql(
  getVideoConfig('gridConfig', 'VideoGridModuleConfigData')
);
const VIDEO_GRID_FRAGMENT = gql`
  ${VIDEO_GRID_CONFIG_FRAGMENT}
  fragment videoGridFragment on VideoGridData {
    settings {
      title
      description
      videoTimestamp
      videoDate
      videoSpeaker
      videoTopic
      videoOrder
      videoProgressBar
      videoDescription
      videoKebabMenu
      viewAll
      viewSearch
      viewFilter
    }
    config {
      ...gridConfig
      autoGenerateLimit
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;
const FEATURED_VIDEO_CONFIG_FRAGMENT = gql(
  getVideoConfig('featureVideoConfig', 'FeaturedVideoModuleConfigData', false)
);
const FEATURED_VIDEO_FRAGMENT = gql`
  ${FEATURED_VIDEO_CONFIG_FRAGMENT}
  fragment featuredVideoFragment on FeaturedVideoData {
    settings {
      title
      description
      videoDate
      videoTimestamp
      videoSpeaker
      videoTopic
      videoDescription
      videoCTA
      videoSave
    }
    config {
      ...featureVideoConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const FEATURED_VIDEO_SLIDER_CONFIG_FRAGMENT = gql(
  getVideoConfig(
    'featureVideoSliderConfig',
    'FeaturedVideoSliderModuleConfigData'
  )
);
const FEATURED_VIDEO_SLIDER_FRAGMENT = gql`
  ${FEATURED_VIDEO_SLIDER_CONFIG_FRAGMENT}
  fragment featuredVideoSlicerFragment on FeaturedVideoSliderData {
    settings {
      title
      description
      videoDate
      videoTimestamp
      videoSpeaker
      videoTopic
      videoOrder
      videoDescription
      videoCTA
      videoSave
    }
    config {
      ...featureVideoSliderConfig
      autoGenerateLimit
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const VIDEO_PLAYER_FRAGMENT = gql`
  fragment videoPlayerFragment on VideoPlayerData {
    settings {
      title
      description
      videoDate
      videoSpeaker
      videoTopic
      videoOrder
      videoDescription
      donationAction
      shareAction
      saveAction
      transcriptAction
    }
    config {
      video {
        id
        title
        isLive
        imageThumbnail {
          url
        }
      }
    }
  }
`;

const podcast = `
  id
  title
  imageThumbnail {
    url
  }
`;

const featurePodcast = `
  podcast {
    order
    id
    title
    imageThumbnail {
      url
    }
  }
`;

const podcasts = `
  podcasts {
    order
    ${podcast}
  }
`;

const getPodcastConfig = (configKey, key, isMultiPodcast = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiPodcast ? podcasts : podcast}
    autoGenerate
    autoGenerateByType
    podcastAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateLimit
    autoGenerateByData {
      ... on Contributor {
        contributorId: id
        contributorFirstName: firstName,
        contributorLastName: lastName
      }
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
      ... on Source {
        sourceId: id,
        sourceName: name
      }
    }
  }
`;

const getFeaturePodcastConfig = (configKey, key) => `
  fragment ${configKey} on ${key} {
    ${featurePodcast}
    autoGenerate
    autoGenerateByType
    podcastAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateByData {
      ... on Contributor {
        contributorId: id
        contributorFirstName: firstName,
        contributorLastName: lastName
      }
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
      ... on Source {
        sourceId: id,
        sourceName: name
      }
    }
  }
`;

const PODCAST_CAROUSEL_CONFIG_FRAGMENT = gql(
  getPodcastConfig('podcastCarouselConfig', 'PodcastCarouselModuleConfigData')
);
const PODCAST_CAROUSEL_FRAGMENT = gql`
  ${PODCAST_CAROUSEL_CONFIG_FRAGMENT}
  fragment podcastCarouselData on PodcastCarouselData {
    settings {
      title
      description
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastOrder
      viewAll
      podcastKebabMenu
    }
    config {
      ...podcastCarouselConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const PODCAST_LIST_CONFIG_FRAGMENT = gql(
  getPodcastConfig('podcastListConfig', 'PodcastListModuleConfigData')
);
const PODCAST_LIST_FRAGMENT = gql`
  ${PODCAST_LIST_CONFIG_FRAGMENT}
  fragment podcastListFragment on PodcastListData {
    settings {
      title
      description
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastOrder
      podcastDescription
      podcastKebabMenu
      viewAll
      viewSearch
      viewFilter
    }
    config {
      ...podcastListConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const PODCAST_GRID_CONFIG_FRAGMENT = gql(
  getPodcastConfig('podcastGridConfig', 'PodcastGridModuleConfigData')
);
const PODCAST_GRID_FRAGMENT = gql`
  ${PODCAST_GRID_CONFIG_FRAGMENT}
  fragment podcastGridFragment on PodcastGridData {
    settings {
      title
      description
      podcastTimestamp
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastOrder
      podcastKebabMenu
      viewAll
      viewSearch
      viewFilter
    }
    config {
      ...podcastGridConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const FEATURED_PODCAST_CONFIG_FRAGMENT = gql(
  getFeaturePodcastConfig(
    'featurePodcastConfig',
    'FeaturedPodcastModuleConfigData',
    false
  )
);

const FEATURED_PODCAST_FRAGMENT = gql`
  ${FEATURED_PODCAST_CONFIG_FRAGMENT}
  fragment featuredPodcastFragment on FeaturedPodcastData {
    settings {
      title
      description
      podcastDate
      podcastTimestamp
      podcastSpeaker
      podcastTopic
      podcastOrder
      podcastDescription
      podcastCTA
      podcastSave
    }
    config {
      ...featurePodcastConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const FEATURED_PODCAST_SLIDER_CONFIG_FRAGMENT = gql(
  getPodcastConfig(
    'featurePodcastSliderConfig',
    'FeaturedPodcastSliderModuleConfigData'
  )
);

const FEATURED_PODCAST_SLIDER_FRAGMENT = gql`
  ${FEATURED_PODCAST_SLIDER_CONFIG_FRAGMENT}
  fragment featuredPodcastSliderFragment on FeaturedPodcastSliderData {
    settings {
      title
      description
      podcastDate
      podcastTimestamp
      podcastSpeaker
      podcastTopic
      podcastOrder
      podcastDescription
      podcastCTA
      podcastSave
    }
    config {
      ...featurePodcastSliderConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const PODCAST_PLAYER_FRAGMENT = gql`
  fragment podcastPlayerFragment on PodcastPlayerData {
    settings {
      title
      description
      podcastDate
      podcastSpeaker
      podcastTopic
      podcastOrder
      podcastDescription
      shareAction
      saveAction
      transcriptAction
    }
    config {
      podcast {
        id
        title
        imageThumbnail {
          url
        }
      }
    }
  }
`;

const collection = `
  collection {
    id
    title
    thumbnail {
      url
    }
  }
`;
const collections = `
  collections {
    order
    id
    title
    thumbnail {
      url
    }
  }
`;
const getCollectionConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    collectionAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? collections : collection}
  }
`;

const COLLECTION_CAROUSEL_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionCarouselConfigFragment',
    'CollectionCarouselModuleConfigData'
  )
);

const COLLECTION_CAROUSEL_FRAGMENT = gql`
  ${COLLECTION_CAROUSEL_CONFIG_FRAGMENT}
  fragment collectionCarouselFragment on CollectionCarouselData {
    settings {
      title
      description
      viewAll
      numberOfItems
    }
    config {
      ...collectionCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const COLLECTION_GRID_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionGridConfigFragment',
    'CollectionGridModuleConfigData'
  )
);

const COLLECTION_GRID_FRAGMENT = gql`
  ${COLLECTION_GRID_CONFIG_FRAGMENT}
  fragment collectionGridFragment on CollectionGridData {
    settings {
      title
      description
      numberOfItems
      viewAll
    }
    config {
      ...collectionGridConfigFragment
      autoGenerateLimit
    }
  }
`;

const COLLECTION_LIST_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionListConfigFragment',
    'CollectionListModuleConfigData'
  )
);
const COLLECTION_LIST_FRAGMENT = gql`
  ${COLLECTION_LIST_CONFIG_FRAGMENT}
  fragment collectionListFragment on CollectionListData {
    settings {
      title
      description
      collectionDescription
      numberOfItems
      viewAll
    }
    config {
      ...collectionListConfigFragment
      autoGenerateLimit
    }
  }
`;

const FEATURED_COLLECTION_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'featuredCollectionConfigFragment',
    'FeaturedCollectionModuleConfigData',
    false
  )
);
const FEATURED_COLLECTION_FRAGMENT = gql`
  ${FEATURED_COLLECTION_CONFIG_FRAGMENT}
  fragment featuredCollectionFragment on FeaturedCollectionData {
    settings {
      numberOfItems
      collectionDescription
    }
    config {
      ...featuredCollectionConfigFragment
    }
  }
`;

const getCollectionDetailConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    collectionAutoGenerateBy: autoGenerateSortBy
    ${isMultiple ? collections : collection}
  }
`;

const COLLECTION_DETAIL_LIST_CONFIG_FRAGMENT = gql(
  getCollectionDetailConfig(
    'collectionDetailListConfigFragment',
    'CollectionDetailListModuleConfigData',
    false
  )
);
const COLLECTION_DETAIL_LIST_FRAGMENT = gql`
  ${COLLECTION_DETAIL_LIST_CONFIG_FRAGMENT}
  fragment collectionDetailListFragment on CollectionDetailListData {
    config {
      ...collectionDetailListConfigFragment
      subCollection {
        id
        title
      }
    }
  }
`;
const COLLECTION_DETAIL_GRID_CONFIG_FRAGMENT = gql(
  getCollectionDetailConfig(
    'collectionDetailGridConfigFragment',
    'CollectionDetailGridModuleConfigData',
    false
  )
);
const COLLECTION_DETAIL_GRID_FRAGMENT = gql`
  ${COLLECTION_DETAIL_GRID_CONFIG_FRAGMENT}
  fragment collectionDetailGridFragment on CollectionDetailGridData {
    config {
      ...collectionDetailGridConfigFragment
      subCollection {
        id
        title
      }
    }
  }
`;
const FEATURED_COLLECTION_SLIDER_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'featuredCollectionSliderConfigFragment',
    'FeaturedCollectionSliderModuleConfigData'
  )
);
const FEATURED_COLLECTION_SLIDER_FRAGMENT = gql`
  ${FEATURED_COLLECTION_SLIDER_CONFIG_FRAGMENT}
  fragment featuredCollectionSliderFragment on FeaturedCollectionSliderData {
    settings {
      collectionDescription
    }
    config {
      ...featuredCollectionSliderConfigFragment
      autoGenerateLimit
    }
  }
`;

const COLLECTION_ITEM_CAROUSEL_FRAGMENT = gql`
  fragment CollectionItemCarouselFragment on CollectionItemCarouselData {
    settings {
      title
      description
      viewAll
    }
    config {
      autoGenerate
      autoGenerateLimit
      subCollection {
        id
        title
      }
      collection {
        id
        title
        thumbnail {
          url
        }
      }
    }
  }
`;

const COLLECTION_ITEM_GRID_FRAGMENT = gql`
  fragment CollectionItemGridFragment on CollectionItemGridData {
    settings {
      title
      description
      viewAll
    }
    config {
      autoGenerate
      autoGenerateLimit
      subCollection {
        id
        title
      }
      collection {
        id
        title
        thumbnail {
          url
        }
      }
    }
  }
`;

const COLLECTION_ITEM_LIST_FRAGMENT = gql`
  fragment CollectionItemListFragment on CollectionItemListData {
    settings {
      title
      description
      viewAll
    }
    config {
      autoGenerate
      autoGenerateLimit
      subCollection {
        id
        title
      }
      collection {
        id
        title
        thumbnail {
          url
        }
      }
    }
  }
`;

const contributor = `
  contributor {
    id
    firstName
    lastName
    thumbnail {
      url
    }
    image {
      url
    }
    primaryColor
  }
`;
const contributors = `
  contributors {
    order
    id
    firstName
    lastName
    thumbnail {
      url
    }
    image {
      url
    }
    primaryColor
  }
`;
const getContributorConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    contributorAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? contributors : contributor}
  }
`;

const CONTRIBUTOR_CAROUSEL_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'contributorCarouselConfigFragment',
    'ContributorCarouselModuleConfigData'
  )
);
const CONTRIBUTOR_CAROUSEL_FRAGMENT = gql`
  ${CONTRIBUTOR_CAROUSEL_CONFIG_FRAGMENT}
  fragment contributorCarouselFragment on ContributorCarouselData {
    settings {
      title
      description
      viewAll
      numberOfVideos
      numberOfPodcasts
      numberOfArticles
    }
    config {
      ...contributorCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const CONTRIBUTOR_LIST_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'contributorListConfigFragment',
    'ContributorListModuleConfigData'
  )
);

const CONTRIBUTOR_LIST_FRAGMENT = gql`
  ${CONTRIBUTOR_LIST_CONFIG_FRAGMENT}
  fragment contributorListFragment on ContributorListData {
    settings {
      title
      description
      separator
      contributorDescription
      numberOfVideos
      viewAll
      numberOfPodcasts
      numberOfArticles
    }
    config {
      ...contributorListConfigFragment
      autoGenerateLimit
    }
  }
`;

const CONTRIBUTOR_GRID_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'contributorGridConfigFragment',
    'ContributorGridModuleConfigData'
  )
);
const CONTRIBUTOR_GRID_FRAGMENT = gql`
  ${CONTRIBUTOR_GRID_CONFIG_FRAGMENT}
  fragment contributorGridFragment on ContributorGridData {
    settings {
      title
      description
      viewAll
      contributorDescription
      numberOfVideos
      numberOfPodcasts
      numberOfArticles
    }
    config {
      ...contributorGridConfigFragment
      autoGenerateLimit
    }
  }
`;

const FEATURED_CONTRIBUTOR_CONFIG_FRAGMENT = gql(
  getContributorConfig(
    'featuredContributorGridConfigFragment',
    'FeaturedContributorModuleConfigData',
    false
  )
);
const FEATURED_CONTRIBUTOR_FRAGMENT = gql`
  ${FEATURED_CONTRIBUTOR_CONFIG_FRAGMENT}
  fragment featuredContributorGridFragment on FeaturedContributorData {
    settings {
      contributorImage
      contributorName
      numberOfVideos
      numberOfPodcasts
      numberOfArticles
    }
    config {
      ...featuredContributorGridConfigFragment
    }
  }
`;

const tag = `
  tag {
    id
    name
  }
`;
const tags = `
  tags {
    order
    id
    name
  }
`;
const getTagConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    tagAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? tags : tag}
  }
`;

const TAG_CAROUSEL_CONFIG_FRAGMENT = gql(
  getTagConfig('tagCarouselConfigFragment', 'TagCarouselModuleConfigData')
);
const TAG_CAROUSEL_FRAGMENT = gql`
  ${TAG_CAROUSEL_CONFIG_FRAGMENT}
  fragment tagCarouselFragment on TagCarouselData {
    settings {
      title
      description
    }
    config {
      ...tagCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const TAG_CLOUD_CONFIG_FRAGMENT = gql(
  getTagConfig('tagCloudConfigFragment', 'TagCloudModuleConfigData')
);
const TAG_CLOUD_FRAGMENT = gql`
  ${TAG_CLOUD_CONFIG_FRAGMENT}
  fragment tagCloudFragment on TagCloudData {
    settings {
      title
      description
    }
    config {
      ...tagCloudConfigFragment
      autoGenerateLimit
    }
  }
`;

const FEATURED_TAG_CONFIG_FRAGMENT = gql(
  getTagConfig(
    'featuredTagConfigFragment',
    'FeaturedTagModuleConfigData',
    false
  )
);
const FEATURED_TAG_FRAGMENT = gql`
  ${FEATURED_TAG_CONFIG_FRAGMENT}
  fragment featuredTagFragment on FeaturedTagData {
    config {
      ...featuredTagConfigFragment
    }
  }
`;

const topic = `
  topic {
    id
    name
  }
`;
const topics = `
  topics {
    order
    id
    name
  }
`;
const getTopicsConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    topicAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? topics : topic}
  }
`;

const TOPIC_CAROUSEL_CONFIG_FRAGMENT = gql(
  getTopicsConfig(
    'topicCarouselConfigFragment',
    'TopicCarouselModuleConfigData'
  )
);
const TOPIC_CAROUSEL_FRAGMENT = gql`
  ${TOPIC_CAROUSEL_CONFIG_FRAGMENT}
  fragment topicCarouselFragment on TopicCarouselData {
    settings {
      title
      description
      viewAll
      topicImage
    }
    config {
      ...topicCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const TOPIC_LIST_CONFIG_FRAGMENT = gql(
  getTopicsConfig('topicListConfigFragment', 'TopicListModuleConfigData')
);
const TOPIC_LIST_FRAGMENT = gql`
  ${TOPIC_LIST_CONFIG_FRAGMENT}
  fragment topicListFragment on TopicListData {
    settings {
      title
      description
      viewAll
      topicImage
    }
    config {
      ...topicListConfigFragment
      autoGenerateLimit
    }
  }
`;

const TOPIC_GRID_CONFIG_FRAGMENT = gql(
  getTopicsConfig('topicGridConfigFragment', 'TopicGridModuleConfigData')
);
const TOPIC_GRID_FRAGMENT = gql`
  ${TOPIC_GRID_CONFIG_FRAGMENT}
  fragment topicGridFragment on TopicGridData {
    settings {
      title
      description
      viewAll
      topicImage
    }
    config {
      ...topicGridConfigFragment
      autoGenerateLimit
    }
  }
`;

const FEATURED_TOPIC_CONFIG_FRAGMENT = gql(
  getTopicsConfig(
    'featuredTopicConfigFragment',
    'FeaturedTopicModuleConfigData',
    false
  )
);
const FEATURED_TOPIC_FRAGMENT = gql`
  ${FEATURED_TOPIC_CONFIG_FRAGMENT}
  fragment featuredTopicFragment on FeaturedTopicData {
    settings {
      layout
      topicImage
    }
    config {
      ...featuredTopicConfigFragment
    }
  }
`;

const CTA_BLOCK_FRAGMENT = gql`
  fragment ctaBlockFragment on CTABlockData {
    settings {
      date
      description
      location
      primaryAction
      secondaryAction
      time
    }
    config {
      date
      location
      time
      asset {
        id
        url
      }
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const CTA_CAROUSEL_FRAGMENT = gql`
  fragment ctaCarouselFragment on CTACarouselData {
    settings {
      date
      description
      location
      primaryAction
      secondaryAction
      time
    }
    config {
      items {
        date
        description
        location
        time
        title
        asset {
          id
          url
        }
        primaryAction {
          title
          type
          url
          internalPageType
          asset {
            id
            url
          }
        }
        secondaryAction {
          title
          type
          url
          internalPageType
          asset {
            id
            url
          }
        }
      }
    }
  }
`;

const CTA_LIST_FRAGMENT = gql`
  fragment ctaListFragment on CTAListData {
    settings {
      date
      description
      location
      primaryAction
      secondaryAction
      time
    }
    config {
      items {
        date
        description
        location
        time
        title
        asset {
          id
          url
        }
        primaryAction {
          title
          type
          url
          internalPageType
          asset {
            id
            url
          }
        }
        secondaryAction {
          title
          type
          url
          internalPageType
          asset {
            id
            url
          }
        }
      }
    }
  }
`;

const CTA_GRID_FRAGMENT = gql`
  fragment ctaGridFragment on CTAGridData {
    settings {
      date
      location
      primaryAction
      secondaryAction
      time
    }
    config {
      items {
        date
        location
        time
        title
        asset {
          id
          url
        }
        primaryAction {
          title
          type
          url
          internalPageType
          asset {
            id
            url
          }
        }
        secondaryAction {
          title
          type
          url
          internalPageType
          asset {
            id
            url
          }
        }
      }
    }
  }
`;

const CTA_BANNER_WITH_TEXT_FRAGMENT = gql`
  fragment ctaBannerWithTextFragment on CTABannerWithTextData {
    settings {
      description
      primaryAction
    }
    config {
      asset {
        id
        url
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const HERO_FRAGMENT = gql`
  fragment heroFragment on HeroData {
    settings {
      date
      description
      image
      location
      primaryAction
      secondaryAction
      time
    }
    config {
      asset {
        id
        url
        categoryKey
        serviceImageThumbnail
        serviceVideoThumbnail
      }
      time
      date
      location
      alignment
      layout
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const HTML_TEXT_BLOCK_FRAGMENT = gql`
  fragment htmlTextBlockFragment on HTMLTextBlockData {
    config {
      content
    }
    settings {
      description
      title
    }
  }
`;

const AD_FRAGMENT = gql`
  fragment adFragment on ADData {
    config {
      adBannerSize
      adType
    }
  }
`;

const INFORMATION_FRAGMENT = gql`
  fragment informationFragment on InformationData {
    config {
      asset {
        id
        url
      }
      imageOrientation
      imageType
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const DONATION_FRAGMENT = gql`
  fragment donationFragment on DonationData {
    settings {
      description
      secondaryTitleAndDescription
      title
      coverTransactionFee
      newsLetterOptIn
    }
    config {
      key
      secondaryTitle
      secondaryDescription
      asset {
        id
        url
        categoryKey
        serviceImageThumbnail
        serviceVideoThumbnail
      }
    }
  }
`;

const COPY_HEAVY_FRAGMENT = gql`
  fragment copyHeavyFragment on CopyHeavyData {
    settings {
      title
      date
      contributor
      textFields
      primaryAction
      secondaryAction
    }
    config {
      date
      text
      alignment
      contributor {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const COPY_HEAVY_HERO_FRAGMENT = gql`
  fragment copyHeavyHeroFragment on CopyHeavyHeroData {
    settings {
      contributor
      date
      image
      description
      location
      primaryAction
      secondaryAction
      time
    }
    config {
      asset {
        id
        url
      }
      date
      location
      time
      text
      alignment
      contributor {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const QUOTE_FRAGMENT = gql`
  fragment quoteFragment on QuoteData {
    settings {
      authorName
    }
    config {
      quotes {
        text
        author {
          name
          subTitle
          asset {
            id
            url
          }
        }
      }
    }
  }
`;

const FAQS_FRAGMENT = gql`
  fragment faqsFragment on FAQData {
    settings {
      description
    }
    config {
      questions {
        question
        answer
      }
    }
  }
`;
const SPACER_FRAGMENT = gql`
  fragment spaceFragment on SpacerData {
    config {
      desktop
      mobile
      tablet
      tv
    }
  }
`;

const BANNER_FRAGMENT = gql`
  fragment bannerFragment on BannerData {
    settings {
      image
    }
    config {
      asset {
        id
        url
      }
    }
  }
`;
const article = `
    article {
      id
      title
      imageThumbnail {
        url
      }
    }
`;
const articles = `
  articles {
    order
    id
    title
    imageThumbnail {
      url
    }
  }
`;

const getArticleConfig = (configKey, key, isMultiArticle = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiArticle ? articles : article}
    autoGenerate
    autoGenerateByType
    autoGenerateSortBy
    autoGenerateByData {
      ... on Contributor {
        contributorId: id
        contributorFirstName: firstName,
        contributorLastName: lastName
      }
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
      ... on Source {
        sourceId: id,
        sourceName: name
      }
    }
  }
`;

const ARTICLE_CAROUSEL_CONFIG_FRAGMENT = gql(
  getArticleConfig('articleCarouselConfig', 'ArticleCarouselModuleConfigData')
);

const ARTICLE_CAROUSEL_FRAGMENT = gql`
  ${ARTICLE_CAROUSEL_CONFIG_FRAGMENT}
  fragment articleCarouselFragment on ArticleCarouselData {
    settings {
      sourceLogo
      viewAll
      articleTopic
      articleDescription
      articleKebabMenu
      title
      description
    }
    config {
      ...articleCarouselConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;
const ARTICLE_GRID_CONFIG_FRAGMENT = gql(
  getArticleConfig('articleGridConfig', 'ArticleGridModuleConfigData')
);

const ARTICLE_GRID_FRAGMENT = gql`
  ${ARTICLE_GRID_CONFIG_FRAGMENT}
  fragment articleGridFragment on ArticleGridData {
    settings {
      articleKebabMenu
      articleTopic
      description
      sourceLogo
      title
      viewAll
      viewSearch
    }
    config {
      ...articleGridConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;
const ARTICLE_FEATURED_SLIDER_CONFIG_FRAGMENT = gql(
  getArticleConfig(
    'featuredArticleSliderConfig',
    'FeaturedArticleSliderModuleConfigData'
  )
);

const ARTICLE_FEATURED_SLIDER_FRAGMENT = gql`
  ${ARTICLE_FEATURED_SLIDER_CONFIG_FRAGMENT}
  fragment featuredArticleSliderFragment on FeaturedArticleSliderData {
    settings {
      articleDescription
      articleTitle
      articleTopic
      sourceLogo
    }
    config {
      ...featuredArticleSliderConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const ARTICLE_LIST_CONFIG_FRAGMENT = gql(
  getArticleConfig('articleListConfig', 'ArticleListModuleConfigData')
);

const ARTICLE_LIST_FRAGMENT = gql`
  ${ARTICLE_LIST_CONFIG_FRAGMENT}
  fragment articleListFragment on ArticleListData {
    config {
      ...articleListConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
    settings {
      sourceLogo
      viewAll
      articleTopic
      articleDescription
      articleKebabMenu
      viewSearch
    }
  }
`;

const FEATURED_ARTICLE_CONFIG_FRAGMENT = gql(
  getArticleConfig(
    'featuredArticleConfig',
    'FeaturedArticleModuleConfigData',
    false
  )
);

const FEATURED_ARTICLE_FRAGMENT = gql`
  ${FEATURED_ARTICLE_CONFIG_FRAGMENT}
  fragment featuredArticleFragment on FeaturedArticleData {
    settings {
      sourceLogo
      articleTopic
      articleDescription
    }
    config {
      ...featuredArticleConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;
const ARTICLE_DISPLAY_CONFIG_FRAGMENT = gql(
  getArticleConfig(
    'articleDisplayConfig',
    'ArticleDisplayModuleConfigData',
    false
  )
);

const ARTICLE_DISPLAY_FRAGMENT = gql`
  ${ARTICLE_DISPLAY_CONFIG_FRAGMENT}
  fragment articleDisplayFragment on ArticleDisplayData {
    settings {
      articleContributor
      articleDate
      articleTopic
    }
    config {
      ...articleDisplayConfig
      autoGenerateBySubCollectionData {
        id
        title
      }
    }
  }
`;

const source = `
  source {
    id
    image {
      id
      url
    }
  }
`;

const sources = `
  sources {
    id
    order
    image {
      id
      url
    }
 }
`;

const getSourcesConfig = (configKey, key, isMultiArticle = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiArticle ? sources : source}
    autoGenerate
    sourceAutoGenerateBy:autoGenerateSortBy
  }
`;

const SOURCE_CAROUSEL_CONFIG_FRAGMENT = gql(
  getSourcesConfig('sourceCarouselConfig', 'SourceCarouselModuleConfigData')
);

const SOURCE_CAROUSEL_FRAGMENT = gql`
  ${SOURCE_CAROUSEL_CONFIG_FRAGMENT}
  fragment sourceCarouselFragment on SourceCarouselData {
    settings {
      description
      title
    }
    config {
      ...sourceCarouselConfig
      autoGenerateLimit
    }
  }
`;

const SOURCE_LIST_CONFIG_FRAGMENT = gql(
  getSourcesConfig('sourceListConfig', 'SourceListModuleConfigData')
);

const SOURCE_LIST_FRAGMENT = gql`
  ${SOURCE_LIST_CONFIG_FRAGMENT}
  fragment sourceListFragment on SourceListData {
    settings {
      description
      title
    }
    config {
      ...sourceListConfig
      autoGenerateLimit
    }
  }
`;

const SOURCE_GRID_CONFIG_FRAGMENT = gql(
  getSourcesConfig('sourceGridConfig', 'SourceGridModuleConfigData')
);

const SOURCE_GRID_FRAGMENT = gql`
  ${SOURCE_GRID_CONFIG_FRAGMENT}
  fragment sourceGridFragment on SourceGridData {
    settings {
      description
      title
    }
    config {
      ...sourceGridConfig
      autoGenerateLimit
    }
  }
`;

const FEATURED_SOURCE_CONFIG_FRAGMENT = gql(
  getSourcesConfig(
    'featuredSourceConfig',
    'FeaturedSourceModuleConfigData',
    false
  )
);

const FEATURED_SOURCE_FRAGMENT = gql`
  ${FEATURED_SOURCE_CONFIG_FRAGMENT}
  fragment featuredSourceFragment on FeaturedSourceData {
    settings {
      description
      numberOfArticles
      numberOfPodcasts
      numberOfVideos
      sourceImage
    }
    config {
      ...featuredSourceConfig
    }
  }
`;

const forms = `
  forms {
    id
    title
  }
`;

const form = `
  form {
    id
    title
  }
`;

const getFormsConfig = (configKey, key, isMultiArticle = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiArticle ? forms : form}
  }
`;

const FORMS_CAROUSEL_CONFIG_FRAGMENT = gql(
  getFormsConfig('formCarouselConfig', 'FormCarouselModuleConfigData')
);

const FORMS_CAROUSEL_FRAGMENT = gql`
  ${FORMS_CAROUSEL_CONFIG_FRAGMENT}
  fragment formCarouselFragment on FormCarouselData {
    settings {
      description
      title
    }
    config {
      ...formCarouselConfig
      autoGenerate
      autoGenerateByData {
        ... on Contributor {
          contributorId: id
          contributorFirstName: firstName
          contributorLastName: lastName
        }
        ... on Topic {
          topicId: id
          topicName: name
        }
        ... on Tag {
          tagId: id
          tagName: name
        }
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
        ... on Source {
          sourceId: id
          sourceName: name
        }
      }
      autoGenerateBySubCollectionData {
        id
        title
      }
      formAutoGenerateByType: autoGenerateByType
      autoGenerateLimit
      formAutoGenerateSortBy: autoGenerateSortBy
      formLimit
      sortBy
      type
    }
  }
`;

const FORMS_DISPLAY_CONFIG_FRAGMENT = gql(
  getFormsConfig('formDisplayConfig', 'FormDisplayModuleConfigData', false)
);

const FORMS_DISPLAY_FRAGMENT = gql`
  ${FORMS_DISPLAY_CONFIG_FRAGMENT}
  fragment formDisplayFragment on FormDisplayData {
    settings {
      formDescription
      formTitle
    }
    config {
      ...formDisplayConfig
      autoFillForm
    }
  }
`;
export const PAGE_MODULE_DETAILS_FRAGMENT = gql`
  ${VIDEO_CONTINUE_WATCHING_CAROUSEL_FRAGMENT}
  ${VIDEO_CAROUSEL_FRAGMENT}
  ${VIDEO_LIST_FRAGMENT}
  ${VIDEO_GRID_FRAGMENT}
  ${FEATURED_VIDEO_FRAGMENT}
  ${FEATURED_VIDEO_SLIDER_FRAGMENT}
  ${VIDEO_PLAYER_FRAGMENT}
  ${PODCAST_CAROUSEL_FRAGMENT}
  ${PODCAST_LIST_FRAGMENT}
  ${PODCAST_GRID_FRAGMENT}
  ${FEATURED_PODCAST_FRAGMENT}
  ${FEATURED_PODCAST_SLIDER_FRAGMENT}
  ${PODCAST_PLAYER_FRAGMENT}
  ${COLLECTION_CAROUSEL_FRAGMENT}
  ${COLLECTION_GRID_FRAGMENT}
  ${COLLECTION_LIST_FRAGMENT}
  ${FEATURED_COLLECTION_FRAGMENT}
  ${COLLECTION_DETAIL_LIST_FRAGMENT}
  ${COLLECTION_DETAIL_GRID_FRAGMENT}
  ${COLLECTION_ITEM_CAROUSEL_FRAGMENT}
  ${COLLECTION_ITEM_GRID_FRAGMENT}
  ${COLLECTION_ITEM_LIST_FRAGMENT}
  ${FEATURED_COLLECTION_SLIDER_FRAGMENT}
  ${CONTRIBUTOR_CAROUSEL_FRAGMENT}
  ${CONTRIBUTOR_LIST_FRAGMENT}
  ${CONTRIBUTOR_GRID_FRAGMENT}
  ${FEATURED_CONTRIBUTOR_FRAGMENT}
  ${TAG_CAROUSEL_FRAGMENT}
  ${TAG_CLOUD_FRAGMENT}
  ${FEATURED_TAG_FRAGMENT}
  ${TOPIC_CAROUSEL_FRAGMENT}
  ${TOPIC_GRID_FRAGMENT}
  ${TOPIC_LIST_FRAGMENT}
  ${FEATURED_TOPIC_FRAGMENT}
  ${CTA_BLOCK_FRAGMENT}
  ${CTA_CAROUSEL_FRAGMENT}
  ${CTA_LIST_FRAGMENT}
  ${CTA_GRID_FRAGMENT}
  ${CTA_BANNER_WITH_TEXT_FRAGMENT}
  ${HERO_FRAGMENT}
  ${HTML_TEXT_BLOCK_FRAGMENT}
  ${AD_FRAGMENT}
  ${DONATION_FRAGMENT}
  ${COPY_HEAVY_FRAGMENT}
  ${COPY_HEAVY_HERO_FRAGMENT}
  ${QUOTE_FRAGMENT}
  ${INFORMATION_FRAGMENT}
  ${FAQS_FRAGMENT}
  ${SPACER_FRAGMENT}
  ${BANNER_FRAGMENT}
  ${ARTICLE_CAROUSEL_FRAGMENT}
  ${ARTICLE_GRID_FRAGMENT}
  ${ARTICLE_FEATURED_SLIDER_FRAGMENT}
  ${ARTICLE_LIST_FRAGMENT}
  ${FEATURED_ARTICLE_FRAGMENT}
  ${ARTICLE_DISPLAY_FRAGMENT}
  ${SOURCE_CAROUSEL_FRAGMENT}
  ${SOURCE_LIST_FRAGMENT}
  ${SOURCE_GRID_FRAGMENT}
  ${FEATURED_SOURCE_FRAGMENT}
  ${FORMS_CAROUSEL_FRAGMENT}
  ${FORMS_DISPLAY_FRAGMENT}
  fragment pageModuleDetails on PageModule {
    id
    title
    description
    status
    permissions
    type
    isDefaultModule
    moduleData {
      ... on VideoContinueWatchingCarouselData {
        ...videoContinueWatchingCarouselData
      }
      ... on VideoCarouselData {
        ...videoCarouselData
      }
      ... on VideoListData {
        ...videoListFragment
      }
      ... on VideoGridData {
        ...videoGridFragment
      }
      ... on FeaturedVideoData {
        ...featuredVideoFragment
      }
      ... on FeaturedVideoSliderData {
        ...featuredVideoSlicerFragment
      }
      ... on VideoPlayerData {
        ...videoPlayerFragment
      }
      ... on PodcastCarouselData {
        ...podcastCarouselData
      }
      ... on PodcastListData {
        ...podcastListFragment
      }
      ... on PodcastGridData {
        ...podcastGridFragment
      }
      ... on FeaturedPodcastData {
        ...featuredPodcastFragment
      }
      ... on FeaturedPodcastSliderData {
        ...featuredPodcastSliderFragment
      }
      ... on PodcastPlayerData {
        ...podcastPlayerFragment
      }
      ... on CollectionCarouselData {
        ...collectionCarouselFragment
      }
      ... on CollectionListData {
        ...collectionListFragment
      }
      ... on CollectionGridData {
        ...collectionGridFragment
      }
      ... on FeaturedCollectionData {
        ...featuredCollectionFragment
      }
      ... on CollectionDetailListData {
        ...collectionDetailListFragment
      }
      ... on CollectionDetailGridData {
        ...collectionDetailGridFragment
      }
      ... on FeaturedCollectionSliderData {
        ...featuredCollectionSliderFragment
      }
      ... on CollectionItemCarouselData {
        ...CollectionItemCarouselFragment
      }
      ... on CollectionItemGridData {
        ...CollectionItemGridFragment
      }
      ... on CollectionItemListData {
        ...CollectionItemListFragment
      }
      ... on ContributorCarouselData {
        ...contributorCarouselFragment
      }
      ... on ContributorListData {
        ...contributorListFragment
      }
      ... on ContributorGridData {
        ...contributorGridFragment
      }
      ... on FeaturedContributorData {
        ...featuredContributorGridFragment
      }
      ... on TagCarouselData {
        ...tagCarouselFragment
      }
      ... on TagCloudData {
        ...tagCloudFragment
      }
      ... on FeaturedTagData {
        ...featuredTagFragment
      }
      ... on TopicCarouselData {
        ...topicCarouselFragment
      }
      ... on TopicListData {
        ...topicListFragment
      }
      ... on TopicGridData {
        ...topicGridFragment
      }
      ... on FeaturedTopicData {
        ...featuredTopicFragment
      }
      ... on CTABlockData {
        ...ctaBlockFragment
      }
      ... on CTABannerWithTextData {
        ...ctaBannerWithTextFragment
      }
      ... on HeroData {
        ...heroFragment
      }
      ... on HTMLTextBlockData {
        ...htmlTextBlockFragment
      }
      ... on ADData {
        ...adFragment
      }
      ... on DonationData {
        ...donationFragment
      }
      ... on CopyHeavyData {
        ...copyHeavyFragment
      }
      ... on CopyHeavyHeroData {
        ...copyHeavyHeroFragment
      }
      ... on QuoteData {
        ...quoteFragment
      }
      ... on InformationData {
        ...informationFragment
      }
      ... on FAQData {
        ...faqsFragment
      }
      ... on SpacerData {
        ...spaceFragment
      }
      ... on BannerData {
        ...bannerFragment
      }
      ... on ArticleCarouselData {
        ...articleCarouselFragment
      }
      ... on ArticleGridData {
        ...articleGridFragment
      }
      ... on FeaturedArticleSliderData {
        ...featuredArticleSliderFragment
      }
      ... on ArticleListData {
        ...articleListFragment
      }
      ... on FeaturedArticleData {
        ...featuredArticleFragment
      }
      ... on ArticleDisplayData {
        ...articleDisplayFragment
      }
      ... on CTACarouselData {
        ...ctaCarouselFragment
      }
      ... on CTAListData {
        ...ctaListFragment
      }
      ... on CTAGridData {
        ...ctaGridFragment
      }
      ... on SourceCarouselData {
        ...sourceCarouselFragment
      }
      ... on SourceListData {
        ...sourceListFragment
      }
      ... on SourceGridData {
        ...sourceGridFragment
      }
      ... on FeaturedSourceData {
        ...featuredSourceFragment
      }
      ... on FormCarouselData {
        ...formCarouselFragment
      }
      ... on FormDisplayData {
        ...formDisplayFragment
      }
    }
  }
`;

export const GET_PAGE_DETAILS = gql`
  ${PAGE_MODULE_DETAILS_FRAGMENT}
  query pageAdmin($id: ID!) {
    pageAdmin(where: { id: $id }) {
      slug
      title
      status
      description
      metaHeader
      metaFooter
      permissions
      allowDelete
      isDefault
      type
      apps {
        id
        name
      }
      modules {
        ...pageModuleDetails
      }
    }
  }
`;

export const GET_PAGE_DETAILS_FOR_PREVIEW = gql`
  fragment AssetFragment on Asset {
    categoryKey
    url
    blurhash
    serviceVideoThumbnail
    serviceImageThumbnail
  }

  fragment ContributorFragment on Contributor {
    id
    firstName
    lastName
    subTitle
    description
    image {
      url
      blurhash
    }
    thumbnail {
      url
      blurhash
    }
    primaryColor
    videosCount
  }

  fragment TopicFragment on Topic {
    id
    key
    name
    primaryColor
  }

  fragment TagFragment on Tag {
    id
    name
  }

  fragment VideoFragment on Video {
    id
    title
    description
    topics {
      id
      name
      primaryColor
    }
    collections {
      id
      slug
      title
    }
    speakers {
      id
      firstName
      lastName
      image {
        url
        blurhash
      }
    }
    tags {
      id
      name
    }
    createdAt
    video: videoAsset {
      id
      url
      information {
        durationInSeconds
      }
    }
    videoThumbnail {
      url
      blurhash
    }
  }

  fragment PodcastFragment on Podcast {
    id
    code
    contentRating
    createdAt
    description
    speakers {
      id
      firstName
      lastName
      image {
        url
        blurhash
      }
    }
    image {
      url
      blurhash
    }
    imageThumbnail {
      url
      blurhash
    }
    metaHeader
    metaFooter
    permissions
    slug
    tags {
      name
      id
    }
    title
    topics {
      key
      name
      id
      primaryColor
    }
    audio {
      id
      url
      serviceImageThumbnail
      information {
        durationInSeconds
      }
    }
  }

  fragment CollectionFragment on Collection {
    id
    title
    itemsCount
    thumbnail {
      url
      blurhash
    }
  }

  fragment VideoContinueWatchingCarouselFragment on VideoContinueWatchingCarouselData {
    settings {
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoWatchLaterCarouselFragment on VideoWatchLaterCarouselData {
    settings {
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoCarouselFragment on VideoCarouselData {
    settings {
      viewAll
      videoProgressBar
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoListFragment on VideoListData {
    settings {
      videoProgressBar
      separator
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoGridFragment on VideoGridData {
    settings {
      videoProgressBar
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment FeaturedVideoSliderFragment on FeaturedVideoSliderData {
    settings {
      carouselIndicator
      videoCTA
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment FeaturedVideoFragment on FeaturedVideoData {
    settings {
      videoCTA
    }
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment VideoPlayerFragment on VideoPlayerData {
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment VideoPlayerV2Fragment on VideoPlayerDataV2 {
    config {
      video {
        ...VideoFragment
        imageThumbnail {
          url
          blurhash
        }
      }
    }
  }

  fragment PodcastListenLaterCarouselFragment on PodcastListenLaterCarouselData {
    settings {
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastContinueListeningCarouselFragment on PodcastContinueListeningCarouselData {
    settings {
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastCarouselFragment on PodcastCarouselData {
    settings {
      viewAll
      podcastProgressBar
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastListFragment on PodcastListData {
    settings {
      podcastProgressBar
      separator
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastGridFragment on PodcastGridData {
    settings {
      podcastProgressBar
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment FeaturedPodcastSliderFragment on FeaturedPodcastSliderData {
    settings {
      carouselIndicator
      podcastCTA
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment FeaturedPodcastFragment on FeaturedPodcastData {
    settings {
      podcastCTA
    }
    config {
      podcast {
        ...PodcastFragment
      }
    }
  }

  fragment PodcastPlayerFragment on PodcastPlayerData {
    config {
      podcast {
        ...PodcastFragment
      }
    }
  }

  fragment TopicCarouselFragment on TopicCarouselData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment TopicListFragment on TopicListData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment TopicGridFragment on TopicGridData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment FeaturedTopicFragment on FeaturedTopicData {
    settings {
      topicTitle
    }
    featuredTopicConfig: config {
      autoGenerateSortBy
      autoGenerate
      topic {
        ...TopicFragment
      }
    }
  }

  fragment CollectionCarouselFragment on CollectionCarouselData {
    settings {
      viewAll
      numberOfItems
    }
    config {
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionGridFragment on CollectionGridData {
    settings {
      numberOfItems
      viewAll
    }
    config {
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionListFragment on CollectionListData {
    settings {
      separator
      numberOfItems
      viewAll
    }
    config {
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment FeaturedCollectionSliderFragment on FeaturedCollectionSliderData {
    config {
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment FeaturedCollectionFragment on FeaturedCollectionData {
    settings {
      numberOfItems
    }
    config {
      collection {
        ...CollectionFragment
      }
    }
  }
  fragment CollectionDetailListFragment on CollectionDetailListData {
    config {
      collection {
        ...CollectionFragment
      }
      subCollection {
        id
        title
      }
    }
  }
  fragment CollectionDetailGridFragment on CollectionDetailGridData {
    config {
      collection {
        ...CollectionFragment
      }
      subCollection {
        id
        title
      }
    }
  }
  fragment ContributorCarouselFragment on ContributorCarouselData {
    settings {
      viewAll
    }
    config {
      contributors {
        ...ContributorFragment
        order
      }
    }
  }

  fragment ContributorGridFragment on ContributorGridData {
    settings {
      viewAll
    }
    config {
      contributors {
        ...ContributorFragment
        order
      }
    }
  }

  fragment ContributorListFragment on ContributorListData {
    settings {
      separator
      viewAll
    }
    config {
      contributors {
        ...ContributorFragment
        order
      }
    }
  }

  fragment FeaturedContributorFragment on FeaturedContributorData {
    config {
      contributor {
        ...ContributorFragment
      }
    }
  }

  fragment TagCarouselFragment on TagCarouselData {
    settings {
      viewAll
    }
    config {
      tags {
        ...TagFragment
      }
    }
  }

  fragment TagCloudFragment on TagCloudData {
    settings {
      viewAll
    }
    config {
      tags {
        ...TagFragment
      }
    }
  }

  fragment FeaturedTagFragment on FeaturedTagData {
    settings {
      tagTitle
    }
    config {
      autoGenerateSortBy
      autoGenerate
      tag {
        ...TagFragment
      }
    }
  }

  fragment CTABlockFragment on CTABlockData {
    config {
      asset {
        ...AssetFragment
      }
      primaryAction {
        asset {
          ...AssetFragment
        }
        title
        url
        type
        internalPageType
      }
      secondaryAction {
        asset {
          ...AssetFragment
        }
        title
        url
        type
        internalPageType
      }
    }
  }
  fragment CTABannerWithTextFragment on CTABannerWithTextData {
    config {
      asset {
        ...AssetFragment
      }
      primaryAction {
        title
        url
        type
        internalPageType
      }
    }
  }
  fragment HeroFragment on HeroData {
    config {
      asset {
        ...AssetFragment
      }
      eventTime
      eventDate
      eventLocation
      layout
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      alignment
    }
  }

  fragment HTMLTextBlockFragment on HTMLTextBlockData {
    config {
      content
    }
    settings {
      description
      title
    }
  }
  fragment AdFragment on ADData {
    config {
      adBannerSize
      adType
    }
  }
  fragment DonationFragment on DonationData {
    config {
      secondaryTitle
      secondaryDescription
    }
  }
  fragment CopyHeavyFragment on CopyHeavyData {
    config {
      date
      text
      alignment
      contributor {
        id
        firstName
        lastName
        subTitle
        image {
          url
          blurhash
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }

  fragment CopyHeavyHeroFragment on CopyHeavyHeroData {
    config {
      asset {
        ...AssetFragment
      }
      date
      location
      time
      text
      alignment
      contributor {
        id
        firstName
        lastName
        subTitle
        image {
          url
          blurhash
        }
        primaryColor
      }
      primaryAction {
        title
        type
        url
        internalPageType
      }
      secondaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }

  fragment QuoteFragment on QuoteData {
    settings {
      indicators
    }
    config {
      quotes {
        text
        author {
          name
          subTitle
          asset {
            ...AssetFragment
          }
        }
      }
    }
  }

  fragment informationFragment on InformationData {
    config {
      asset {
        url
        blurhash
      }
      imageOrientation
      imageType
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
    }
  }

  fragment contactUsFragment on ContactUsData {
    config {
      key
    }
  }

  fragment faqsFragment on FAQData {
    config {
      questions {
        question
        answer
      }
    }
  }

  fragment spaceFragment on SpacerData {
    config {
      desktop
      mobile
      tablet
      tv
    }
  }
  fragment bannerFragment on BannerData {
    settings {
      image
    }
    config {
      asset {
        id
        url
      }
    }
  }
  fragment CTAGridFragment on CTAGridData {
    settings {
      viewAll
    }
    config {
      items {
        url
        type
        title
        internalPageType
        description
        asset {
          ...AssetFragment
        }
      }
      ctaGridType: type
    }
  }
  fragment CTAListFragment on CTAListData {
    settings {
      date
      description
      location
      primaryAction
      secondaryAction
      time
    }
    config {
      items {
        date
        description
        location
        time
        title
        asset {
          id
          url
        }
        primaryAction {
          title
          type
          url
          internalPageType
          asset {
            id
            url
          }
        }
        secondaryAction {
          title
          type
          url
          internalPageType
          asset {
            id
            url
          }
        }
      }
    }
  }
  fragment CTACarouselFragment on CTACarouselData {
    settings {
      viewAll
    }
    config {
      items {
        url
        type
        title
        internalPageType
        description
        asset {
          ...AssetFragment
        }
      }
      ctaCarouselType: type
    }
  }

  fragment CTAButtonListFragment on CTAButtonListData {
    settings {
      bottomSeparator
    }
    config {
      items {
        url
        type
        title
        internalPageType
        buttonType
        asset {
          ...AssetFragment
        }
      }
    }
  }

  fragment articleListFragment on ArticleListData {
    config {
      articles {
        id
        title
        description
        publishedAt
        createdAt
        authors {
          firstName
          lastName
          thumbnail {
            url
            blurhash
          }
          id
          slug
        }
        tags {
          id
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        slug
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
    settings {
      articleShare
      articleSave
    }
  }

  fragment articleListV2Fragment on ArticleListDataV2 {
    config {
      articles {
        id
        title
        description
        publishedAt
        createdAt
        authors {
          firstName
          lastName
          thumbnail {
            url
            blurhash
          }
          id
          slug
        }
        tags {
          id
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        slug
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
    settings {
      articleShare
      articleSave
    }
  }

  fragment featuredArticleFragment on FeaturedArticleData {
    settings {
      articleHtml
      articleShare
    }
    config {
      article {
        publishedAt
        html
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          name
          key
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        title
        description
        slug
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment featuredArticleV2Fragment on FeaturedArticleDataV2 {
    config {
      article {
        html
        title
        id
      }
    }
  }

  fragment articleCarouselFragment on ArticleCarouselData {
    settings {
      articleTimestamp
      articleShare
      articleSave
    }
    config {
      articles {
        publishedAt
        slug
        id
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          key
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        description
        title
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment articleCarouselV2Fragment on ArticleCarouselDataV2 {
    settings {
      articleTimestamp
      articleShare
      articleSave
    }
    config {
      articles {
        publishedAt
        slug
        id
        html
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          key
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        description
        title
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment articleGridFragment on ArticleGridData {
    settings {
      articleKebabMenu
      articleTopic
      description
      sourceLogo
      title
      viewAll
      viewSearch
    }
    config {
      articles {
        publishedAt
        slug
        id
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          key
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        description
        title
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment featuredArticleSliderFragment on FeaturedArticleSliderData {
    settings {
      articleDescription
      articleTitle
      articleTopic
      sourceLogo
    }
    config {
      article {
        publishedAt
        slug
        id
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          key
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        description
        title
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment articleDisplayFragment on ArticleDisplayData {
    settings {
      articleContributor
      articleDate
      articleTopic
    }
    config {
      article {
        publishedAt
        slug
        id
        authors {
          firstName
          lastName
          subTitle
          thumbnail {
            url
            blurhash
          }
          image {
            blurhash
            url
          }
          id
          slug
        }
        tags {
          id
          key
          name
          slug
        }
        topics {
          id
          name
          slug
          primaryColor
        }
        description
        title
        imageThumbnail {
          id
          url
          blurhash
        }
      }
    }
  }

  fragment formDisplayFragment on FormDisplayData {
    config {
      autoFillForm
      form {
        id
        title
        submitCount
        description
        slug
        ctas {
          icon {
            url
            id
          }
          actionUrl
          action
          label
          type
        }
        type
        fields {
          key
          label
          order
          type
          required
          options {
            key
            label
            order
          }
        }
      }
    }
  }

  fragment formCarouselFragment on FormCarouselData {
    config {
      formLimit
      type
      sortBy
      forms {
        id
        title
        description
        slug
        submitCount
        type
        ctas {
          label
          type
          actionUrl
          action
          icon {
            id
            url
          }
        }
      }
    }
  }

  fragment sourceCarouselFragment on SourceCarouselData {
    settings {
      description
      title
    }
    config {
      autoGenerate
      sourceAutoGenerateBy: autoGenerateSortBy
      autoGenerateLimit
      sources {
        id
        order
        image {
          id
          url
        }
      }
    }
  }

  fragment sourceListFragment on SourceListData {
    settings {
      description
      title
    }
    config {
      autoGenerate
      sourceAutoGenerateBy: autoGenerateSortBy
      autoGenerateLimit
      sources {
        id
        order
        image {
          id
          url
        }
      }
    }
  }

  fragment sourceGridFragment on SourceGridData {
    settings {
      description
      title
    }
    config {
      autoGenerate
      sourceAutoGenerateBy: autoGenerateSortBy
      autoGenerateLimit
      sources {
        id
        order
        image {
          id
          url
        }
      }
    }
  }

  fragment featuredSourceFragment on FeaturedSourceData {
    settings {
      description
      numberOfArticles
      numberOfPodcasts
      numberOfVideos
      sourceImage
    }
    config {
      autoGenerate
      sourceAutoGenerateBy: autoGenerateSortBy
      autoGenerateLimit
      source {
        id
        order
        image {
          id
          url
        }
      }
    }
  }

  query pageAdmin($id: ID!) {
    pageAdmin(where: { id: $id }, data: { applySettings: true }) {
      slug
      type
      modules {
        id
        type
        order
        title
        description
        moduleData {
          ... on VideoContinueWatchingCarouselData {
            ...VideoContinueWatchingCarouselFragment
          }
          ... on VideoWatchLaterCarouselData {
            ...VideoWatchLaterCarouselFragment
          }
          ... on VideoCarouselData {
            ...VideoCarouselFragment
          }
          ... on VideoListData {
            ...VideoListFragment
          }
          ... on VideoGridData {
            ...VideoGridFragment
          }
          ... on FeaturedVideoData {
            ...FeaturedVideoFragment
          }
          ... on VideoPlayerData {
            ...VideoPlayerFragment
          }
          ... on VideoPlayerDataV2 {
            ...VideoPlayerV2Fragment
          }
          ... on FeaturedVideoSliderData {
            ...FeaturedVideoSliderFragment
          }
          ... on PodcastListenLaterCarouselData {
            ...PodcastListenLaterCarouselFragment
          }
          ... on PodcastContinueListeningCarouselData {
            ...PodcastContinueListeningCarouselFragment
          }
          ... on PodcastCarouselData {
            ...PodcastCarouselFragment
          }
          ... on PodcastListData {
            ...PodcastListFragment
          }
          ... on PodcastGridData {
            ...PodcastGridFragment
          }
          ... on FeaturedPodcastData {
            ...FeaturedPodcastFragment
          }
          ... on FeaturedPodcastSliderData {
            ...FeaturedPodcastSliderFragment
          }
          ... on PodcastPlayerData {
            ...PodcastPlayerFragment
          }
          ... on CollectionGridData {
            ...CollectionGridFragment
          }
          ... on CollectionCarouselData {
            ...CollectionCarouselFragment
          }
          ... on CollectionListData {
            ...CollectionListFragment
          }
          ... on FeaturedCollectionData {
            ...FeaturedCollectionFragment
          }
          ... on CollectionDetailListData {
            ...CollectionDetailListFragment
          }
          ... on CollectionDetailGridData {
            ...CollectionDetailGridFragment
          }
          ... on FeaturedCollectionSliderData {
            ...FeaturedCollectionSliderFragment
          }
          ... on ContributorListData {
            ...ContributorListFragment
          }
          ... on ContributorGridData {
            ...ContributorGridFragment
          }
          ... on ContributorCarouselData {
            ...ContributorCarouselFragment
          }
          ... on FeaturedContributorData {
            ...FeaturedContributorFragment
          }
          ... on TopicListData {
            ...TopicListFragment
          }
          ... on TopicGridData {
            ...TopicGridFragment
          }
          ... on TopicCarouselData {
            ...TopicCarouselFragment
          }
          ... on FeaturedTopicData {
            ...FeaturedTopicFragment
          }
          ... on TagCloudData {
            ...TagCloudFragment
          }
          ... on TagCarouselData {
            ...TagCarouselFragment
          }
          ... on FeaturedTagData {
            ...FeaturedTagFragment
          }
          ... on CTABlockData {
            ...CTABlockFragment
          }
          ... on CTABannerWithTextData {
            ...CTABannerWithTextFragment
          }
          ... on HeroData {
            ...HeroFragment
          }
          ... on HTMLTextBlockData {
            ...HTMLTextBlockFragment
          }
          ... on ADData {
            ...AdFragment
          }
          ... on DonationData {
            ...DonationFragment
          }
          ... on CopyHeavyData {
            ...CopyHeavyFragment
          }
          ... on CopyHeavyHeroData {
            ...CopyHeavyHeroFragment
          }
          ... on QuoteData {
            ...QuoteFragment
          }
          ... on InformationData {
            ...informationFragment
          }
          ... on ContactUsData {
            ...contactUsFragment
          }
          ... on FAQData {
            ...faqsFragment
          }
          ... on SpacerData {
            ...spaceFragment
          }
          ... on BannerData {
            ...bannerFragment
          }
          ... on CTAGridData {
            ...CTAGridFragment
          }
          ... on CTAListData {
            ...CTAListFragment
          }
          ... on CTACarouselData {
            ...CTACarouselFragment
          }
          ... on CTAButtonListData {
            ...CTAButtonListFragment
          }
          ... on ArticleCarouselData {
            ...articleCarouselFragment
          }
          ... on ArticleCarouselDataV2 {
            ...articleCarouselV2Fragment
          }
          ... on ArticleGridData {
            ...articleGridFragment
          }
          ... on FeaturedArticleSliderData {
            ...featuredArticleSliderFragment
          }
          ... on ArticleDisplayData {
            ...articleDisplayFragment
          }
          ... on ArticleListData {
            ...articleListFragment
          }
          ... on ArticleListDataV2 {
            ...articleListV2Fragment
          }
          ... on FeaturedArticleData {
            ...featuredArticleFragment
          }
          ... on FeaturedArticleDataV2 {
            ...featuredArticleV2Fragment
          }
          ... on FormDisplayData {
            ...formDisplayFragment
          }
          ... on FormCarouselData {
            ...formCarouselFragment
          }
          ... on SourceCarouselData {
            ...sourceCarouselFragment
          }
          ... on SourceListData {
            ...sourceListFragment
          }
          ... on SourceGridData {
            ...sourceGridFragment
          }
          ... on FeaturedSourceData {
            ...featuredSourceFragment
          }
        }
      }
    }
  }
`;

export const GET_WORKSPACE_APP = gql`
  query workspaceApp($where: WorkspaceAppUniqueInput!) {
    workspaceApp(where: $where) {
      id
      url
      typeKey
    }
  }
`;

export const PAGE_VERSIONS = gql`
  query PageVersionsAdmin($where: PageUniqueInput!) {
    pageVersionsAdmin(where: $where) {
      count
      pages {
        id
        status
        updatedAt
      }
    }
  }
`;
