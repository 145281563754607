import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';
import { isArray, isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  allowedVideo,
  DOMAIN_STATUS,
  MAX_LENGTHS,
  PROVIDER_TYPES,
  ROUTES,
  VIDEO_UPLOAD_PLATFORMS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { formValidatorRules, uploadToPlatform } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { Switch } from '../../pages/component/pageModules/moduleForms/FormInputs';
import {
  Select,
  SelectPlatForm,
  SelectProviderVideo
} from '../../videos/components/FormInputs';
import {
  CREATE_VIDEO_ASSET,
  IMPORT_PLATFORM_VIDEO_TO_WORKSPACE_ASSET,
  UPDATE_ASSET_STATUS,
  UPDATE_VIDEO_ASSET,
  UPDATE_VIDEO_ASSET_STATUS
} from '../graphql/Mutations';
import { GET_ASSET, GET_LANGUAGES } from '../graphql/Queries';

const { TextArea } = Input;

const languageVariableSelctor = ({ skip, limit, search }) => ({
  filter: {
    skip,
    limit,
    search
  }
});

export const SelectLanguage = ({ ...rest }) => {
  return (
    <Select
      popupMatchSelectWidth={false}
      variablesSelector={languageVariableSelctor}
      placeholder="Select language"
      query={GET_LANGUAGES}
      dataSelector={(data) =>
        data?.languages?.languages?.map(({ code, name }) => ({
          label: name,
          value: code
        })) ?? []
      }
      keys={{
        data: 'languages',
        records: 'languages',
        count: 'count'
      }}
      queryOptions={{
        fetchPolicy: 'cache-first'
      }}
      {...rest}
    />
  );
};

const CommonVideoForm = ({ isModal, setShowModal, setNewAsset }) => {
  const location = useLocation();
  const { videoId, isUpdate } = location?.state || {};
  const [form] = Form.useForm();
  const videoUrl = Form.useWatch('videoUrl', form);
  const providerVideo = Form.useWatch('providerVideo', form);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [videoProgress, setVideoProgress] = useState(undefined);
  const [videoData, setVideoData] = useState({});
  const [videoLoading, setVideoLoading] = useState(true);

  const isLive = Form.useWatch('isLive', form);

  const [fetchVideo] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: videoId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setVideoLoading(true);
      setVideoData(res?.asset);
      setVideoLoading(false);
      form?.setFieldValue('languageCode', res?.asset?.languageCode);
    },
    onError: () => {
      setVideoLoading(false);
    }
  });

  useEffect(() => {
    if (videoId) {
      fetchVideo();
    } else {
      setVideoLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  const handleCancel = () => {
    if (isModal) {
      setShowModal(false);
      setNewAsset(null);
    } else {
      history?.replace(ROUTES?.ASSETS_VIDEOS);
    }
  };

  const [createVideoAssetMutate] = useMutation(CREATE_VIDEO_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [createPlatformVideoAssetMutate] = useMutation(
    IMPORT_PLATFORM_VIDEO_TO_WORKSPACE_ASSET,
    {
      onError() {
        setButtonLoading(false);
      }
    }
  );
  const [updateVideoAssetMutate] = useMutation(UPDATE_VIDEO_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateVideoAssetStatus] = useMutation(UPDATE_VIDEO_ASSET_STATUS);

  const [updateAssetStatus] = useMutation(UPDATE_ASSET_STATUS, {
    onError: () => {}
  });

  const onFinish = async (values) => {
    if (isFunction(setNewAsset)) {
      setNewAsset(null);
    }
    setButtonLoading(true);
    const { video, platform } = values;
    // CMP-1608 : according to this task we have changed the flow. first we create asset then upload the respective file to the platform.
    try {
      const textData = {
        title: values?.title,
        description: values?.description,
        languageCode: values?.languageCode?.value
      };
      const videofile = video?.[0]?.originFileObj;

      if (isUpdate) {
        updateVideoAssetMutate({
          variables: {
            where: { id: videoId },
            data: {
              ...textData,
              ...(values?.isLive && { url: values?.liveUrl })
            }
          }
        })
          .then(() => {
            history?.replace(ROUTES?.ASSETS_VIDEOS);
            setButtonLoading(false);
          })
          .catch(() => {
            setVideoProgress(undefined);
            setButtonLoading(false);
          });
      } else {
        const platformId = values?.providerVideo?.platformId;
        if (platformId) {
          const response = await createPlatformVideoAssetMutate({
            variables: {
              where: {
                platformId,
                videoId: values?.providerVideo?.id
              },
              data: {
                ...textData
              }
            }
          });
          if (response?.data?.importPlatformVideoToWorkspaceAsset) {
            setVideoProgress(undefined);
            setButtonLoading(false);
            if (isModal) {
              setShowModal(false);
              setNewAsset({ asset: false, data: null });
            } else {
              history?.replace(ROUTES?.ASSETS_VIDEOS);
            }
            return;
          }
        }
        const importUrl = values?.videoUrl;

        const response = await createVideoAssetMutate({
          variables: {
            data: {
              ...textData,
              isLive: Boolean(values?.isLive),
              url: values?.liveUrl ?? '',
              importUrl,
              ...(!importUrl &&
                !values?.isLive &&
                videofile && {
                  contentLength: videofile.size,
                  fileName: `${videofile.name.replace(/\s/g, '_')}`
                }),
              platformId: platform?.value ?? ''
            }
          }
        });
        if (importUrl) {
          setVideoProgress(undefined);
          setButtonLoading(false);
          if (isModal) {
            const asset = response?.data?.createVideoAsset?.asset;
            setShowModal(false);
            setNewAsset({ asset: true, data: asset });
          } else {
            history?.replace(ROUTES?.ASSETS_VIDEOS);
          }
          return;
        }
        setVideoProgress(0);
        if (values?.isLive && response?.data?.createVideoAsset) {
          if (isModal) {
            const asset = response?.data?.createVideoAsset?.asset;
            setShowModal(false);
            setNewAsset({ asset: true, data: asset });
          } else {
            history?.replace(ROUTES?.ASSETS_VIDEOS);
          }
        } else if (response?.data?.createVideoAsset?.signedUrl && videofile) {
          const res = await uploadToPlatform(
            response?.data?.createVideoAsset?.signedUrl,
            videofile,
            setVideoProgress,
            platform?.provider?.key
          ).catch(async (err) => {
            await updateAssetStatus({
              variables: {
                where: {
                  id: response?.data?.createVideoAsset?.asset?.id
                },
                data: {
                  errorObj: JSON.parse(err),
                  status: DOMAIN_STATUS.ERRORED
                }
              }
            });
            setVideoProgress(undefined);
            setButtonLoading(false);
            history?.replace(ROUTES?.ASSETS_VIDEOS);
          });

          if (res && response?.data?.createVideoAsset?.asset?.id) {
            if (platform?.provider?.key === VIDEO_UPLOAD_PLATFORMS.PEERTUBE) {
              const data = JSON.parse(res);
              await updateVideoAssetStatus({
                variables: {
                  peertubeUUID: data?.video?.uuid,
                  id: response?.data?.createVideoAsset?.asset?.id,
                  platformId: platform?.value
                }
              });
            }
            if (isModal) {
              const asset = response?.data?.createVideoAsset?.asset;
              setShowModal(false);
              setNewAsset({ asset: true, data: asset });
            } else {
              history?.replace(ROUTES?.ASSETS_VIDEOS);
            }
            setVideoProgress(undefined);
            setButtonLoading(false);
          } else {
            if (isModal) {
              const asset = response?.data?.createVideoAsset?.asset;
              setShowModal(false);
              setNewAsset({ asset: true, data: asset });
            } else {
              history?.replace(ROUTES?.ASSETS_VIDEOS);
            }
            setVideoProgress(undefined);
            setButtonLoading(false);
          }
        }
      }
    } catch (error) {
      setVideoProgress(undefined);
      setButtonLoading(false);
      return error;
    }
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const initialValues = {
    languageCode: {
      label: 'English',
      value: 'en'
    },
    providerVideo: {
      id: '',
      title: '',
      description: '',
      imageURL: '',
      platformId: ''
    },
    videoUrl: '',
    isLive: false,
    liveUrl: videoData?.isLive ? videoData?.url : '',
    ...videoData
  };

  if (videoLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Form
        form={form}
        initialValues={initialValues}
        name="create-asset"
        layout="vertical"
        className="add-edit-form"
        onFinish={onFinish}
        disabled={isViewOnly}
      >
        <Form.Item
          name="title"
          label="Title"
          required
          rules={[
            formValidatorRules?.required('Please enter title!'),
            formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
          ]}
        >
          <Input
            placeholder="Enter title"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
        >
          <TextArea
            rows={2}
            placeholder="Enter description"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>
        <Form.Item name="languageCode" label="Language Code">
          <SelectLanguage />
        </Form.Item>

        <Form.Item name="isLive" valuePropName="checked">
          <Switch
            disabled={videoData?.isLive && isUpdate}
            label="Is This A Livestream Video Asset?"
          />
        </Form.Item>
        {isLive ? (
          <Form.Item
            name="liveUrl"
            label={`Live Stream HLS URL "M3U8"`}
            required
            rules={[
              formValidatorRules?.required('Please enter URL!'),
              {
                type: 'url',
                message: 'Please enter valid URL!'
              }
            ]}
          >
            <Input type="url" placeholder="Enter url" />
          </Form.Item>
        ) : (
          <>
            {!isUpdate && (
              <>
                <Form.Item
                  name="platform"
                  label="Service Provider"
                  rules={[
                    {
                      required: !providerVideo?.id,
                      message: 'Service provider is required'
                    }
                  ]}
                >
                  <SelectPlatForm type={PROVIDER_TYPES.VIDEO} fullWidth />
                </Form.Item>
                <Form.Item
                  name="video"
                  label="Upload Video"
                  required={!videoUrl && !providerVideo?.id}
                  rules={[
                    {
                      async validator(_, value) {
                        const { name } = value?.[0]?.originFileObj || {};
                        const ext = name?.substring(name?.lastIndexOf('.') + 1);
                        if (!value?.length && !videoUrl && !providerVideo?.id) {
                          throw new Error('Please select video');
                        }

                        if (!allowedVideo?.includes(ext) && value?.length) {
                          throw new Error('Please select a valid file');
                        }
                      }
                    }
                  ]}
                  getValueFromEvent={(e) => {
                    if (isArray(e)) {
                      return e;
                    }
                    return e?.fileList;
                  }}
                  valuePropName="fileList"
                >
                  <Upload
                    maxCount={1}
                    disabled={isViewOnly || buttonLoading}
                    accept=".mp4"
                    beforeUpload={() => false}
                    onChange={() =>
                      form.resetFields(['providerVideo', 'videoUrl'])
                    }
                  >
                    <Space>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                      <span>(.mp4)</span>
                    </Space>
                  </Upload>
                </Form.Item>
                <div className="input-divider">Or</div>
                <Form.Item name="videoUrl" label="Import Video">
                  <Input
                    placeholder="Enter url"
                    disabled={isViewOnly || buttonLoading}
                    onChange={() =>
                      form.resetFields(['providerVideo', 'video'])
                    }
                  />
                </Form.Item>
                <div className="input-divider">Or</div>
                <Form.Item
                  name="providerVideo"
                  label="Select Video From Service Provider"
                >
                  <SelectProviderVideo
                    onChange={({ title, description }) => {
                      form.setFieldValue('title', title);
                      form.setFieldValue('description', description);
                      form.resetFields(['videoUrl', 'video']);
                    }}
                  />
                </Form.Item>
              </>
            )}
            {videoProgress >= 0 && (
              <Form.Item>
                <ProgressBar progress={videoProgress} />
              </Form.Item>
            )}
          </>
        )}

        <div className="d-flex button-section">
          <Space>
            {isAddEditAllowed && (
              <Form.Item>
                <Button
                  loading={buttonLoading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>
              </Form.Item>
            )}
            <Form.Item>
              <Button
                type="text"
                className="text-btn2"
                disabled={buttonLoading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </>
  );
};

export default CommonVideoForm;
