import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Select as AntdSelect, Button, Form, Input, Space, Table } from 'antd';
import { forEach, filter as lodashFilter } from 'lodash';
import { ArrowsOutCardinal } from 'phosphor-react';
import React, { forwardRef } from 'react';
import * as urlSlug from 'url-slug';
import {
  COLLECTION_ITEM_TYPES,
  MAX_LENGTHS,
  PAGE_TYPES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  UNPUBLISHED_STATUS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../../common/constants';
import { formValidatorRules } from '../../../../common/utils';
import { messageContext } from '../../../../components/AppComponentContainer';
import useCheckPermission from '../../../../hooks/useCheckPermission';
import { GET_FORMS } from '../../../form/graphql/Queries';
import { Select } from '../../../videos/components/FormInputs';
import { SlugInput } from '../../topics/components/FormInputs';
import { SelectArticle, SelectPodcasts, SelectVideos } from './FormInputs';

const TableRow = forwardRef(({ children, ...rest }, ref) => (
  <tr ref={ref} {...rest}>
    {children}
  </tr>
));

const DEFAULT_ITEM_VALUE = {
  type: 'VIDEO',
  sequence: null,
  itemId: null
};

const Draggable = ({ id, children, className, style: defaultStyles }) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging
  } = useSortable({
    id,
    strategy: verticalListSortingStrategy
  });
  const [firstChild, ...restChildren] = children;

  const style = transform
    ? {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS?.Translate?.toString(transform),
        transition
      }
    : undefined;

  const handlers = {
    ...attributes,
    ...listeners
  };

  return (
    <TableRow
      className={className}
      style={{ ...style, ...defaultStyles }}
      ref={setNodeRef}
    >
      {React.cloneElement(firstChild, {
        render: firstChild?.props?.render?.bind(null, handlers)
      })}
      {restChildren}
    </TableRow>
  );
};
const DraggableRow = ({ className, children, style, ...rest }) => {
  const id = rest['data-row-key'] + 1;
  if (!id)
    return (
      <TableRow className={className} style={style}>
        {children}
      </TableRow>
    );
  return (
    <Draggable id={id} className={className} style={style}>
      {children}
    </Draggable>
  );
};

const CollectionItems = ({
  form,
  items,
  loading = false,
  itemsLoading = false,
  field,
  updateCollectionLoading,
  parentCollectionSlug
}) => {
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const getSubCollectionItems = form?.getFieldValue(['subCollections']);
  const subCollectionItems = Form.useWatch(['subCollections'], form);
  const subCollectionId = !!getSubCollectionItems?.[field?.name]?.id;

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isContentEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleTitleChange = (e) => {
    if (subCollectionId) {
      form?.setFieldValue(
        ['subCollections', field?.name, 'slug'],
        `/${urlSlug?.convert(e?.target?.value)}`
      );
    } else {
      form?.setFieldValue(
        ['subCollections', field?.name, 'slug'],
        `${parentCollectionSlug}-${urlSlug?.convert(e?.target?.value)}`
      );
    }
  };

  const getItems = (itemType) => {
    switch (itemType) {
      case PAGE_TYPES.PODCAST:
        return (
          <SelectPodcasts
            IsTableView
            disabled={isViewOnly}
            multiple={false}
            isContentEditAllowed={isContentEditAllowed}
          />
        );
      case PAGE_TYPES.ARTICLE:
        return (
          <SelectArticle
            IsTableView
            disabled={isViewOnly}
            multiple={false}
            isContentEditAllowed={isContentEditAllowed}
          />
        );
      case PAGE_TYPES.FORM:
        return (
          <Select
            placeholder="Select form"
            query={GET_FORMS}
            disabled={isViewOnly || !isContentEditAllowed}
            variablesSelector={(filter) => ({
              filter: { ...filter, status: STATUS_TYPES.PUBLISHED }
            })}
            dataSelector={(data) =>
              data?.formsAdmin?.forms?.map(({ id, title }) => ({
                label: title,
                value: id
              })) ?? 0
            }
            allowClear
            keys={{
              data: 'formsAdmin',
              records: 'forms',
              count: 'count'
            }}
          />
        );
      default:
        return (
          <SelectVideos
            disabled={isViewOnly}
            multiple={false}
            isContentEditAllowed={isContentEditAllowed}
            IsTableView
          />
        );
    }
  };

  const isItemAlreadyPresent = (selectedItem, type) => {
    const filterItems = lodashFilter(items, (item) => item?.type === type);
    const getItem = [];
    forEach(filterItems, (item) => {
      if (
        (item?.itemId?.value && item?.itemId?.value === selectedItem?.value) ||
        (item?.itemId?.id && item?.itemId?.id === selectedItem?.id)
      ) {
        getItem?.push(item);
      }
    });
    if (getItem?.length > 1) {
      return true;
    }
    return false;
  };

  const handleDragEnd = ({ over, active }) => {
    if (!over) return;

    const source = active?.id - 1;
    const destination = over?.id - 1;
    if (source !== destination) {
      const collectionItems = form?.getFieldValue([
        'subCollections',
        field?.name,
        'collectionItems'
      ]);

      const reorderedItems = arrayMove(collectionItems, source, destination);

      form?.setFieldValue(
        ['subCollections', field?.name, 'collectionItems'],
        reorderedItems
      );
    }
  };

  const collectionItemColumns = [
    {
      title: ' ',
      key: 'id',
      width: 30,
      render: (handlers) => {
        return (
          <div className="d-flex align-center justify-center">
            <ArrowsOutCardinal
              {...handlers}
              className="grab drag-icon"
              size={16}
            />
          </div>
        );
      }
    },
    {
      title: 'Type',
      key: 'type',
      width: 350,
      render: (value, row) => {
        return (
          <Form.Item
            {...row}
            name={[row?.name, 'type']}
            key={[row?.key, 'type']}
            className="mb-0"
            rules={[
              {
                required: true,
                message: 'Please select type!'
              }
            ]}
          >
            <AntdSelect
              options={COLLECTION_ITEM_TYPES}
              placeholder="Select Type"
              onChange={() => {
                form?.setFieldValue(
                  [
                    'subCollections',
                    field?.name,
                    'collectionItems',
                    row?.name,
                    'itemId'
                  ],
                  DEFAULT_ITEM_VALUE?.itemId
                );
              }}
            />
          </Form.Item>
        );
      }
    },
    {
      title: 'Item',
      key: 'itemId',
      width: 350,
      render: (value, row, index) => {
        const getType = items?.[index]?.type;
        return (
          <Form.Item
            {...row}
            name={[row?.name, 'itemId']}
            key={[row?.key, 'itemId']}
            className="mb-0"
            rules={[
              {
                required: items?.length > 1,
                message: 'Please select item!'
              },
              () => ({
                validator(_, itemSelected) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  const isPresent = isItemAlreadyPresent(itemSelected, getType);
                  if (isPresent) {
                    return Promise.reject(new Error('Must Not Be Repeated'));
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            {getItems(getType)}
          </Form.Item>
        );
      }
    },
    {
      title: 'Sequence',
      key: 'sequence',
      width: 350,
      render: (_, row) => {
        return (
          <Form.Item
            {...row}
            name={[row?.name, 'sequence']}
            key={[row?.key, 'sequence']}
            className="mb-0"
            rules={[
              {
                validator(__, value) {
                  const filterItems = lodashFilter(
                    items,
                    (item) => item?.sequence
                  );
                  const getItem = [];
                  forEach(filterItems, (item) => {
                    if (item?.sequence === value) {
                      getItem?.push(item);
                    }
                  });
                  if (getItem?.length > 1) {
                    return Promise.reject(
                      new Error('Sequence can not be duplicate')
                    );
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input placeholder="Enter Sequence" />
          </Form.Item>
        );
      }
    }
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    form?.submit();
  };
  return (
    <>
      <div>
        <Space className="d-flex justify-end">
          {isAddEditAllowed && (
            <Button
              disabled={loading || itemsLoading || updateCollectionLoading}
              loading={loading || itemsLoading || updateCollectionLoading}
              type="text"
              htmlType="submit"
              className="text-btn mr-8 mt-8"
              size="middle"
              onClick={handleSubmit}
            >
              {subCollectionId ? 'Save' : 'Add'}
            </Button>
          )}
        </Space>
        <Form.Item
          label="Title"
          name={[field?.name, 'title']}
          required
          rules={[
            formValidatorRules?.required('Please enter title!'),
            formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
          ]}
        >
          <Input placeholder="Enter title" onChange={handleTitleChange} />
        </Form.Item>
        <Form.Item
          name={[field?.name, 'description']}
          label="Description"
          rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
        >
          <Input.TextArea placeholder="Enter description" />
        </Form.Item>
        <Form.Item
          label="Slug"
          name={[field?.name, 'slug']}
          rules={[
            {
              required: true,
              message: 'Please enter slug!'
            },
            formValidatorRules?.maxLength(MAX_LENGTHS.TITLE),
            formValidatorRules?.slug()
          ]}
        >
          <SlugInput />
        </Form.Item>

        <Form.Item label="Status" name={[field?.name, 'status']}>
          <AntdSelect
            options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
              ({ name, value }) => ({
                label: name,
                value
              })
            )}
            placeholder="Select status"
          />
        </Form.Item>
        <Form.List name={[field?.name, 'collectionItems']}>
          {(fields, { add, remove }) => (
            <div className="listview form-table nested-table">
              <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                <SortableContext
                  strategy={verticalListSortingStrategy}
                  items={fields?.map(({ name }) => name + 1)}
                >
                  <Button
                    onClick={() => {
                      const collectionItems = subCollectionItems?.[
                        field?.name
                      ]?.collectionItems?.some(
                        (item) =>
                          item?.itemId?.id === '' || item?.itemId === null
                      );
                      if (collectionItems) {
                        messageContext?.success(
                          'You already have added one item. Please choose asset to add more.'
                        );
                      } else {
                        add(DEFAULT_ITEM_VALUE, 0);
                      }
                    }}
                    icon={<PlusOutlined />}
                    className="mb-20"
                  >
                    Add Item
                  </Button>
                  <Table
                    dataSource={fields?.map((f) => ({
                      ...f
                    }))}
                    rowKey="name"
                    loading={loading || itemsLoading}
                    pagination={false}
                    bordered={false}
                    components={{
                      body: {
                        row: DraggableRow
                      }
                    }}
                    columns={[
                      ...collectionItemColumns,
                      {
                        title: 'Action',
                        render: (value, row) => {
                          return (
                            <Button
                              className="text-btn"
                              type="text"
                              onClick={() => {
                                remove(row?.name);
                              }}
                              icon={<DeleteOutlined />}
                            />
                          );
                        }
                      }
                    ]}
                  />
                </SortableContext>
              </DndContext>
            </div>
          )}
        </Form.List>
      </div>
    </>
  );
};
export default CollectionItems;
