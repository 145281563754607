import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as urlSlug from 'url-slug';
import { useApp } from '../../../AppContext';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import PageHeader from '../../../components/PageHeader';
import history from '../../../historyData';
import useCheckPermission from '../../../hooks/useCheckPermission';
import SelectAsset from '../../assets/components/SelectAsset';
import { Switch } from '../../pages/component/pageModules/moduleForms/FormInputs';
import { DEFAULT_CONFIG_KEYS } from '../../workspaces/components';
import { ColorPicker } from '../topics/components/FormInputs';
import { CREATE_CONTRIBUTOR, UPDATE_CONTRIBUTOR } from './graphql/Mutations';
import { GET_CONTRIBUTOR } from './graphql/Queries';

const COLOR_KEYS = {
  red2: 'red2',
  yellow2: 'yellow2',
  blue2: 'blue2',
  moss2: 'moss2'
};

const initialValues = {
  isActive: true,
  firstName: '',
  lastName: '',
  slug: '',
  description: '',
  subTitle: '',
  image: null,
  thumbnail: null,
  metaHeader: '',
  metaFooter: '',
  primaryColor: COLOR_KEYS.red2
};

const AddEditContributor = (props) => {
  const { state } = useApp();
  const workspaceColors = state?.workspaceConfig?.find(
    ({ key }) => key === DEFAULT_CONFIG_KEYS.COLORS
  )?.value;

  const [form] = Form.useForm();
  const firstName = Form.useWatch('firstName', form) ?? '';
  const lastName = Form.useWatch('lastName', form) ?? '';
  const { location } = props;
  const { id: contributorId } = useParams();
  const { isUpdate } = location?.state || {};

  const contributorBreadcrumbs = [
    { label: MODULES?.LABELS },
    { label: MODULES?.CONTRIBUTORS, route: ROUTES?.LABELS_CONTRIBUTORS },
    contributorId && { label: contributorId },
    { label: contributorId ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const isAssetEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const isAssetViewAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const [buttonLoading, setButtonLoading] = useState(false);

  const colors = useMemo(
    () => [
      {
        key: COLOR_KEYS.red2,
        color: workspaceColors?.[COLOR_KEYS.red2]
      },
      {
        key: COLOR_KEYS.yellow2,
        color: workspaceColors?.[COLOR_KEYS.yellow2]
      },
      {
        key: COLOR_KEYS.blue2,
        color: workspaceColors?.[COLOR_KEYS.blue2]
      },
      {
        key: COLOR_KEYS.moss2,
        color: workspaceColors?.[COLOR_KEYS.moss2]
      }
    ],
    [workspaceColors]
  );

  const [fetchContributor] = useLazyQuery(GET_CONTRIBUTOR, {
    variables: { where: { id: contributorId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      form.setFieldsValue({
        ...res?.contributorAdmin,
        slug: res?.contributorAdmin?.slug?.startsWith('/')
          ? res?.contributorAdmin?.slug
          : `/${res?.contributorAdmin?.slug}`
      });
    }
  });

  useEffect(() => {
    if (contributorId) {
      fetchContributor();
    }
  }, [contributorId]);

  const [createContributorMutate] = useMutation(CREATE_CONTRIBUTOR, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateContributorMutate] = useMutation(UPDATE_CONTRIBUTOR, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    history?.replace(ROUTES?.LABELS_CONTRIBUTORS);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    try {
      const formData = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        description: values?.description,
        subTitle: values?.subTitle,
        slug: values?.slug?.startsWith('/')
          ? values?.slug?.substring(1)
          : values?.slug,
        imageId: values?.image?.id ? values?.image?.id : null,
        thumbnailId: values?.thumbnail?.id ? values?.thumbnail?.id : null,
        primaryColor: values?.primaryColor,
        ...(contributorId && {
          isActive: !!values?.isActive
        }),
        metaHeader: values?.metaHeader || '',
        metaFooter: values?.metaFooter || '',
        displayName: values?.displayName
      };

      if (isUpdate) {
        const response = await updateContributorMutate({
          variables: {
            where: { id: contributorId },
            data: {
              ...formData
            }
          }
        });
        if (response?.data?.updateContributor) {
          setButtonLoading(false);
          history?.replace(ROUTES?.LABELS_CONTRIBUTORS);
        }
      } else {
        const response = await createContributorMutate({
          variables: {
            data: {
              ...formData
            }
          }
        });
        if (response?.data?.createContributor) {
          setButtonLoading(false);
          history?.replace(ROUTES?.LABELS_CONTRIBUTORS);
        }
      }
    } catch (error) {
      setButtonLoading(false);
      return error;
    }
  };

  const handleFirstNameChange = (e) => {
    const value = e?.target?.value;
    const separator = value && lastName ? '-' : '';
    form?.setFieldValue(
      'slug',
      `/${urlSlug?.convert(value)}${separator}${urlSlug?.convert(lastName)}`
    );
  };
  const handleLastNameChange = (e) => {
    const value = e?.target?.value;
    const separator = value && firstName ? '-' : '';
    form?.setFieldValue(
      'slug',
      `/${urlSlug?.convert(firstName)}${separator}${urlSlug?.convert(value)}`
    );
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <>
      <PageHeader menu={contributorBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            initialValues={initialValues}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
            disabled={isViewOnly}
          >
            <Form.Item
              name="firstName"
              label="First Name"
              required
              rules={[
                formValidatorRules?.required('Please enter first name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input
                placeholder="Enter First Name"
                disabled={isViewOnly || buttonLoading}
                onChange={handleFirstNameChange}
              />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last Name"
              required
              rules={[
                formValidatorRules?.required('Please enter last name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input
                placeholder="Enter Last Name"
                onChange={handleLastNameChange}
                disabled={isViewOnly || buttonLoading}
              />
            </Form.Item>

            <Form.Item
              name="displayName"
              label="Display Name"
              required
              rules={[
                formValidatorRules?.required('Please enter display name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input
                placeholder="Enter Display Name"
                disabled={isViewOnly || buttonLoading}
              />
            </Form.Item>

            <Form.Item
              name="slug"
              label="Slug"
              rules={[
                { required: true, message: 'Please enter page slug!' },
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input
                placeholder="Enter Page Slug"
                disabled={isViewOnly || buttonLoading}
              />
            </Form.Item>
            {contributorId && (
              <Form.Item name="isActive" valuePropName="checked">
                <Switch label="Active" />
              </Form.Item>
            )}
            <Form.Item
              name="subTitle"
              label="Subtitle"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)]}
            >
              <Input placeholder="Enter subtitle" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
            <Form.Item name="primaryColor" label="Color">
              <ColorPicker data={colors} />
            </Form.Item>

            <Form.Item label="Main Image" name="image">
              <SelectAsset
                disabled={isViewOnly}
                modalTitle="Select Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
                isAssetEditAllowed={isAssetEditAllowed}
                isAssetViewAllowed={isAssetViewAllowed}
              />
            </Form.Item>

            <Form.Item label="Thumbnail Image" name="thumbnail">
              <SelectAsset
                disabled={isViewOnly}
                modalTitle="Select Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
                isAssetEditAllowed={isAssetEditAllowed}
                isAssetViewAllowed={isAssetViewAllowed}
              />
            </Form.Item>

            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  type="text"
                  className="text-btn2"
                  disabled={buttonLoading}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditContributor;
