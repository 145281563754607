import { gql } from '@apollo/client';

const CORE_TOPIC_FIELDS = gql`
  fragment CoreFields on Topic {
    id
    name
    primaryColor
    image {
      id
      url
      blurhash
    }
  }
`;

export const GET_TOPICS = gql`
  ${CORE_TOPIC_FIELDS}
  query topicsAdmin($filter: TopicsFilter, $sort: TopicsSort) {
    topicsAdmin(filter: $filter, sort: $sort) {
      count
      topics {
        ...CoreFields
      }
    }
  }
`;

export const GET_TOPIC = gql`
  ${CORE_TOPIC_FIELDS}
  query topicAdmin($id: ID!) {
    topicAdmin(where: { id: $id }) {
      ...CoreFields
      slug
      metaHeader
      metaFooter
    }
  }
`;
