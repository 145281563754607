import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { Export } from 'phosphor-react';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { authClient } from '../../apollo';
import {
  DATE_FORMAT,
  LIST_TYPES,
  MODULES,
  ROUTES,
  USERS_SORT_FIELD
} from '../../common/constants';
import Avatar from '../../components/Avatar';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import { FORGOT_PASSWORD } from '../auth/graphql/Mutations';
import { REQUEST_EXPORT_ADMIN_USERS } from './graphql/Mutations';
import { GET_ADMIN_USERS } from './graphql/Queries';

const dataSelector = (data) => ({
  data: data?.adminUsers?.users ?? [],
  count: data?.adminUsers?.count ?? 0
});

const variablesSelector = ({ offset, sortField, sortOrder, ...rest }) => ({
  filter: {
    skip: offset,
    ...rest
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const ResetPassword = ({ email }) => {
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    client: authClient
  });

  const handleClick = () => {
    forgotPassword({
      variables: {
        data: {
          email
        }
      }
    });
  };

  return (
    <Button
      loading={loading}
      type="text"
      className="text-btn"
      onClick={handleClick}
    >
      Send Reset
    </Button>
  );
};

const Action = ({ id }) => {
  const history = useHistory();

  const handleEdit = () => {
    history?.push(`${ROUTES?.ADMINS}/${id}/edit`);
  };
  return (
    <Tooltip title="Edit User">
      <Button type="text" className="text-btn" onClick={handleEdit}>
        <EditOutlined />
      </Button>
    </Tooltip>
  );
};

const columns = [
  {
    title: 'Avatar',
    dataIndex: 'profileImage',
    width: 100,
    render: (_, { firstName, lastName, profileImage }) => (
      <Avatar src={profileImage} firstName={firstName} lastName={lastName} />
    )
  },
  {
    title: 'F name',
    dataIndex: 'firstName',
    ellipsis: true
  },
  {
    title: 'L name',
    dataIndex: 'lastName',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'isActive',
    ellipsis: true,
    render: (value) => (value ? 'Active' : 'Inactive')
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 300,
    render: (value) => <a href={`mailto:${value}`}>{value}</a>
  },
  {
    title: 'Password',
    dataIndex: 'email',
    render: (value) => <ResetPassword email={value} />
  },
  {
    title: 'Joined',
    dataIndex: 'createdAt',
    render: (value) => moment(value)?.format(DATE_FORMAT)
  },
  {
    title: 'Attributes',
    dataIndex: 'permissions',
    render: (value) => value?.join(',')
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (value) => <Action id={value} />
  }
];

const AdminUsers = ({ history }) => {
  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: USERS_SORT_FIELD,
      field: USERS_SORT_FIELD?.[0]?.value
    }
  });

  const [exportUsers, { loading: isExporting }] = useMutation(
    REQUEST_EXPORT_ADMIN_USERS
  );

  const handleShowModal = () => {
    history?.push(`${ROUTES?.ADMINS}/add`);
  };

  const filters = useMemo(() => ({ ...filterProps?.filters }), [
    filterProps?.filters
  ]);

  const handleExport = () => {
    const {
      sortField: _sortField,
      sortOrder: _sortOrder,
      ...payload
    } = filters;

    exportUsers({
      variables: {
        filter: payload
      }
    });
  };
  const adminUserBreadcrumbs = [{ label: MODULES?.ADMINS }];
  return (
    <>
      <PageHeader
        menu={adminUserBreadcrumbs}
        handleShowModal={handleShowModal}
      />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false
        }}
        SearchProps={{ placeholder: 'Search Admin Users' }}
      >
        <Tooltip title="Export Admin Users" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<Export size={24} weight="thin" />}
            onClick={handleExport}
            loading={isExporting}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES?.TABLE}
        query={GET_ADMIN_USERS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        limit={20}
      />
    </>
  );
};

export default AdminUsers;
