import { useMutation } from '@apollo/client';
import { Button, Tag, Tooltip } from 'antd';
import { ArrowClockwise, CaretRight } from 'phosphor-react';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  CLOUD_FRONT_CACHE_KEYS,
  MODULES,
  ORDER,
  ROUTES,
  TAGS_SORT_FIELD,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { INVALIDATE_CLOUD_FRONT_CACHE } from '../../menus/graphql/Mutations';
import { GET_TAGS } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    skip: offset,
    limit,
    search
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});
const dataSelector = ({ tagsAdmin }) => ({
  data: tagsAdmin?.tags ?? [],
  count: tagsAdmin?.count ?? 0
});

const TagCard = ({ name, id }) => {
  return (
    <Link to={`${ROUTES?.LABELS_TAGS}/${id}/edit`}>
      <Tag
        className="text-md tag-s bg-n-400 b-0 text-n-900 mr-4 tag-small"
        size="large"
      >
        {name}
        <CaretRight size={16} />
      </Tag>
    </Link>
  );
};

const Tags = ({ history }) => {
  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.OG_IMAGES }
    }
  );
  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: TAGS_SORT_FIELD,
      order: ORDER[1].value,
      field: TAGS_SORT_FIELD[0].value
    }
  });
  const handleShowModal = () => {
    history.push(`${ROUTES?.LABELS_TAGS}/add`);
  };
  const tagsBreadcrumbs = [
    { label: MODULES?.LABELS },
    { label: MODULES?.TAGS }
  ];
  return (
    <>
      <PageHeader
        menu={tagsBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search by tag name' }}
        show={{ listModes: false }}
      >
        <Tooltip title="Update Share Preview" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={invalidateCache}
            loading={isInvalidating}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        query={GET_TAGS}
        filters={filterProps.filters}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        limit={200}
        keyField="key"
        GridProps={{
          renderItem: ({ name, id }) => (
            <TagCard id={id} name={name} isAddEditAllowed={isAddEditAllowed} />
          ),
          breakPoints: 'auto',
          colSpacing: 4,
          rowSpacing: 8
        }}
      />
    </>
  );
};

export default Tags;
