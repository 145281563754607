import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import PageHeader from '../../../components/PageHeader';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { SlugInput } from '../topics/components/FormInputs';
import { CREATE_TAG, UPDATE_TAG } from './graphql/Mutations';
import { GET_TAG } from './graphql/Queries';

const initialValues = {
  name: '',
  slug: '/',
  metaHeader: '',
  metaFooter: ''
};

const AddEditTags = ({ match: { params }, history }) => {
  const [form] = Form.useForm();
  const { tagId } = params;

  const isEdit = !!tagId;

  const tagsBreadcrumbs = [
    { label: MODULES?.LABELS },
    { label: MODULES?.TAGS, route: ROUTES?.LABELS_TAGS },
    tagId && { label: tagId },
    { label: params?.id ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const [fetchTagDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_TAG,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateTag, { loading }] = useMutation(
    isEdit ? UPDATE_TAG : CREATE_TAG
  );

  useEffect(() => {
    if (isEdit && !!tagId) {
      fetchTagDetails({
        variables: {
          id: tagId
        }
      }).then((res) => {
        const tag = res.data?.tagAdmin;
        if (tag) {
          const { slug, metaHeader, metaFooter, name } = tag;
          form.setFieldsValue({
            name: name ?? '',
            slug: slug.startsWith('/') ? slug : `/${slug}`,
            metaHeader: metaHeader ?? '',
            metaFooter: metaFooter ?? ''
          });
        }
      });
    }
  }, [fetchTagDetails, tagId, form, isEdit]);

  const handleNameChange = (e) => {
    form?.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const handleCancel = () => {
    history.push(ROUTES?.LABELS_TAGS);
  };

  const handleSubmit = ({ name, ...values }) => {
    const slug = values.slug.startsWith('/')
      ? values.slug.substring(1)
      : values.slug;

    addUpdateTag({
      variables: {
        data: {
          ...(!isEdit && { name }),
          ...values,
          slug
        },
        ...(isEdit && {
          where: {
            id: tagId
          }
        })
      }
    })
      .then(() => {
        history.push(ROUTES?.LABELS_TAGS);
      })
      .catch();
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <>
      <PageHeader menu={tagsBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            className="add-edit-form"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
            disabled={isViewOnly || fetchingDetails}
          >
            <Form.Item
              label="Name"
              name="name"
              required
              rules={[
                formValidatorRules?.required('Please enter name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input
                disabled={isEdit}
                readOnly={isEdit}
                placeholder="Enter name"
                onChange={handleNameChange}
              />
            </Form.Item>

            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please enter slug!'
                },
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE),
                formValidatorRules?.slug()
              ]}
            >
              <SlugInput />
            </Form.Item>
            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    loading={fetchingDetails || loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={fetchingDetails || loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditTags;
