import { Breadcrumb } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import './styles/breadCrumb.less';

const BreadCrumb = ({ menu }) => {
  const breadCrumbView = () => {
    const smallCase = (s) =>
      s?.charAt(0)?.toUpperCase() + s?.slice(1)?.toLowerCase();

    const renderItems = () => {
      const items = [];
      if (menu?.length > 0) {
        menu?.map((item, index) => {
          if (item?.route) {
            items?.push({
              title: (
                <Link className="clickable" to={item?.route}>
                  {smallCase(item?.label)}
                </Link>
              ),
              className: 'clickable'
            });
          } else if (index === menu?.length - 1) {
            items?.push({ className: 'active', title: smallCase(item?.label) });
          } else {
            items?.push({
              className: 'clickable',
              title: smallCase(item?.label)
            });
          }
          return item;
        });
      }
      return items;
    };

    return (
      <div>
        <Breadcrumb items={renderItems()} className="breadcrumb-item" />
      </div>
    );
  };

  return <>{breadCrumbView()}</>;
};

export default BreadCrumb;
