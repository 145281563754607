import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';
import heic2any from 'heic2any';
import { isArray, isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  allowedImages,
  ASSET_CATEGORY,
  DOMAIN_STATUS,
  MAX_LENGTHS,
  PROVIDER_TYPES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import {
  Blurhash,
  fileUpload,
  formValidatorRules
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { SelectPlatForm } from '../../videos/components/FormInputs';
import {
  CREATE_ASSET,
  UPDATE_ASSET,
  UPDATE_ASSET_STATUS
} from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';

const { TextArea } = Input;
const CommonImageForm = ({
  isModal,
  setShowModal,
  setNewAsset,
  isAssetEdit
}) => {
  const location = useLocation();
  const { imageId, isUpdate } = location?.state || {};
  const [imageLoading, setImageLoading] = useState(true);
  const [imageProgress, setImageProgress] = useState(undefined);
  const [imageData, setImageData] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleCancel = () => {
    if (isModal) {
      setShowModal(false);
      setNewAsset(null);
    } else {
      history?.replace(ROUTES?.ASSETS_IMAGES);
    }
  };

  const [fetchImage] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: imageId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setImageLoading(true);
      setImageData(res?.asset);
      setImageLoading(false);
    },
    onError: () => {
      setImageLoading(false);
    }
  });

  const [updateAssetStatus] = useMutation(UPDATE_ASSET_STATUS, {
    onError: () => {}
  });

  useEffect(() => {
    if (imageId) {
      fetchImage();
    } else {
      setImageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageId]);

  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL, {
    fetchPolicy: 'network-only'
  });
  const [createAssetMutate] = useMutation(CREATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAssetMutate] = useMutation(UPDATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });

  const initialValues = {
    ...imageData,
    platform: imageData?.platform
      ? {
          label: imageData?.platform?.name,
          value: imageData?.platform?.id
        }
      : null
  };

  const onFinish = async (values) => {
    if (isFunction(setNewAsset)) {
      setNewAsset(null);
    }
    setButtonLoading(true);
    const { image, platform } = values;

    try {
      const textData = {
        title: values?.title,
        description: values?.description
      };
      let key = null;
      let blurhash = null;
      let contentType = null;

      if (image?.length > 0) {
        setImageProgress(0);
        let imagefile = image?.[0]?.originFileObj;
        let fileName = image?.[0]?.name;
        contentType = image?.[0]?.type;
        if (
          imagefile?.type?.toLowerCase() === 'image/heic' ||
          imagefile?.name?.toLowerCase()?.includes('.heic')
        ) {
          const ext = fileName
            ?.toLowerCase()
            ?.split('.')?.[1]
            .replace('heic', 'jpeg');
          const file = fileName?.split('.')?.[0];
          fileName = `${file}.${ext}`;
          imagefile = await heic2any({
            blob: imagefile,
            toType: 'image/jpeg',
            quality: 0.8
          });
        }
        const getSignedPutUrlResult = await getSignedUrl({
          variables: {
            data: {
              fileName: fileName?.replace(/ /g, '_'),
              contentType,
              assetType: ASSET_CATEGORY?.IMAGE,
              platformId: platform?.value
            }
          }
        });
        // CMP-1608 : according to this task we have changed the flow. first we create asset then upload the respective file to the platform.
        const type =
          getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.aclType;

        const media = {
          ...textData,
          categoryKey: ASSET_CATEGORY?.IMAGE,
          contentType
        };
        if (!key) {
          delete media?.key;
        }
        if (!blurhash) {
          delete media?.blurhash;
        }

        if (isUpdate && isAssetEdit) {
          const response = await updateAssetMutate({
            variables: {
              where: { id: imageId },
              data: {
                ...media,
                key: getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key,
                blurhash: await Blurhash.encode(imagefile)
              }
            }
          });
          if (response?.data?.updateAsset) {
            if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
              await fileUpload(
                getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
                imagefile,
                setImageProgress,
                type
              )
                .then(async () => {
                  await updateAssetStatus({
                    variables: {
                      where: {
                        id: imageId
                      },
                      data: {
                        errorObj: {},
                        status: DOMAIN_STATUS.READY
                      }
                    }
                  });
                })
                .catch(async (err) => {
                  await updateAssetStatus({
                    variables: {
                      where: {
                        id: imageId
                      },
                      data: {
                        errorObj: JSON.parse(err),
                        status: DOMAIN_STATUS.ERRORED
                      }
                    }
                  });
                });
              key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
              blurhash = await Blurhash.encode(imagefile);
            }
            setButtonLoading(false);
            history?.replace(ROUTES?.ASSETS_IMAGES);
          }
        } else {
          const response = await createAssetMutate({
            variables: {
              data: {
                ...media,
                key: getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key,
                blurhash: await Blurhash.encode(imagefile),
                platformId: platform?.value
              }
            }
          });
          if (response?.data?.createAsset) {
            if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
              await fileUpload(
                getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
                imagefile,
                setImageProgress,
                type
              )
                .then(async () => {
                  await updateAssetStatus({
                    variables: {
                      where: {
                        id: response?.data?.createAsset?.asset?.id
                      },
                      data: {
                        errorObj: {},
                        status: DOMAIN_STATUS.READY
                      }
                    }
                  });
                })
                .catch(async (err) => {
                  await updateAssetStatus({
                    variables: {
                      where: {
                        id: response?.data?.createAsset?.asset?.id
                      },
                      data: {
                        errorObj: JSON.parse(err),
                        status: DOMAIN_STATUS.ERRORED
                      }
                    }
                  });
                });
              key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
              blurhash = await Blurhash.encode(imagefile);
            }
            if (isModal) {
              const asset = response?.data?.createAsset?.asset;
              setShowModal(false);
              setNewAsset({ asset: true, data: asset });
            } else {
              history?.replace(ROUTES?.ASSETS_IMAGES);
            }
            setImageProgress(undefined);
            setButtonLoading(false);
          }
        }
      } else {
        const response = await updateAssetMutate({
          variables: {
            where: { id: imageId },
            data: {
              ...textData,
              categoryKey: ASSET_CATEGORY?.IMAGE
            }
          }
        });
        if (response?.data?.updateAsset) {
          setButtonLoading(false);
          history?.replace(ROUTES?.ASSETS_IMAGES);
        }
      }
    } catch (error) {
      setImageProgress(undefined);
      setButtonLoading(false);
      return error;
    }
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  if (imageLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        name="create-asset"
        layout="vertical"
        className="add-edit-form"
        onFinish={onFinish}
        disabled={isViewOnly}
      >
        <Form.Item
          name="title"
          label="Title"
          required
          rules={[
            formValidatorRules?.required('Please enter title!'),
            formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
          ]}
        >
          <Input
            placeholder="Enter title"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
        >
          <TextArea
            rows={2}
            placeholder="Enter description"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>
        <Form.Item
          name="platform"
          label="Service Provider"
          rules={[{ required: true, message: 'Service provider is required' }]}
        >
          <SelectPlatForm
            disabled={isViewOnly || (isUpdate && isAssetEdit)}
            type={PROVIDER_TYPES.STORAGE}
            fullWidth
          />
        </Form.Item>
        <Form.Item
          name="image"
          label="Image"
          rules={[
            {
              validator: async (_, value) => {
                const { name } = value?.[0]?.originFileObj || {};
                const ext = name?.substring(name?.lastIndexOf('.') + 1);

                if (!allowedImages?.includes(ext) && value?.length) {
                  throw new Error('Please select a valid file');
                }

                if (!(isUpdate && isAssetEdit) && !value?.length) {
                  throw new Error('Please select image!');
                }
              }
            }
          ]}
          getValueFromEvent={(e) => {
            if (isArray(e)) {
              return e;
            }
            return e?.fileList;
          }}
          valuePropName="fileList"
        >
          <Upload
            maxCount={1}
            disabled={isViewOnly || buttonLoading}
            accept=".jpg,.jpeg,.png,.heic,.gif"
            beforeUpload={() => false}
          >
            <Space>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
              <span>(.jpg, .jpeg, .png, .heic, .gif)</span>
            </Space>
          </Upload>
        </Form.Item>

        {imageProgress >= 0 && (
          <Form.Item>
            <ProgressBar progress={imageProgress} />
          </Form.Item>
        )}
        <div className="d-flex button-section">
          <Space>
            {isAddEditAllowed && (
              <Form.Item>
                <Button
                  loading={buttonLoading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>
              </Form.Item>
            )}
            <Form.Item>
              <Button
                type="text"
                className="text-btn2"
                disabled={buttonLoading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </>
  );
};

export default CommonImageForm;
