import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  allowedTextFile,
  ASSET_CATEGORY,
  DOMAIN_STATUS,
  MAX_LENGTHS,
  PROVIDER_TYPES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { fileUpload, formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { SelectPlatForm } from '../../videos/components/FormInputs';
import {
  CREATE_ASSET,
  UPDATE_ASSET,
  UPDATE_ASSET_STATUS
} from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';

const { TextArea } = Input;

const CommonTextForm = ({
  isModal,
  setShowModal,
  setNewAsset,
  isAssetEdit
}) => {
  const location = useLocation();
  const { textId, isUpdate } = location?.state || {};
  const [textData, setTextData] = useState({});
  const [textLoading, setTextLoading] = useState(true);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [textProgress, setTextProgress] = useState(undefined);
  const [fetchText] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: textId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setTextLoading(true);
      setTextData(res?.asset);
      setTextLoading(false);
    },
    onError: () => {
      setTextLoading(false);
    }
  });

  useEffect(() => {
    if (textId) {
      fetchText();
    } else {
      setTextLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textId]);

  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onError: () => {}
  });
  const [createAssetMutate] = useMutation(CREATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAssetMutate] = useMutation(UPDATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAssetStatus] = useMutation(UPDATE_ASSET_STATUS, {
    onError: () => {}
  });

  const handleCancel = () => {
    if (isModal) {
      setShowModal(false);
      setNewAsset(null);
    } else {
      history?.replace(ROUTES?.ASSETS_MEDIA_CAPTIONS);
    }
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    const { text, platform } = values;

    try {
      const formData = {
        title: values?.title,
        description: values?.description
      };
      let key = null;
      let contentType = null;
      if (text?.length > 0) {
        setTextProgress(0);
        const textFile = text?.[0]?.originFileObj;
        const fileName = text?.[0]?.name;
        contentType = text?.[0]?.type;

        const getSignedPutUrlResult = await getSignedUrl({
          variables: {
            data: {
              fileName: fileName?.replace(/ /g, '_'),
              contentType,
              assetType: ASSET_CATEGORY?.TEXT,
              platformId: platform?.value
            }
          }
        });
        // CMP-1608 : according to this task we have changed the flow. first we create asset then upload the respective file to the platform.
        const type =
          getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.aclType;

        const media = {
          ...formData,
          categoryKey: ASSET_CATEGORY?.TEXT,
          contentType
        };
        if (!key) {
          delete media?.key;
        }
        if (isUpdate && isAssetEdit) {
          const response = await updateAssetMutate({
            variables: {
              where: { id: textId },
              data: {
                ...media,
                key: getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key
              }
            }
          });
          if (response?.data?.updateAsset) {
            if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
              await fileUpload(
                getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
                textFile,
                setTextProgress,
                type
              )
                .then(async () => {
                  await updateAssetStatus({
                    variables: {
                      where: {
                        id: textId
                      },
                      data: {
                        errorObj: {},
                        status: DOMAIN_STATUS.READY
                      }
                    }
                  });
                })
                .catch(async (err) => {
                  await updateAssetStatus({
                    variables: {
                      where: {
                        id: textId
                      },
                      data: {
                        errorObj: JSON.parse(err),
                        status: DOMAIN_STATUS.ERRORED
                      }
                    }
                  });
                });
              key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
            }
            setButtonLoading(false);
            history?.replace(ROUTES?.ASSETS_MEDIA_CAPTIONS);
          }
        } else {
          const response = await createAssetMutate({
            variables: {
              data: {
                ...media,
                key: getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key,
                platformId: platform?.value
              }
            }
          });
          if (response?.data?.createAsset) {
            if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
              await fileUpload(
                getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
                textFile,
                setTextProgress,
                type
              )
                .then(async () => {
                  await updateAssetStatus({
                    variables: {
                      where: {
                        id: response?.data?.createAsset?.asset?.id
                      },
                      data: {
                        errorObj: {},
                        status: DOMAIN_STATUS.READY
                      }
                    }
                  });
                })
                .catch(async (err) => {
                  await updateAssetStatus({
                    variables: {
                      where: {
                        id: response?.data?.createAsset?.asset?.id
                      },
                      data: {
                        errorObj: JSON.parse(err),
                        status: DOMAIN_STATUS.ERRORED
                      }
                    }
                  });
                });
              key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
            }
            if (isModal) {
              const asset = response?.data?.createAsset?.asset;
              setShowModal(false);
              setNewAsset({ asset: true, data: asset });
            } else {
              history?.replace(ROUTES?.ASSETS_MEDIA_CAPTIONS);
            }
            setTextProgress(undefined);
            setButtonLoading(false);
          }
        }
      } else {
        const response = await updateAssetMutate({
          variables: {
            where: { id: textId },
            data: {
              title: values?.title,
              description: values?.description,
              categoryKey: ASSET_CATEGORY?.TEXT
            }
          }
        });
        if (response?.data?.updateAsset) {
          setButtonLoading(false);
          history?.replace(ROUTES?.ASSETS_MEDIA_CAPTIONS);
        }
      }
    } catch (error) {
      setTextProgress(undefined);
      setButtonLoading(false);
      return error;
    }
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const initialValues = {
    ...textData,
    platform: textData?.platform
      ? {
          label: textData?.platform?.name,
          value: textData?.platform?.id
        }
      : null
  };

  if (textLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        name="create-asset"
        layout="vertical"
        className="add-edit-form"
        onFinish={onFinish}
        disabled={isViewOnly}
      >
        <Form.Item
          name="title"
          label="Title"
          required
          rules={[
            formValidatorRules?.required('Please enter title!'),
            formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
          ]}
        >
          <Input
            placeholder="Enter title"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
        >
          <TextArea
            rows={2}
            placeholder="Enter description"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>
        <Form.Item
          name="platform"
          label="Service Provider"
          rules={[{ required: true, message: 'Service provider is required' }]}
        >
          <SelectPlatForm
            disabled={isUpdate && isAssetEdit}
            type={PROVIDER_TYPES.STORAGE}
            fullWidth
          />
        </Form.Item>
        <Form.Item
          name="text"
          label="Text Track"
          rules={[
            {
              validator: async (_, value) => {
                const { name } = value?.[0]?.originFileObj || {};
                const ext = name?.substring(name?.lastIndexOf('.') + 1);

                if (!allowedTextFile?.includes(ext) && value?.length) {
                  throw new Error('Please select a valid file');
                }

                if (!(isUpdate && isAssetEdit) && !value?.length) {
                  throw new Error('Please select file!');
                }
              }
            }
          ]}
          getValueFromEvent={(e) => {
            if (isArray(e)) {
              return e;
            }
            return e?.fileList;
          }}
          valuePropName="fileList"
        >
          <Upload
            maxCount={1}
            disabled={buttonLoading}
            accept=".vtt"
            beforeUpload={() => false}
          >
            <Space>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
              <span>(.vtt)</span>
            </Space>
          </Upload>
        </Form.Item>

        {textProgress >= 0 && (
          <Form.Item>
            <ProgressBar progress={textProgress} />
          </Form.Item>
        )}

        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              {isAddEditAllowed && (
                <Button
                  loading={buttonLoading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="text"
                className="text-btn2"
                disabled={buttonLoading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </>
  );
};

export default CommonTextForm;
