import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_OPTIONS,
  AUTO_GENERATED_SORT_BY_TYPES,
  AUTO_GENERATED_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES,
  TEMPLATE_OPTION,
  getAutoGenerateByDataValue,
  getAutoGeneratedByIdProps
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { SelectPodcasts } from '../../../../labels/collections/components/FormInputs';
import { GET_COLLECTION_ITEMS } from '../../../../labels/collections/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const AUTO_GENERATED_SORT_BY_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM },
  { label: 'Plays', value: AUTO_GENERATED_SORT_BY_TYPES.PLAYS }
];

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  isDefaultModule: false,
  settings: {
    title: true,
    description: true,
    viewAll: true,
    podcastTimestamp: true,
    podcastDate: true,
    podcastSpeaker: true,
    podcastTopic: true,
    podcastOrder: true,
    viewSearch: true,
    viewFilter: true,
    podcastDescription: true,
    podcastKebabMenu: true,
    shareAction: true,
    saveAction: true,
    transcriptAction: true,
    podcastCTA: true,
    podcastSave: true
  },
  config: {
    autoGenerate: false,
    autoGenerateByType: null,
    autoGenerateById: null,
    autoGenerateBySubCollectionId: null,
    autoGenerateSortBy: null,
    autoGenerateLimit: null,
    podcasts: [
      MODULE_TYPES.FEATURED_PODCAST,
      MODULE_TYPES.PODCAST_PLAYER
    ].includes(type)
      ? { id: '', url: '', title: '' }
      : []
  }
});

const MODULE_KEYS = {
  [MODULE_TYPES.PODCAST_CAROUSEL]: 'podcastCarouselModule',
  [MODULE_TYPES.PODCAST_LIST]: 'podcastListModule',
  [MODULE_TYPES.PODCAST_GRID]: 'podcastGridModule',
  [MODULE_TYPES.FEATURED_PODCAST]: 'featuredPodcastModule',
  [MODULE_TYPES.FEATURED_PODCAST_SLIDER]: 'featuredPodcastSliderModule',
  [MODULE_TYPES.PODCAST_PLAYER]: 'podcastPlayerModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.PODCAST_CAROUSEL]: 'Podcast Carousel Configs',
  [MODULE_TYPES.PODCAST_LIST]: 'Podcast List Configs',
  [MODULE_TYPES.PODCAST_GRID]: 'Podcast Grid Configs',
  [MODULE_TYPES.FEATURED_PODCAST]: 'Feature Podcast Configs',
  [MODULE_TYPES.FEATURED_PODCAST_SLIDER]: 'Feature Podcast Slider Configs',
  [MODULE_TYPES.PODCAST_PLAYER]: 'Podcast Player Configs'
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.PODCAST_CAROUSEL, MODULE_TYPES.PODCAST_LIST]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.PODCAST_CAROUSEL,
      MODULE_TYPES.PODCAST_LIST,
      MODULE_TYPES.PODCAST_GRID
    ]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [
      MODULE_TYPES.PODCAST_CAROUSEL,
      MODULE_TYPES.PODCAST_LIST,
      MODULE_TYPES.PODCAST_GRID
    ]
  },
  {
    name: 'podcastDescription',
    label: 'Podcast Description',
    allowedTypes: [
      MODULE_TYPES.PODCAST_PLAYER,
      MODULE_TYPES.FEATURED_PODCAST,
      MODULE_TYPES.FEATURED_PODCAST_SLIDER,
      MODULE_TYPES.PODCAST_LIST
    ]
  },
  {
    name: 'viewSearch',
    label: 'View Search',
    allowedTypes: [MODULE_TYPES.PODCAST_LIST, MODULE_TYPES.PODCAST_GRID]
  },
  {
    name: 'viewFilter',
    label: 'View Filters',
    allowedTypes: [MODULE_TYPES.PODCAST_LIST, MODULE_TYPES.PODCAST_GRID]
  },
  {
    name: 'podcastTimestamp',
    label: 'Timestamp',
    allowedTypes: [
      MODULE_TYPES.PODCAST_CAROUSEL,
      MODULE_TYPES.PODCAST_LIST,
      MODULE_TYPES.PODCAST_GRID
    ]
  },
  {
    name: 'podcastDate',
    label: 'Date',
    allowedTypes: [
      MODULE_TYPES.PODCAST_PLAYER,
      MODULE_TYPES.FEATURED_PODCAST,
      MODULE_TYPES.FEATURED_PODCAST_SLIDER,
      MODULE_TYPES.PODCAST_CAROUSEL,
      MODULE_TYPES.PODCAST_LIST,
      MODULE_TYPES.PODCAST_GRID
    ]
  },
  {
    name: 'podcastSpeaker',
    label: 'Speaker',
    allowedTypes: [
      MODULE_TYPES.PODCAST_PLAYER,
      MODULE_TYPES.FEATURED_PODCAST,
      MODULE_TYPES.FEATURED_PODCAST_SLIDER,
      MODULE_TYPES.PODCAST_CAROUSEL,
      MODULE_TYPES.PODCAST_LIST,
      MODULE_TYPES.PODCAST_GRID
    ]
  },

  {
    name: 'podcastTopic',
    label: 'Topic',
    allowedTypes: [
      MODULE_TYPES.PODCAST_PLAYER,
      MODULE_TYPES.FEATURED_PODCAST,
      MODULE_TYPES.FEATURED_PODCAST_SLIDER,
      MODULE_TYPES.PODCAST_CAROUSEL,
      MODULE_TYPES.PODCAST_LIST,
      MODULE_TYPES.PODCAST_GRID
    ]
  },

  {
    name: 'podcastOrder',
    label: 'Order as Selected',
    allowedTypes: [
      MODULE_TYPES.PODCAST_PLAYER,
      MODULE_TYPES.PODCAST_CAROUSEL,
      MODULE_TYPES.PODCAST_LIST,
      MODULE_TYPES.PODCAST_GRID,
      MODULE_TYPES.FEATURED_PODCAST,
      MODULE_TYPES.FEATURED_PODCAST_SLIDER
    ]
  },

  {
    name: 'podcastKebabMenu',
    label: 'Kebab Menu',
    allowedTypes: [
      MODULE_TYPES.PODCAST_CAROUSEL,
      MODULE_TYPES.PODCAST_LIST,
      MODULE_TYPES.PODCAST_GRID
    ]
  },
  {
    name: 'shareAction',
    label: 'Share Action',
    allowedTypes: [MODULE_TYPES.PODCAST_PLAYER]
  },
  {
    name: 'saveAction',
    label: 'Save Action',
    allowedTypes: [MODULE_TYPES.PODCAST_PLAYER]
  },
  {
    name: 'transcriptAction',
    label: 'Transcript Action',
    allowedTypes: [MODULE_TYPES.PODCAST_PLAYER]
  },
  {
    name: 'podcastCTA',
    label: 'Play Action',
    allowedTypes: [
      MODULE_TYPES.FEATURED_PODCAST,
      MODULE_TYPES.FEATURED_PODCAST_SLIDER
    ]
  },
  {
    name: 'podcastSave',
    label: 'Save Action',
    allowedTypes: [
      MODULE_TYPES.FEATURED_PODCAST,
      MODULE_TYPES.FEATURED_PODCAST_SLIDER
    ]
  }
];

const { PODCAST } = PAGE_TYPES;
const REST_PAGE_TYPES = [
  PAGE_TYPES.TOPIC,
  PAGE_TYPES.TAG,
  PAGE_TYPES.CONTRIBUTOR,
  PAGE_TYPES.COLLECTION
];
const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.PODCAST_CAROUSEL]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.PODCAST_LIST]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.PODCAST_GRID]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_PODCAST]: [PODCAST, ...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_PODCAST_SLIDER]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.PODCAST_PLAYER]: [PODCAST]
};

const PodcastForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType,
  pageId
}) => {
  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const collection = Form?.useWatch(['config', 'autoGenerateById'], form);
  const collectionType = Form?.useWatch(['config', 'autoGenerateByType'], form);
  const collectionId = collection?.value;
  const configProps = Form.useWatch(['config'], form);
  const { autoGenerateByType, autoGenerate } =
    configProps ?? initialValues.config;
  const isMultiPodcast = ![
    MODULE_TYPES.FEATURED_PODCAST,
    MODULE_TYPES.PODCAST_PLAYER
  ].includes(type);

  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);
  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  const includeOptions = useMemo(
    () => (autoGenerateByType === pageType ? [TEMPLATE_OPTION] : []),
    [autoGenerateByType, pageType]
  );

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },

        config: {
          ...(type !== MODULE_TYPES.PODCAST_PLAYER && {
            autoGenerate:
              defaultValues?.moduleData?.config?.autoGenerate ?? false,
            autoGenerateByType:
              defaultValues?.moduleData?.config?.autoGenerateByType || null,
            autoGenerateById: idm
              ? TEMPLATE_OPTION
              : getAutoGenerateByDataValue[
                  defaultValues?.moduleData?.config?.autoGenerateByType
                ]?.(defaultValues?.moduleData?.config?.autoGenerateByData) ??
                null,
            autoGenerateBySubCollectionId:
              defaultValues?.moduleData?.config
                ?.autoGenerateBySubCollectionData === null
                ? { label: 'All', value: 'all' }
                : {
                    label:
                      defaultValues?.moduleData?.config
                        ?.autoGenerateBySubCollectionData?.title,
                    value:
                      defaultValues?.moduleData?.config
                        ?.autoGenerateBySubCollectionData?.id
                  },
            autoGenerateSortBy:
              defaultValues?.moduleData?.config?.podcastAutoGenerateSortBy ||
              null,
            autoGenerateLimit:
              defaultValues?.moduleData?.config?.autoGenerateLimit || null
          }),
          podcasts: isMultiPodcast
            ? defaultValues?.moduleData?.config?.podcasts?.map(
                ({ id, title, imageThumbnail }) => ({
                  id,
                  title,
                  url: imageThumbnail?.url ?? ''
                })
              ) || []
            : {
                id: defaultValues?.moduleData?.config?.podcast?.id ?? '',
                title: defaultValues?.moduleData?.config?.podcast?.title ?? '',
                url:
                  defaultValues?.moduleData?.config?.podcast?.imageThumbnail
                    ?.url ?? ''
              }
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    isDefaultModule: defaultModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const isDefault = allowedTemplateConfig && defaultModule;
    const {
      podcasts,
      autoGenerate: generate,
      autoGenerateById,
      autoGenerateLimit,
      autoGenerateBySubCollectionId,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,

        config: {
          autoGenerate: generate,
          autoGenerateById: autoGenerateById?.value,
          ...(generate &&
            autoGenerateBySubCollectionId && {
              autoGenerateBySubCollectionId:
                autoGenerateBySubCollectionId?.value === 'all'
                  ? null
                  : autoGenerateBySubCollectionId?.value
            }),
          ...restConfig,
          ...(isDefault
            ? {
                podcastId: '*'
              }
            : {
                ...(!generate &&
                  isMultiPodcast && {
                    podcasts:
                      podcasts?.map(({ id }, i) => ({
                        podcastId: id,
                        order: i + 1
                      })) ?? []
                  }),
                ...(!generate &&
                  !isMultiPodcast && {
                    podcastId: podcasts?.id ?? ''
                  })
              }),
          ...(autoGenerateLimit && {
            autoGenerateLimit: Number(autoGenerateLimit)
          })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleSubmit}
      form={form}
    >
      <ModuleFields
        title={{
          settingToggle: [
            MODULE_TYPES.PODCAST_CAROUSEL,
            MODULE_TYPES.PODCAST_LIST,
            MODULE_TYPES.PODCAST_GRID,
            MODULE_TYPES.FEATURED_PODCAST,
            MODULE_TYPES.FEATURED_PODCAST_SLIDER,
            MODULE_TYPES.PODCAST_PLAYER
          ].includes(type)
        }}
        description={{
          settingToggle: [
            MODULE_TYPES.PODCAST_CAROUSEL,
            MODULE_TYPES.PODCAST_LIST,
            MODULE_TYPES.PODCAST_GRID,
            MODULE_TYPES.FEATURED_PODCAST,
            MODULE_TYPES.FEATURED_PODCAST_SLIDER,
            MODULE_TYPES.PODCAST_PLAYER
          ].includes(type)
        }}
      />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />

        <Space className="w-full" direction="vertical">
          {allowedTemplateConfig && pageType === PODCAST && (
            <>
              <Typography.Text>Template Config</Typography.Text>
              <Form.Item
                className="m-0"
                name="isDefaultModule"
                valuePropName="checked"
              >
                <Switch label="Use Template Data" />
              </Form.Item>
            </>
          )}
          {(!allowedTemplateConfig ||
            (allowedTemplateConfig && !isDefaultModule)) && (
            <>
              <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
              <div>
                {type !== MODULE_TYPES.PODCAST_PLAYER && (
                  <>
                    <Form.Item
                      name={['config', 'autoGenerate']}
                      valuePropName="checked"
                    >
                      <Switch
                        label={
                          isMultiPodcast
                            ? 'Auto Generated Podcasts'
                            : 'Auto Generated Podcast'
                        }
                      />
                    </Form.Item>
                    {autoGenerate && (
                      <>
                        <Form.Item
                          label="Auto Generated By Type"
                          name={['config', 'autoGenerateByType']}
                        >
                          <AntdSelect
                            options={AUTO_GENERATED_OPTIONS}
                            placeholder="Select type"
                            onChange={() => {
                              form.setFieldValue(
                                ['config', 'autoGenerateById'],
                                null
                              );
                            }}
                          />
                        </Form.Item>
                        {autoGenerateByType && autoGenerateByIdProps && (
                          <Form.Item
                            label="Auto Generated By"
                            name={['config', 'autoGenerateById']}
                          >
                            <Select
                              placeholder="Select"
                              query={autoGenerateByIdProps?.query}
                              variablesSelector={(filter) => ({
                                filter,
                                ...(autoGenerateByType ===
                                  AUTO_GENERATED_TYPES.CONTRIBUTOR && {
                                  where: { isActive: true }
                                })
                              })}
                              dataSelector={autoGenerateByIdProps?.dataSelector}
                              keys={autoGenerateByIdProps?.keys}
                              {...(allowedTemplateConfig && {
                                includeOptions
                              })}
                              onChange={() => {
                                form.setFieldValue(
                                  ['config', 'autoGenerateBySubCollectionId'],
                                  null
                                );
                              }}
                            />
                          </Form.Item>
                        )}
                        {collectionType === AUTO_GENERATED_TYPES?.COLLECTION &&
                          collectionId && (
                            <Form.Item
                              label="Select SubCollection"
                              name={['config', 'autoGenerateBySubCollectionId']}
                            >
                              <Select
                                placeholder="Select sub collection"
                                query={GET_COLLECTION_ITEMS}
                                variablesSelector={() => ({
                                  where: {
                                    id: collectionId
                                  }
                                })}
                                dataSelector={(data) =>
                                  data?.collectionItemsAdmin?.collectionItems?.map(
                                    (item) => ({
                                      label: item?.itemData?.title,
                                      value: item?.itemData?.id
                                    })
                                  ) ?? []
                                }
                                includeOptions={[
                                  { label: 'All', value: 'all' }
                                ]}
                                keys={{
                                  data: 'collectionItemsAdmin',
                                  records: 'collectionItems',
                                  count: 'count'
                                }}
                              />
                            </Form.Item>
                          )}
                        <Form.Item
                          label="Auto Generated Sort By"
                          name={['config', 'autoGenerateSortBy']}
                        >
                          <AntdSelect
                            options={AUTO_GENERATED_SORT_BY_OPTIONS}
                            placeholder="Select sort by"
                          />
                        </Form.Item>
                        {isMultiPodcast && (
                          <Form.Item
                            label="No. of Auto generated Podcasts"
                            name={['config', 'autoGenerateLimit']}
                            rules={[
                              formValidatorRules?.number,
                              formValidatorRules?.maxNumberAllowed(
                                configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                              )
                            ]}
                          >
                            <Input placeholder="Enter number" />
                          </Form.Item>
                        )}
                      </>
                    )}
                  </>
                )}
                {!autoGenerate && (
                  <Form.Item
                    label={
                      isMultiPodcast ? 'Select Podcasts' : 'Select Podcast'
                    }
                    name={['config', 'podcasts']}
                    extra={
                      isMultiPodcast ? 'Select items in order you want' : ''
                    }
                  >
                    <SelectPodcasts multiple={isMultiPodcast} />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default PodcastForm;
