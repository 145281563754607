import { Spin, Typography } from 'antd';
import React, { memo } from 'react';
import PermissionCard from './PermissionCard';

const { Title } = Typography;

const MiddlePanelPermissions = ({
  permissionKey,
  setPermissionKey,
  form,
  permissions,
  loading,
  permissionsLoading
}) => {
  const Module = memo(({ data, active }) => {
    const newData = {
      ...data,
      key: data?.key?.split('_')?.join(' ')
    };
    const handleClick = () => {
      if (data?.key === permissionKey || permissionsLoading) return;
      setPermissionKey(data?.key);
      form?.resetFields();
    };

    return (
      <PermissionCard
        title={newData?.key}
        active={active}
        onClick={handleClick}
        disabled={permissionsLoading}
      />
    );
  });

  return (
    <>
      <div className="module-wrapper">
        <div className="text-center mt-8">
          <Title level={3}>Permissions</Title>
          {loading ? (
            <Spin spinning={loading} />
          ) : (
            permissions?.map((module) => (
              <React.Fragment key={module?.id}>
                <Module data={module} active={module?.key === permissionKey} />
              </React.Fragment>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default MiddlePanelPermissions;
