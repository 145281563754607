import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { Col, FloatButton, Row, Spin, Table } from 'antd';
import { debounce, isArray, isFunction } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { ASSET_CATEGORY, LIST_TYPES } from '../common/constants';
import EmptyStatePage from './EmptyStatePage';
import InView from './InView';
import LoaderComponent from './LoaderComponent';
import PreviewModal from './PreviewModal';

const LIMIT = 15;
const initialPreviewModalState = {
  show: false,
  title: '',
  url: '',
  type: ASSET_CATEGORY.IMAGE
};

const PageList = forwardRef(
  (
    {
      query,
      variablesSelector,
      dataSelector,
      filters,
      listMode = LIST_TYPES.GRID,
      GridProps: {
        renderItem = () => {},
        breakPoints = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 },
        colSpacing = 16,
        rowSpacing = 16
      } = {},
      TableProps: { columns: tableCols = [], ...restTableProps } = {},
      limit = LIMIT,
      keyField = 'id',
      queryOptions = {},
      onLoad,
      onRow
    },
    ref
  ) => {
    const [previewModal, setPreviewModal] = useState({
      ...initialPreviewModalState
    });

    const [records, setRecords] = useState([]);
    const [count, setCount] = useState(0);
    const [networkStatus, setNetworkStatus] = useState(NetworkStatus.loading);

    const showPreview = useCallback((props) => {
      if (
        [ASSET_CATEGORY.TEXT, ASSET_CATEGORY.DOCUMENT].includes(props.type) &&
        props.url
      ) {
        // eslint-disable-next-line no-undef
        window?.open(props.url, 'blank');
        return;
      }
      setPreviewModal((prev) => ({
        ...prev,
        ...props,
        show: true
      }));
    }, []);

    const hidePreview = () => {
      setPreviewModal({ ...initialPreviewModalState });
    };

    const getUniqueKey = useCallback(
      (record) => (isFunction(keyField) ? keyField(record) : record[keyField]),
      [keyField]
    );

    const removeItem = useCallback(
      (id) => {
        const newItems = records.filter(
          (record) => getUniqueKey(record) !== id
        );
        setRecords(newItems);
        setCount((prev) => prev - 1);
      },
      [getUniqueKey, records]
    );

    const handleResponse = (responseData, isInitial = false) => {
      setNetworkStatus(NetworkStatus.ready);
      const res = dataSelector(responseData);
      if (!!res && 'data' in res && 'count' in res) {
        if (isArray(res.data)) {
          if (isFunction(onLoad)) onLoad(res.data);
          if (isInitial) setRecords([...res.data]);
          else {
            setRecords([...records, ...res.data]);
          }
          if (typeof res.count === 'number') {
            setCount(res.count);
          }
        }
      }
    };

    const handleError = () => {
      setNetworkStatus(NetworkStatus.error);
    };

    const [fetchRecords, { fetchMore }] = useLazyQuery(query, {
      onCompleted: (responseData) => {
        handleResponse(responseData, true);
      },
      onError: handleError,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      ...queryOptions
    });

    const debounceFetch = useMemo(() => debounce(fetchRecords, 500), [
      fetchRecords
    ]);

    const clear = useCallback(() => {
      setRecords([]);
      setCount(0);
    }, []);

    const updateData = useCallback((id, callback) => {
      setRecords((prev) =>
        prev?.map((item) => {
          if (isFunction(id) ? id(item) : item?.id === id) {
            if (isFunction(callback)) {
              const newItem = callback(item);
              if (newItem) return newItem;
            }
          }
          return item;
        })
      );
    }, []);

    const refresh = useCallback(() => {
      const variables = variablesSelector({
        ...filters,
        search: filters?.search?.trim(),
        offset: 0,
        limit
      });
      if (variables) {
        clear();
        setNetworkStatus(NetworkStatus.loading);
        debounceFetch({
          variables
        });
      }
    }, [filters, debounceFetch, clear]);

    const loading = networkStatus === NetworkStatus.loading;
    const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
    const hasError = networkStatus === NetworkStatus.error;
    const hasMoreData = count > records.length;

    useEffect(() => {
      refresh();
    }, [refresh]);

    useImperativeHandle(
      ref,
      () => ({ removeItem, refresh, showPreview, updateData }),
      [removeItem, refresh, showPreview, updateData]
    );

    const columns = useMemo(
      () =>
        isFunction(tableCols)
          ? tableCols({ showPreview, removeItem, refresh, updateData })
          : tableCols,
      [tableCols, showPreview, removeItem, refresh, updateData]
    );

    const fetchMoreRecords = ({ offset } = {}) => {
      setNetworkStatus(NetworkStatus.fetchMore);
      const variables = variablesSelector({
        ...filters,
        search: filters?.search?.trim(),
        offset,
        limit
      });
      if (variables) {
        fetchMore({
          variables
        })
          .then(({ data }) => {
            handleResponse(data);
          })
          .catch(handleError);
      }
    };

    return (
      <>
        <PreviewModal {...previewModal} onClose={hidePreview} />
        <div className="ant-body-scroll assets-wrapper">
          {!loading && records.length <= 0 ? (
            <EmptyStatePage />
          ) : (
            <>
              {listMode === LIST_TYPES.GRID &&
                (loading ? (
                  <LoaderComponent />
                ) : (
                  <>
                    <Row gutter={[colSpacing, rowSpacing]}>
                      {records.map((record) => (
                        <Col
                          {...(breakPoints !== null &&
                          breakPoints !== 'auto' &&
                          typeof breakPoints === 'object'
                            ? { ...breakPoints }
                            : {})}
                          key={getUniqueKey(record)}
                        >
                          {renderItem(record, {
                            showPreview,
                            removeItem,
                            refresh,
                            updateData
                          })}
                        </Col>
                      ))}
                    </Row>
                  </>
                ))}

              {listMode === LIST_TYPES.TABLE && (
                <div className="listview">
                  <Table
                    loading={loading}
                    rowKey={getUniqueKey}
                    columns={columns}
                    bordered={false}
                    dataSource={records}
                    pagination={false}
                    {...(onRow && { onRow })}
                    {...restTableProps}
                  />
                </div>
              )}
              {isFetchingMore && (
                <div className="d-flex justify-center pt-16">
                  <Spin />
                </div>
              )}
              {hasMoreData && !isFetchingMore && !loading && !hasError && (
                <InView
                  onChange={({ inView }) => {
                    if (inView) {
                      fetchMoreRecords({
                        offset: records.length
                      });
                    }
                  }}
                />
              )}
            </>
          )}
          <FloatButton.BackTop
            // eslint-disable-next-line no-undef
            target={() => document.querySelector('.assets-wrapper')}
          />
        </div>
      </>
    );
  }
);

export default PageList;
