import { useMutation } from '@apollo/client';
import { Button, Form, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import { MODULE_TYPES, STATUS_TYPES } from '../../../../../common/constants';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions } from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  config: {
    adBannerSize: null,
    adType: null
  }
};

const MODULE_KEYS = {
  [MODULE_TYPES.AD]: 'adModule'
};

const AD_BANNER_SIZE_VALUES = {
  BANNER: 'BANNER',
  FULL_BANNER: 'FULL_BANNER',
  LARGE_BANNER: 'LARGE_BANNER',
  LEADERBOARD: 'LEADERBOARD',
  MEDIUM_RECTANGLE: 'MEDIUM_RECTANGLE',
  ADAPTIVE_BANNER: 'ADAPTIVE_BANNER',
  ANCHORED_ADAPTIVE_BANNER: 'ANCHORED_ADAPTIVE_BANNER',
  INLINE_ADAPTIVE_BANNER: 'INLINE_ADAPTIVE_BANNER',
  WIDE_SKYSCRAPER: 'WIDE_SKYSCRAPER'
};

const AD_TYPE_VALUES = {
  ADMOB: 'ADMOB',
  GAME: 'GAME'
};

const AD_BANNER_SIZE_OPTIONS = [
  { label: 'Banner', value: AD_BANNER_SIZE_VALUES?.BANNER },
  { label: 'Full Banner', value: AD_BANNER_SIZE_VALUES?.FULL_BANNER },
  { label: 'Large Banner', value: AD_BANNER_SIZE_VALUES?.LARGE_BANNER },
  { label: 'Leaderboard', value: AD_BANNER_SIZE_VALUES?.LEADERBOARD },
  { label: 'Medium Rectangle', value: AD_BANNER_SIZE_VALUES?.MEDIUM_RECTANGLE },
  { label: 'Adaptive Banner', value: AD_BANNER_SIZE_VALUES?.ADAPTIVE_BANNER },
  {
    label: 'Anchored Adaptive Banner',
    value: AD_BANNER_SIZE_VALUES?.ANCHORED_ADAPTIVE_BANNER
  },
  {
    label: 'Inline Adaptive Banner',
    value: AD_BANNER_SIZE_VALUES?.INLINE_ADAPTIVE_BANNER
  },
  { label: 'Wide Skyscraper', value: AD_BANNER_SIZE_VALUES?.WIDE_SKYSCRAPER }
];

const AD_TYPE_OPTIONS = [
  { label: 'Admob', value: AD_TYPE_VALUES?.ADMOB },
  { label: 'Game', value: AD_TYPE_VALUES?.GAME }
];

const AdForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        config: {
          adBannerSize: defaultValues?.moduleData?.config?.adBannerSize ?? null,
          adType: defaultValues?.moduleData?.config?.adType ?? null
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({ config, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;
    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        config: {
          adBannerSize: config?.adBannerSize,
          adType: config?.adType
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <Form.Item label="Ad Banner Size" name={['config', 'adBannerSize']}>
          <Select
            placeholder="Select alignment"
            options={AD_BANNER_SIZE_OPTIONS}
          />
        </Form.Item>
        <Form.Item label="Ad Type" name={['config', 'adType']}>
          <Select placeholder="Select alignment" options={AD_TYPE_OPTIONS} />
        </Form.Item>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default AdForm;
