import { useMutation } from '@apollo/client';
import { Button, Form, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
  MAX_LENGTHS,
  MODULE_TYPES,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { KeyInput, ModuleFields, Permissions, ShowFields } from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    title: true,
    description: true,
    image: true,
    icon: true
  },
  config: {
    key: ''
  }
};

const SETTINGS = [
  {
    name: 'image',
    label: 'Image',
    allowedTypes: []
  },
  {
    name: 'icon',
    label: 'Icon',
    allowedTypes: []
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.CONTACT_US]: 'contactUsModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.CONTACT_US]: 'Contact Us Configs'
};

const LeadGenForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          key: defaultValues?.moduleData?.config?.key ?? ''
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;
    const { key: keyField, ...restConfig } = config ?? {};
    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        ...(!(type === MODULE_TYPES.CONTACT_US && isEdit) && {
          config: {
            ...(!isEdit && {
              key: keyField
            }),
            ...restConfig
          }
        })
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields
        title={{
          settingToggle: [MODULE_TYPES.CONTACT_US].includes(type)
        }}
        description={{
          settingToggle: [MODULE_TYPES.CONTACT_US].includes(type)
        }}
      />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />

        <Space className="w-full" direction="vertical">
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          <div>
            <Form.Item
              label="Key"
              name={['config', 'key']}
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.KEY)]}
            >
              <KeyInput
                disabled={isEdit}
                readOnly={isEdit}
                placeholder="Enter key"
              />
            </Form.Item>
          </div>
        </Space>

        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default LeadGenForm;
