import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
  MAX_LENGTHS,
  MODULE_TYPES,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields } from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    description: true
  },
  config: {
    questions: []
  }
};

const SETTINGS = [
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.FAQ]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.FAQ]: 'faqModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.FAQ]: 'FAQ Configs'
};

const FAQInput = ({ name, remove, ...restField }) => {
  return (
    <>
      <Form.Item
        name={[name, 'question']}
        label="Question"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)]}
        {...restField}
      >
        <Input placeholder="Enter question" />
      </Form.Item>
      <Form.Item
        name={[name, 'answer']}
        label="Answer"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
        {...restField}
      >
        <Input.TextArea placeholder="Enter answer" />
      </Form.Item>
      <Button
        className="text-btn mb-12"
        type="text"
        size="small"
        onClick={() => remove(name)}
      >
        Remove Question
      </Button>
    </>
  );
};

const FAQForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          questions:
            defaultValues?.moduleData?.config?.questions?.map((que) => ({
              question: que?.question ?? '',
              answer: que?.answer ?? ''
            })) ?? []
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const { questions } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          questions: questions.map(({ question, answer }) => ({
            question,
            answer
          }))
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          <div>
            <Form.List name={['config', 'questions']}>
              {(fields, { add, remove }) => (
                <>
                  {fields?.map((field) => (
                    <FAQInput remove={remove} key={field?.key} {...field} />
                  ))}
                  <Form.Item>
                    <Button
                      className="text-btn"
                      type="text"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Question
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </Space>
      </Space>
      <Permissions />
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              type="text"
              htmlType="submit"
              className="text-btn mr-8"
              size="middle"
              disabled={loading}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              disabled={loading}
              onClick={onCancel}
              type="text"
              className="text-btn2"
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

export default FAQForm;
