import { IdcardOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';
import { Divider, Menu } from 'antd';
import { capitalize, forEach } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  MODULES,
  ROLE_KEYS,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import useCheckPermission from '../../../hooks/useCheckPermission';
import UserProfile from '../header/UserProfile';
import './sidebar.less';

function Sidebar({ location: { pathname }, history, collapsed }) {
  const {
    state: { workspace, app, currentUser: { roles = [] } = null }
  } = useContext(AppContext);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const subMenus = [
    {
      moduleKey: MODULES?.UI_CONFIGURATIONS,
      routes: [
        ROUTES?.UI_CONFIG_MENUS,
        ROUTES?.UI_CONFIG_PAGES,
        ROUTES?.UI_CONFIG_LABELS
      ]
    },
    {
      moduleKey: MODULES?.CONTENTS,
      routes: [
        ROUTES?.CONTENTS_VIDEOS,
        ROUTES?.CONTENTS_PODCASTS,
        ROUTES?.CONTENTS_FORMS,
        ROUTES?.CONTENTS_ARTICLES
      ]
    },
    {
      moduleKey: MODULES?.ASSETS,
      routes: [
        ROUTES?.ASSETS_IMAGES,
        ROUTES?.ASSETS_PODCASTS,
        ROUTES?.ASSETS_DOCUMENTS,
        ROUTES?.ASSETS_VIDEOS,
        ROUTES?.ASSETS_MEDIA_CAPTIONS,
        ROUTES?.ASSETS_ICONS
      ]
    },
    {
      moduleKey: MODULES?.LABELS,
      routes: [
        ROUTES?.LABELS_TOPICS,
        ROUTES?.LABELS_TAGS,
        ROUTES?.LABELS_CONTRIBUTORS,
        ROUTES?.LABELS_COLLECTIONS,
        ROUTES?.LABELS_SOURCES
      ]
    },
    {
      moduleKey: MODULES?.REPORTS,
      routes: [ROUTES?.REPORTS_EXPORT_JOBS, ROUTES?.REPORTS_IMPORT_JOBS]
    },
    {
      moduleKey: MODULES?.APP_SETTINGS,
      routes: [ROUTES?.USERS, ROUTES?.DONATIONS, ROUTES?.SUBSCRIPTIONS]
    },
    {
      moduleKey: MODULES?.WORKSPACE_SETTINGS,
      routes: [ROUTES?.WS_MEMBERS, ROUTES?.WS_ROLES, ROUTES?.WS_SERVICES]
    }
  ];

  const getOpenKeys = () => {
    const openKeysClone = openKeys;
    forEach(subMenus, (item) => {
      forEach(item?.routes, (route) => {
        if (
          route !== ROUTES?.MAIN &&
          pathname?.includes(route) &&
          !openKeysClone?.includes(item?.moduleKey)
        ) {
          openKeysClone?.push(item?.moduleKey);
          setOpenKeys(openKeysClone);
        }
      });
    });
  };

  useEffect(() => {
    getOpenKeys();
  }, [pathname]);

  useEffect(() => {
    if (pathname.split('/')?.length <= 2) {
      setSelectedKeys(`/${pathname?.split('/')?.[1]}`);
    } else {
      setSelectedKeys(
        `/${pathname?.split('/')?.[1]}/${pathname?.split('/')?.[2]}`
      );
    }
  }, [pathname]);

  const onMenuSelect = ({ key }) => {
    history?.push(key);
  };

  function getItem({ label, key, icon, children, type }) {
    return {
      key,
      icon,
      children,
      label,
      type
    };
  }
  const isContentManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const isAssetManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isLabelManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isPlatformManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isUserManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isRoleManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ROLE_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isSubscriptionAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isReportManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const uiConfigManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const uiPageManagementAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const menuItems = [
    getItem({
      label: MODULES?.DASHBOARD,
      key: ROUTES?.DASHBOARD_LANDING
    }),
    getItem({
      type: 'divider'
    }),
    app &&
      (uiConfigManagementAllowed || uiPageManagementAllowed) &&
      getItem({
        label: MODULES?.UI_CONFIGURATIONS,
        key: MODULES?.UI_CONFIGURATIONS,
        children: [
          uiConfigManagementAllowed &&
            getItem({
              label: MODULES?.MENUS,
              key: ROUTES?.UI_CONFIG_MENUS
            }),
          uiPageManagementAllowed &&
            getItem({
              label: MODULES?.PAGES,
              key: ROUTES?.UI_CONFIG_PAGES
            }),
          uiConfigManagementAllowed &&
            getItem({
              label: MODULES?.UI_LABELS,
              key: ROUTES?.UI_CONFIG_LABELS
            })
        ]
      }),
    isContentManagementAllowed &&
      getItem({
        label: MODULES?.CONTENTS,
        key: MODULES?.CONTENTS,
        children: [
          getItem({
            label: MODULES?.VIDEOS,
            key: ROUTES?.CONTENTS_VIDEOS
          }),
          getItem({
            label: MODULES?.PODCASTS,
            key: ROUTES?.CONTENTS_PODCASTS
          }),
          getItem({
            label: MODULES?.FORMS,
            key: ROUTES?.CONTENTS_FORMS
          }),
          getItem({ label: MODULES?.ARTICLES, key: ROUTES?.CONTENTS_ARTICLES })
        ]
      }),
    isAssetManagementAllowed &&
      getItem({
        label: MODULES?.ASSETS,
        key: MODULES?.ASSETS,
        children: [
          getItem({ label: MODULES?.IMAGES, key: ROUTES?.ASSETS_IMAGES }),
          getItem({ label: MODULES?.VIDEOS, key: ROUTES?.ASSETS_VIDEOS }),
          getItem({ label: MODULES?.DOCUMENTS, key: ROUTES?.ASSETS_DOCUMENTS }),
          getItem({ label: MODULES?.PODCASTS, key: ROUTES?.ASSETS_PODCASTS }),
          getItem({
            label: MODULES?.MEDIA_CAPTIONS,
            key: ROUTES?.ASSETS_MEDIA_CAPTIONS
          }),
          getItem({ label: MODULES?.ICONS, key: ROUTES?.ASSETS_ICONS })
        ]
      }),
    isLabelManagementAllowed &&
      getItem({
        label: MODULES?.LABELS,
        key: MODULES?.LABELS,
        children: [
          getItem({ label: MODULES?.TOPICS, key: ROUTES?.LABELS_TOPICS }),
          getItem({ label: MODULES?.TAGS, key: ROUTES?.LABELS_TAGS }),
          getItem({
            label: MODULES?.CONTRIBUTORS,
            key: ROUTES?.LABELS_CONTRIBUTORS
          }),
          getItem({
            label: MODULES?.COLLECTIONS,
            key: ROUTES?.LABELS_COLLECTIONS
          }),
          getItem({ label: MODULES?.SOURCES, key: ROUTES?.LABELS_SOURCES })
        ]
      }),
    isReportManagementAllowed &&
      getItem({
        label: MODULES?.REPORTS,
        key: MODULES?.REPORTS,
        children: [
          getItem({
            label: MODULES?.EXPORT_JOBS,
            key: ROUTES?.REPORTS_EXPORT_JOBS
          }),
          getItem({
            label: MODULES?.IMPORT_JOBS,
            key: ROUTES?.REPORTS_IMPORT_JOBS
          })
        ]
      }),
    isUserManagementAllowed &&
      getItem({
        label: MODULES?.USERS,
        key: ROUTES?.USERS
      }),
    getItem({
      type: 'divider'
    }),
    isUserManagementAllowed &&
      getItem({
        label: MODULES?.DONATIONS,
        key: ROUTES?.DONATIONS
      }),
    getItem({
      type: 'divider'
    }),
    isSubscriptionAllowed &&
      getItem({
        label: MODULES?.SUBSCRIPTIONS,
        key: ROUTES?.SUBSCRIPTIONS
      }),
    isSubscriptionAllowed &&
      getItem({
        type: 'divider'
      }),
    getItem({
      label: MODULES?.WORKSPACE_SETTINGS,
      key: MODULES?.WORKSPACE_SETTINGS,
      children: [
        isUserManagementAllowed &&
          getItem({
            label: MODULES?.MEMBERS,
            key: ROUTES?.WS_MEMBERS
          }),
        isRoleManagementAllowed &&
          getItem({
            label: MODULES?.ROLES,
            key: ROUTES?.WS_ROLES
          }),
        isPlatformManagementAllowed &&
          getItem({
            label: MODULES?.SERVICES,
            key: ROUTES?.WS_SERVICES
          })
      ]
    })
  ];

  const profileItems = [
    getItem({
      label: <UserProfile collapsed={collapsed} />,
      key: MODULES?.PROFILE,
      children: [
        [ROLE_KEYS.ADMIN, ROLE_KEYS.SUPER_ADMIN]?.includes(roles?.[0]) &&
          getItem({
            label: (
              <Link to={ROUTES?.ADMINS}>{capitalize(MODULES?.ADMINS)}</Link>
            ),
            key: ROUTES?.ADMINS,
            icon: <UserOutlined />
          }),
        getItem({
          label: <Link to={ROUTES?.PROFILE}>My Profile</Link>,
          key: ROUTES?.PROFILE,
          icon: <IdcardOutlined />
        }),
        getItem({
          label: <Link to={ROUTES?.LOGOUT}>Logout</Link>,
          key: ROUTES?.LOGOUT,
          icon: <LoginOutlined />
        })
      ]
    })
  ];

  return (
    <div className="sidebar">
      <img
        onClick={() => history.push(ROUTES?.DASHBOARD_LANDING)}
        src={collapsed ? '/logo-collapsed.png' : '/logo.png'}
        className={`sidebar-logo ${collapsed ? 'collapsed' : ''}`}
        alt="sidebar logo"
      />
      <Divider className="logo-divider" />
      <div className="sidebar-scroll">
        {workspace && (
          <Menu
            className="menu-bar"
            theme="light"
            mode="inline"
            selectedKeys={selectedKeys}
            defaultSelectedKeys={[ROUTES?.MAIN]}
            items={menuItems}
            inlineIndent={0}
            openKeys={openKeys}
            onOpenChange={(openKeysArray) => {
              setOpenKeys(openKeysArray);
            }}
            onClick={onMenuSelect}
          />
        )}
      </div>
      <div className="profile-menu">
        <Menu
          className="profile-bar"
          theme="light"
          selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
          items={profileItems}
        />
      </div>
    </div>
  );
}

export default withRouter(Sidebar);
